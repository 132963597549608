// general
$ff: 'Panton', sans-serif;

$__IMG_PATH: '/assets/website/img/';
$__FONTS_PATH: '/assets/website/fonts/';


// colors
$color_primary: #4996D1;
$color_secondary: #CE72CB;
$color_blur: #559BEB;
$color_default: #5C6876; // texts
$color_aqua: #93DEEF; // footer titles
$color_title: #8592F4;
$color_intro_subtitle: #8FF7FF;
$color_disabled_tabs_controls: #959BAF;
$transparent: rgba(0, 0, 0, 0);
$gradient_primary: linear-gradient(90.36deg, #6EACF6 21.97%, #A685F3 76.97%), $color_primary;
$gradient_secondary: linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 49.38%, rgba(179, 102, 215, 0.4) 62.97%, rgba(90, 84, 209, 0.4) 87.16%), $color_primary;
$gradient_block: url($__IMG_PATH + 'bg-block.jpg') no-repeat;

//layout
$container-xl: 1100px;
$container-xxl: 1280px;
$container: 91%;


//breakpoints
$break_desc: '1440px';
$break_md_lg: '1366px';
$break_md: '1223px';
$break_sm: '1024px';
$break_xs: '768px';
$break_xxs: '500px';


// For Grid System
// - Spacers
$spacer: 16px;
$spacers: ();
$spacers: map-merge((
        0: 0, // 0px
        1: ($spacer * 0.5), // 8px
        2: $spacer, // 16px
        3: ($spacer * 1.5), // 24px
        4: ($spacer * 2), // 32px
        5: ($spacer * 3), // 48px
), $spacers);

// - Breakpoints
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
);

// - Grid Layout
$layout: (
        d: (display: (
                none: none,
          //i: inline,
                block: block,
                inline-block: inline-block,
                flex: flex,
          //inline-flex: inline-flex,
        )),
        flex: (flex-direction: (
                row: row,
                column: column,
                row-reverse: row-reverse,
                column-reverse: column-reverse
        ), flex-wrap: (
                wrap: wrap,
                nowrap: nowrap,
          //wr: wrap-reverse
        )),
        justify-content: (justify-content: (
          //start: flex-start,
          //end: flex-end,
                center: center,
                between: space-between,
          //around: space-around
        )),
        align-items: (align-items: (
                start: flex-start,
                end: flex-end,
                center: center,
          //baseline: baseline,
          //stretch: stretch
        )),
        align-self: (align-self: (
          //start: flex-start,
          //end: flex-end,
          //center: center,
          //baseline: baseline,
          //stretch: stretch
        )),
        order: (order: (
          //1: 1,
          //2: 2,
          //3: 3,
          //4: 4,
          //5: 5,
        ))
);