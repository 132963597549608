.dropdown {
  cursor: pointer;
  position: relative;
  display: inline-block;

  &__title {
    position: relative;
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    line-height: 25px;
    color: $color_default;

    svg {
      margin-left: 8px;
      position: relative;
      z-index: 13;

      path {
        fill: $color_default;
        transition: all 0.2s;
      }
    }
  }

  &__content {
    display: none;
    position: absolute;
    top: -12px;
    left: -17px;
    z-index: 10;
    background: #fff;
    padding: 12px 31px 12px 17px;
    border-radius: 8px;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.02em;

    li, a {
      font-size: 18px;
      color: $color_default;
      white-space: nowrap;

      &:hover {
        color: $color_primary;
      }
    }

    li {
      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
  }

  &:hover {
    .dropdown__content {
      display: block;
    }
  }

  &--blue {
    .dropdown__title {
      color: $color_primary;

      svg {
        path {
          fill: $color_primary;
        }
      }
    }
  }

  &--light {
    .dropdown__title {
      color: #ffffff;

      svg {
        path {
          fill: #ffffff;
        }
      }
    }
  }
}