.media_page {
  padding-top: 139px;
  position: relative;
  margin-bottom: 62px;

  &:after,
  &:before,
  .media_blocks:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    z-index: -1;
    left: 0;
  }

  .border {
    position: relative;
    height: 0;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }

  &:before {
    height: 100%;
    //max-height: 425px;
    background: top 0 right 0/100% 425px no-repeat url($__IMG_PATH + "media/media_top.jpg");
  }

  &:after {
    height: 100%;
    max-height: 425px;
    background: linear-gradient(13.94deg, #FFFFFF 13.55%, rgba(255, 255, 255, 0) 81.02%);
  }

  .hero {
    &_section {
      background: #FFFFFF;
      box-shadow: 0px 4px 30px rgba(43, 45, 74, 0.17);
      backdrop-filter: blur(150px);
      -webkit-backdrop-filter: blur(150px);
      border-radius: 24px;
      margin: 0 7px 43px;
      @include sm {
        border-radius: 8px;
        br {
          display: none;
        }
      }
    }

    &_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px 27px 26px 83px;
      border-bottom: 1px solid rgba(92, 104, 118, 0.3);
      @include md {
        padding: 25px 20px;
      }
      @include sm {
        padding: 41px 26px 29px;
      }

      .text {
        width: 31%;
        @include sm {
          width: 100%;
        }

        .heading {
          margin-bottom: 10px;
          @include md {
            font-size: 34px;
          }
        }

        .subtitle {
          font-size: 24px;
          @include md {
            font-size: 18px;
          }
        }
      }

      .small_img {
        position: relative;
        top: -86px;
        width: 222px;
        min-width: 222px;
        //height: 213px;
        background: linear-gradient(150deg, #FFFFFF 29.81%, rgba(219, 230, 242, 0) 53.04%, #8F9FAE 100%);
        box-shadow: 16px 4px 64px 0 rgba(18, 61, 101, 0.3), -15px 2px 40px 0 rgba(255, 255, 255, 0.8), -8px -6px 80px 0 rgba(255, 255, 255, 0.18) inset;
        border-radius: 24px;
        padding: 1px;
        @include md {
          width: 170px;
          min-width: 170px;
          //height: 170px;
        }
        @include sm {
          display: none;
        }

        &_wrapp {
          background: linear-gradient(0deg, #FFFFFF 89.74%, rgba(255, 255, 255, 0) 105.13%);
          border-radius: 24px;
          padding: 20px;
          height: 100%;
          width: 100%;
          @include md {
            padding: 10px;
          }
        }

        .border {
          padding-bottom: 95%;

          img {
            border-radius: 18px;
          }
        }
      }

      .big_img {
        width: 44%;
        border-radius: 18px;
        box-shadow: -16px 6px 40px rgba(255, 255, 255, 0.8), 16px 4px 64px rgba(18, 61, 101, 0.3), inset -8px -6px 80px rgba(255, 255, 255, 0.18);
        background: linear-gradient(244.98deg, #FFFFFF 29.81%, rgba(219, 230, 242, 0) 53.04%, #8F9FAE 100%);
        padding: 1px;

        .border {
          padding-bottom: 44%;

          img {
            border-radius: 18px;
          }
        }

        @include sm {
          display: none;
        }
      }
    }

    &_bottom {
      padding: 35px 25px 33px 82px;
      @include md {
        padding: 35px 20px;
      }
      @include sm {
        padding: 18px 26px 27px;
      }
    }
  }

  .filter {
    &_mobile {
      display: none;
      @include sm {
        display: block;
      }

      .burger {
        display: none;
        @include sm {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-left: 0;
          font-size: 18px;
        }

        img {
          margin-left: 12px;
        }
      }
    }

    &_menu {
      @include sm {
        padding-top: 90px;
        padding-bottom: 45px;
        display: flex;
        transition: none;
        transform: none;
      }

      ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
        @include sm {
          flex-direction: column;
          justify-content: center;
          padding-top: 55px;
          padding-bottom: 22px;
          margin: auto;
        }

        li {
          padding: 0;
          position: static;
          height: auto;
          @include sm {
            //order: 2;
            max-width: 140px;
            width: 100%;
            text-align: center;
          }

          &:before {
            content: none;
          }

          /*&.active {
            @include sm {
              order: 1;
            }
          }*/

          a {
            @include sm {
              text-transform: none;
            }
          }
        }

        .close {
          top: 16px;
          right: 14px;
        }
      }
    }

    &_button {
      display: block;
      border-radius: 67px;
      padding: 9px 37px 7px;
      border: 1px solid;
      margin-right: 29px;
      font-size: 14px;
      line-height: 128.02%;
      letter-spacing: 0.02em;
      background: #fff;
      transition: all 0.1s linear;
      cursor: pointer;
      @include sm {
        margin-bottom: 29px;
        margin-right: 0;
      }

      &.all {
        color: #959BAF;
        border-color: #959BAF;
        @include sm {
          padding: 9px 12px 7px;
        }

        &.active, &:focus, &:hover {
          background: #959BAF;
        }
      }

      &.blog-media {
        color: #5C6876;
        border-color: #5C6876;
        @include sm {
          padding: 9px 12px 7px;
        }

        &.active, &:focus, &:hover {
          background: #5C6876;
        }
      }

      &.portfolio-media {
        color: #CE72CB;
        border-color: #CE72CB;
        padding: 9px 29px 7px;
        @include sm {
          padding: 9px 12px 7px;
        }

        &.active, &:focus, &:hover {
          background: #CE72CB;
        }
      }

      &.news-media {
        color: #559BEB;
        border-color: #559BEB;
        padding: 9px 22px 7px;
        @include sm {
          padding: 9px 12px 7px;
        }

        &.active, &:focus, &:hover {
          background: #559BEB;
        }
      }

      &.active, &:focus, &:hover {
        color: #fff;
      }
    }
  }

  .media_blocks {
    position: relative;

    &:before {
      background: top 0 right 0/contain no-repeat url($__IMG_PATH + 'media/media_bottom.jpg');
      height: 100%;
    }

    &_wrapp {
      display: flex;
      align-items: stretch;
      //justify-content: space-between;
      flex-wrap: wrap;
      padding-bottom: 70px;
      position: relative;
      overflow: visible !important;
      margin: 0 -8px 32px;
      @include xs {
        padding-bottom: 20px;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(0deg, rgba(73, 150, 209, 0), rgba(73, 150, 209, 0)),
        linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 36.82%, rgba(179, 102, 215, 0.4) 67.04%, rgba(90, 84, 209, 0.4) 87.16%);
      }

      .item {
        background: linear-gradient(158deg, #FFFFFF 29.81%, rgba(219, 230, 242, 0) 53.04%, #8F9FAE 100%);
        border-radius: 24px;
        padding: 1px;
        margin: 0 15px 30px;
        width: calc(33.3333% - 30px);
        box-shadow: -16px -16px 40px rgba(255, 255, 255, 0.8), 16px 4px 64px rgba(18, 61, 101, 0.3), inset -8px -6px 80px rgba(255, 255, 255, 0.18);
        @include sm {
          width: calc(50% - 30px);
          border-radius: 18px;
        }
        @include xs {
          width: calc(100% - 30px);
        }

        &_wrapp {
          position: relative;
          background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 100%), #F2F5FB;
          width: 100%;
          height: 100%;
          border-radius: 24px;
          padding: 19px 16px 40px 18px;
          @include sm {
            border-radius: 18px;
          }
        }

        .category {
          border-radius: 67px;
          padding: 5px 20px 4px 19px;
          letter-spacing: 0.02em;
          font-size: 10px;
          line-height: 128.02%;
          color: #FFFFFF;
        }

        .category.portfolio-media {
          background: #CE72CB
        }

        .category.news-media {
          background: #559BEB !important;
        }

        .category.blog-media {
          background: #5C6876
        }

        .date {
          font-size: 14px;
          line-height: 128.02%;
          letter-spacing: 0.02em;
          color: #8592F4;
        }

        .subtitle {
          color: $color_default;
          max-width: 100%;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          height: 25px;
          font-size: 18px;
          letter-spacing: 0.02em;
          margin-top: -3px;
          overflow: hidden;
        }

        .title {
          color: #4996D1;
          width: 90%;
          letter-spacing: 0.02em;
        }

        .text {
          width: 100%;
          max-width: 100%;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-height: 128.02%;
          font-weight: 500;
          -webkit-box-orient: vertical;
          max-height: 48px;
          overflow: hidden;
          margin-bottom: 14px;
        }

        .info {
          width: 100%;
          max-width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 18px;
        }

        .post__btn {
          position: absolute;
          bottom: 23px;
          font-weight: 500;
          display: block;
          color: $color_primary;
          background: linear-gradient(90.36deg, #6EACF6 21.97%, #A685F3 76.97%), linear-gradient(0deg, #4996D1, #4996D1);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 14px;

          &:hover {
            &:after {
              margin-left: 16px;

              @include sm {
                margin-left: 12px;
              }
            }
          }
        }
      }

      .border {
        padding-bottom: 48%;
        border-radius: 24px;
        overflow: hidden;
        margin-bottom: 16px;
        @include sm {
          border-radius: 18px;
        }
      }
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: $color_default;

    & > * {
      display: block;
      margin: 0 4px;
    }

    a {
      display: flex;
      align-items: center;
      transition: 0.2s;
      color: $color_default;

      &:hover {
        color: $color_primary;

        svg {
          path {
            fill: $color_primary;
          }
        }
      }

      svg {
        display: block;
        margin: 0 5px;
        transition: 0.2s;
      }
    }

    .current_page {
      display: block;
      color: $color_primary;
      background: linear-gradient(90.36deg, #6EACF6 21.97%, #A685F3 76.97%), linear-gradient(0deg, #4996D1, #4996D1);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
