.integration {
  padding-top: 163px;
  @include md-lg {
    padding-top: 73px;
  }
  @include sm {
    padding-top: 85px;
  }
  @include xs {
    padding-top: 10px;
  }

  &__inner {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
    grid-column-gap: 43px;
    grid-row-gap: 50px;
    align-items: center;
    padding-left: 90px;
    padding-right: 45px;
    @include md-lg {
      grid-row-gap: 20px;
      align-items: flex-start;
      padding-left: 69px;
      padding-right: 130px;
    }
    @include sm {
      padding-left: 32px;
      padding-right: 20px;
    }
    @include xs {
      display: flex;
      flex-direction: column;
      grid-row-gap: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .info {
    grid-area: 1 / 1 / 2 / 2;
    margin-right: 0;
    flex: none;
    width: 100%;
    max-width: 590px;
    @include md-lg {
      padding-top: 20px;
      padding-bottom: 20px;
      max-width: 430px;
    }
    @include xs {
      grid-area: initial;
      padding-bottom: 44px;
    }

    .title {
      margin-bottom: 13px;
      @include xs {
        max-width: 220px;
        margin-bottom: 27px;
        line-height: 1.2;
      }
    }

    .heading {
      margin-bottom: 13px;
      @include xs {
        margin-bottom: 27px;
      }
    }

    .subtitle {
      max-width: none;
      @include xs {
        max-width: 295px;
      }
    }
  }

  .title--primary {
    margin-bottom: 5px;
  }

  .row {
    grid-area: 2 / 1 / 3 / 2;
    width: 100%;
    max-width: 645px;
    display: flex;
    justify-content: space-between;
    @include md-lg {
      grid-area: 2 / 1 / 3 / 3;
    }
    @include xs {
      grid-area: initial;
      flex-wrap: wrap;
    }

    .col {
      width: 47%;
      @include xs {
        width: 100%;
      }

      & + .col {
        @include xs {
          margin-top: 34px;
          margin-left: 6px;
        }
      }
    }
  }

  .btn,
  .brands {
    margin-top: 24px;

    &:before {
      content: none;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-template-rows: repeat(2, auto);
      justify-items: initial;
      grid-gap: 10%;
      padding: 0;

      img {
        width: 100%;
        max-width: none;
        margin-right: 0;
      }

      img:nth-child(4) {
        margin-left: 5px;
      }
    }
  }

  .code {
    grid-area: 1 / 2 / 3 / 3;
    width: 450px;
    @include md-lg {
      grid-area: 1 / 2 / 2 / 3;
      width: 372px;
    }
    @include xs {
      grid-area: initial;
      width: 100%;
      margin-top: 29px;
    }

    &__inner {
      position: relative;
      min-height: 420px;
      padding: 70px;
      opacity: 0.75;
      border-radius: 24px;
      border: 1px solid;
      border-image-source: linear-gradient(87deg, rgba(160, 250, 252, 0) 90.54%, #A0FAFC 0.13%);
      box-shadow: 0 4px 30px rgba(43, 61, 74, 0.36);
      overflow: hidden;
      @include md-lg {
        min-height: 292px;
        padding: 36px 43px 30px 44px;
      }
      @include xs {
        padding: 20px;
        min-height: 230px;
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.75;
      }

      &:before {
        background-image: url($__IMG_PATH + 'bg-code.jpg');
        background-size: cover;
        @include xs {
          background-image: url($__IMG_PATH + 'bg-code-mobile.jpg');
        }
      }

      &:after {
        background: linear-gradient(47.93deg, rgba(0, 0, 0, 0.5) 20.61%, rgba(0, 0, 0, 0) 88.71%);
        @include xs {
          background: linear-gradient(180deg, rgba(31, 0, 71, 0.6) 0%, rgba(31, 0, 71, 0) 100%);
        }
      }
    }

    &__input {
      position: relative;
      z-index: 2;
      width: 100%;
      color: #fff;
      font-size: 18px;
      line-height: 1.4;
      font-weight: 300;
      @include md-lg {
        font-size: 14px;
      }
      @include xs {
        font-family: 'Roboto Mono', monospace;
      }
      @include xxs {
        font-size: 12px;
      }
    }

    &__color--1 {
      color: #CE72CB
    }

    &__color--2 {
      color: #9FF8FF
    }

    &__color--3 {
      color: #98DDFC
    }
  }
}
