.brands {
  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include xs {
      flex-wrap: wrap;
      gap: 12px 10%;
      padding: 0 10px 24px 10px;
    }

  }

  &__img {

    @include md {
      max-width: calc(100% / 8 - 24px);
    }

    @include xs {
      max-width: 100%;
      width: 17%;
    }

    &:not(:last-child) {
      margin-right: 58px;

      @include md {
        margin-right: 24px;
      }

      @include xs {
        margin-right: 0;
      }
    }

    img {
      margin: 0 auto;
    }
  }
}