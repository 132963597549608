.card {
  &_item {
    padding: 1px;
    border-radius: 24px;
    box-shadow: -16px 6px 40px rgba(255, 255, 255, 0.8), 16px 4px 64px rgba(18, 61, 101, 0.3), inset -8px -6px 80px rgba(255, 255, 255, 0.18);
    background: linear-gradient(155deg, #FFFFFF 30%, rgba(219, 230, 242, 0) 53%, #8F9FAE 100%);
    @include xs {
      padding: 0;
      border-radius: 0;
      box-shadow: none;
      background: none;
    }

    & + & {
      margin-top: 37px;
      @include xs {
        margin-top: 30px;
      }
    }

    &--two_cols {
      .card_inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include xs {
          align-items: flex-start;
        }
      }
    }

    &--content_left {
      .card_inner {
        flex-direction: row-reverse;
        @include xs {
          flex-direction: column;
        }
      }
    }

    &--content_right {
      .card_inner {
        flex-direction: row;
        @include xs {
          flex-direction: column;
        }
      }

      .card_content {
        padding-left: 70px;
        padding-right: 100px;
        @include md {
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      /*.card_title {
        margin-bottom: 25px;
        @include xs {
          margin-bottom: 40px;
        }
      }

      .card_text {
        p {
          margin-bottom: 15px;
        }
      }*/
    }
  }

  &_inner {
    padding: 23px 26px;
    border-radius: 24px;
    background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #F2F5FB;
    @include xs {
      background: none;
      padding-left: 4px;
      padding-right: 0;
    }
  }

  &_image {
    position: relative;
    max-width: 495px;
    border-radius: 18px;
    overflow: hidden;
    width: 100%;
    padding-bottom: 22%;
    background: linear-gradient(155deg, #FFFFFF 30%, rgba(219, 230, 242, 0) 53%, #8F9FAE 100%);
    @include xs {
      order: 2;
      max-width: none;
      margin-bottom: 20px;
      padding-bottom: 46.5%;
    }
    @include xxs {
      padding-bottom: 71%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &_content {
    max-width: 625px;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 15px;
    margin-right: 15px;
    @include md {
      max-width: 500px;
    }
    @include xs {
      display: contents;
    }
  }

  &_title {
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0.02em;
    color: #4996D1;
    margin-bottom: 20px;
    @include xs {
      order: 1;
      margin-bottom: 40px;
    }
  }

  &_text {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: 0.02em;
    color: #5C6876;
    @include xs {
      order: 3;
    }

    a {
      color: #4996D1;
    }

    ol {
      list-style: auto inside;

      li {
        padding-bottom: 13px;
      }
    }

    ul li {
      position: relative;
      padding: 0 0 13px 24px;

      &::before {
        content: '';
        position: absolute;
        top: 6px;
        left: 0;
        display: block;
        width: 12px;
        height: 12px;
        background: center/contain no-repeat #4996D1 url($__IMG_PATH + 'icons/ellipse.svg');
        box-shadow: -2px -1px 4px rgba(255, 255, 255, 0.4), 2px 1px 3px rgba(24, 47, 91, 0.2), inset 3px 3px 5px rgba(213, 222, 245, 0.4), inset -4px -3px 4px rgba(79, 103, 120, 0.2);
        border-radius: 50%;
      }
    }


    p {
      margin-bottom: 25px;
      @include xs {
        margin-bottom: 15px;
      }
    }
  }
}


