.menuChild {
  padding-top: 20px;
  position: absolute;
  top: 100%;
  left: -53px;
  z-index: 50;
  width: max-content;
  pointer-events: none;
  opacity: 0;
  transition: all 0.2s;
  @include md {
    padding-top: 10px;
    left: -24px;
  }
  @include sm {
    padding-top: 0;
    position: static;
    width: 100%;
    margin-top: 15px;
  }

  &--products {
    .menuChild__wrapper {
      background: left 0 top 0 / auto 100% no-repeat url($__IMG_PATH + 'header/child-menu-products.jpg') #fff;
    }

    .menuChild__content {
      @include sm {
        grid-template-columns: repeat(4, 1fr);
      }
      @include xs {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  &--solutions {
    .menuChild__wrapper {
      background: left 0 top 0 / auto 100% no-repeat url($__IMG_PATH + 'header/child-menu-solutions.jpg') #fff;
    }

    .menuChild__content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 24px;
      @include sm {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 15px 24px;
      }
      @include xs {
        grid-template-columns: repeat(2, 1fr);
      }

      li:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  &--company {
    .menuChild__wrapper {
      background: left 0 top 0 / auto 100% no-repeat url($__IMG_PATH + 'header/child-menu-company.jpg') #fff;
    }
  }

  &--contacts {
    .menuChild__wrapper {
      background: left 0 top 0 / auto 100% no-repeat url($__IMG_PATH + 'header/child-menu-contacts.jpg') #fff;
    }
  }

  & &__wrapper {
    display: flex;
    padding: 44px 53px;
    box-shadow: 0 4px 30px rgba(43, 45, 74, 0.17);
    border-radius: 24px;
    @include md {
      padding: 24px;
    }
    @include sm {
      flex-direction: column;
      background: $transparent;
      box-shadow: none;
      padding: 0;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 250px;
    @include sm {
      //order: 1;
      //margin-top: 11px;
      max-width: none;
    }
  }

  &__header {
    @include sm {
      display: none;
    }
  }

  &__footer {
    max-width: 240px;
    margin-top: auto;
    @include sm {
      /*display: flex;
      flex-wrap: wrap;
      align-items: center;*/
      max-width: none;
      margin-bottom: 6px;
    }

    .menuChild__subtitle {
      margin-top: 20px;
      @include sm {
        /*display: block;
        margin-top: 0;
        margin-bottom: 19px;
        margin-right: 20px;
        color: $color_primary;
        background: $gradient_primary;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;*/
      }
    }

    .menuChild__btn {
      margin-top: 13px;
      @include sm {
        margin-top: 0;
        margin-bottom: 19px;
      }

      .btn {
        font-size: 14px;
      }
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 14px;
    letter-spacing: 0.05em;
    color: $color_primary;
    background: $gradient_primary;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @include md {
      font-size: 20px;
    }
  }

  &__subtitle {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    color: $color_default;
    letter-spacing: 0.05em;
    @include md {
      font-size: 14px;
      line-height: 20px;
    }
    @include sm {
      display: none;
    }
  }

  &__content {
    margin-left: 92px;
    margin-top: 3px;
    @include md {
      margin-left: 24px;
    }
    @include sm {
      margin-left: 0;
      margin-top: 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 15px 24px;
    }

    li {
      &:not(:last-child) {
        margin-bottom: 24px;
        @include md {
          margin-bottom: 16px;
        }
        @include sm {
          margin-bottom: 0;
        }
      }

      a {
        color: $color_primary;
        background: $gradient_primary;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        letter-spacing: 0.02em !important;
        @include sm {
          letter-spacing: 0.05em !important;
          line-height: 20px !important;
        }

        &:hover {
          -webkit-text-fill-color: $color_secondary;
        }
      }
    }

    &--two {
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-gap: 24px 40px;
      @include md {
        grid-gap: 16px 24px;
      }
      @include sm {
        grid-template-columns: repeat(2, 1fr);
      }

      li {
        margin-bottom: 0 !important;
      }
    }
  }
}

.menu {
  margin-left: auto;
  align-self: stretch;
  transition: all 0.2s;
  @include desc {
    margin: 0 auto
  }
  @include sm {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-10px);
    //backdrop-filter: blur(150px);
    //-webkit-backdrop-filter: blur(150px);
    background-color: rgba(255, 255, 255, .9);
  }
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    & {
      @include sm {
        background-color: rgba(255, 255, 255, .9);
        backdrop-filter: blur(150px);
        -webkit-backdrop-filter: blur(150px);
      }
    }
  }

  &.active {
    opacity: 1;
    pointer-events: auto;
    transform: none;

    .menuChild {
      @include sm {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  & > ul {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    @include sm {
      display: block;
      height: auto;
      border-radius: 18px;
      background: #fff;
      margin: 30px auto;
      width: 91%;
      box-shadow: 0 4px 30px rgba(43, 45, 74, 0.17);
    }

    & > li {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 43px 78px 43px 0;
      @include md-lg {
        padding: 30px 50px 30px 0;
      }
      @include md {
        padding: 30px 30px 30px 0;
      }
      @include sm {
        display: block;
        padding: 24px 28px;
      }

      &.menu__footer {
        display: none;
        @include sm {
          display: flex;
          flex-wrap: wrap;
        }

        .user,
        .lang,
        .header__action {
          @include sm {
            display: block;
          }
        }

        .header__action {
          margin-right: 0;
          margin-left: auto;
        }

        .user {
          a {
            display: flex;
            align-items: center;
            color: #959BAF;
          }

          img {
            margin-right: 13px;
          }
        }

        .dropdown__content {
          top: -13px;
        }

        .dropdown__title {
          font-size: 14px;
        }
      }

      &::before {
        content: "";
        position: absolute;
        width: calc(100% - 78px); //78px padding right for menu item
        height: 3px;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0);
        transition: all 0.2s;
        @include md-lg {
          width: calc(100% - 50px); //24px padding right for menu item
        }
        @include md {
          width: calc(100% - 30px); //30px padding right for menu item
        }
        @include sm {
          width: 100%;
          background: linear-gradient(0deg, rgba(73, 150, 209, 0), rgba(73, 150, 209, 0)),
          linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 36.82%, rgba(179, 102, 215, 0.4) 67.04%, rgba(90, 84, 209, 0.4) 87.16%);;
          height: 1px;
          top: 100%;
        }
      }

      &:nth-last-child(-n+1):before {
        @include sm {
          content: none;
        }
      }

      & > span,
      a {
        display: inline-block;
        align-items: center;
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0.05em;
        transition: all 0.2s;
        @include md {
          font-size: 14px;
        }

        svg {
          margin-left: 8px;
          @include sm {
            display: none;
          }

          path {
            fill: $color_default;
            transition: all 0.2s;
          }
        }
      }

      & > span {
        color: $color_default;
        @include sm {
          text-transform: uppercase;
        }
      }

      &:hover {
        &::before {
          background: $color_secondary;
          @include sm {
            background: linear-gradient(0deg, rgba(73, 150, 209, 0), rgba(73, 150, 209, 0)), linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 36.82%, rgba(179, 102, 215, 0.4) 67.04%, rgba(90, 84, 209, 0.4) 87.16%);
          }
        }

        & > span {
          color: $color_secondary;
          @include sm {
            color: $color_default;
          }

          svg {
            path {
              fill: $color_secondary;
            }
          }
        }

        .menuChild {
          opacity: 1;
          pointer-events: auto;
          transition-delay: 0.1s;
        }
      }
    }
  }

}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  cursor: default;
}
