.modal {
  display: none;
  justify-content: center;
  align-items: center;
  padding: 50px 15px;
  width: 100%;
  height: 100%;
  background: top 0 left 0 / 100% 497px no-repeat url($__IMG_PATH + "modal/modal-overlay.jpg") #FFF;
  /*opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;*/
  @include sm {
    padding: 145px 16px 23px;
  }

  &_show {
    display: flex;
    /*opacity: 1;
    visibility: visible;
    transform: scale(1.0);
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;*/
  }

  &_content {
    position: relative;
    align-self: center;
    padding: 66px 89px 42px 82px;
    width: 100%;
    max-width: 674px;
    background: top 0 left 0 / contain no-repeat url($__IMG_PATH + 'modal/modal-bg.jpg') #FFF;
    box-shadow: 0 4px 30px rgba(43, 45, 74, 0.17);
    border-radius: 24px;
    @include sm {
      padding: 31px 28px 38px;
      border-radius: 8px;
    }
  }

  &_close {
    position: absolute;
    top: 16px;
    right: 15px;
    padding: 5px;
    width: 28px;
    height: 28px;
    cursor: pointer;
    border: 0;
    border-radius: 0;
    background-color: transparent;

    &:hover {
      svg path {
        fill: #5C6876;
      }
    }
  }

  .info {
    max-width: none;

    .title {
      margin-bottom: 10px;
    }

    .heading {
      margin-bottom: 40px;
      color: #4996D1;
      font-size: 28px;
      line-height: 1.17;
      @include sm {
        margin-bottom: 34px;
        background: transparent;
        -webkit-background-clip: initial;
        -webkit-text-fill-color: initial;
      }
    }

    .image {
      padding: 1px;
      margin-bottom: 40px;
      background: linear-gradient(244.98deg, #FFFFFF 29.81%, rgba(219, 230, 242, 0) 53.04%, #8F9FAE 100%);
      box-shadow: 16px 4px 64px 0 rgba(18, 61, 101, 0.3), -16px 6px 40px 0px rgba(255, 255, 255, 0.8), -8px -6px 80px 0px rgba(255, 255, 255, 0.18) inset;
      border-radius: 18px;

      &_inner {
        border-radius: inherit;
        overflow: hidden;

        img {
          width: 100%;
          height: 178px;
          object-fit: cover;
        }
      }
    }
  }

  .form__checkbox {
    @include sm {
      margin-left: 0;
      padding-bottom: 26px;
      font-size: 11px;
    }

    label {
      @include sm {
        padding-left: 40px;
      }
    }

    .form__checkmark {
      @include sm {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.hide_content {
  display: none !important;
}