.program {
  padding-top: 137px;
  padding-bottom: 72px;
  background: top 0 left 0 / 100% no-repeat url($__IMG_PATH + 'program/program-bg.jpg');
  @include sm {
    background: top 68px left 0 / cover no-repeat url($__IMG_PATH + 'program/program-bg-mob.jpg');
  }

  /*&,
  & ~ #success,
  & ~ #unsuccess {

  }*/

  .card {
    @include sm {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }

  .heading {
    -webkit-background-clip: initial;
    -webkit-text-fill-color: initial;
    background: none;
  }

  .content-block {
    li {
      flex-grow: 1;
      max-width: 257px;
      width: 100%;
    }

    li:not(:last-child) {
      @include media-query(lg) {
        margin-right: 48px;
      }
    }
  }

  .form {
    /*select,
    textarea::placeholder,
    input::placeholder {
      font-weight: 600;
      color: rgba(85, 155, 235, 1);
      background: linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 49.38%, rgba(179, 102, 215, 0.4) 62.97%, rgba(90, 84, 209, 0.4) 87.16%), #4996D1;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }*/

    .row {
      margin-left: 0;
      margin-right: 0;
      flex-wrap: nowrap;
      @include xs {
        flex-wrap: wrap;
      }
    }

    &__submit {
      @include sm {
        text-align: left;
      }
      .btn {
        width: auto;
      }
    }

    &__checkbox {
      margin-bottom: 8px;
    }

    &__error {
      &--left {
        left: -32px;
      }

      &--right {
        right: -32px;
      }
    }
  }
}