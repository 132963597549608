.payments {
  padding-bottom: 90px;
  background: bottom 0 right -280px / contain no-repeat url($__IMG_PATH + 'bg-payment.jpg');
  @include md-lg {
    padding-bottom: 33px;
    background-position: top 70% right -300px;
  }
  @include sm {
    padding-bottom: 100px;
  }
  @include xs {
    padding-bottom: 32px;
    background: top 100% left -280px / 120% no-repeat url($__IMG_PATH + 'bg-payment.jpg'), top 0 left 300px / 120% no-repeat url($__IMG_PATH + "bg-payment.jpg");
  }
  @include xxs {
    background: top 100% left -280px / 180% no-repeat url($__IMG_PATH + 'bg-payment.jpg'), top 110px left 100px / 180% no-repeat url($__IMG_PATH + "bg-payment.jpg");
  }

  &__inner {
    display: flex;
    align-items: flex-end;
    padding-left: 90px;
    @include md-lg {
      align-items: center;
      padding-right: 107px;
      padding-left: 69px;
    }
    @include sm {
      padding-left: 0;
      padding-right: 0;
    }
    @include xs {
      flex-wrap: wrap;
    }
  }

  .info {
    max-width: none;
    flex: 1 1 40%;
    margin-right: 5%;
    @include md-lg {
      flex-basis: 19%;
    }
    @include sm {
      flex-basis: 36%;
    }
    @include xs {
      flex-basis: 100%;
    }

    .title {
      margin-bottom: 19px;
      @include xs {
        margin-bottom: 25px;
      }
    }

    .heading {
      margin-bottom: 21px;
      @include md-lg {
        margin-bottom: 25px;
      }
      @include xs {
        margin-bottom: 28px;
      }
    }

    .subtitle {
      display: block;
      max-width: 515px;
    }

    .btn {
      margin-top: 24px;
      padding-left: 37px;
      padding-right: 37px;
      @include xs {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }

  .brands {
    flex: 1 1 auto;

    &__list {
      display: grid;
      padding: 30px 0;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
      justify-items: center;
      @include xxs {
        padding: 23px 0 13px;
        grid-gap: 0;
      }
    }

    &__img {
      padding: 14px;
      width: auto;
      margin-right: 0;
      max-width: none;
      @include sm {
        padding: 20px;
      }
      @include xs {
        padding: 12px;
      }

      &:nth-child(1) {
        img {
          max-width: 68px;
          max-height: 68px;
          @include xs {
            max-width: 52px;
            max-height: 52px;
          }
        }
      }

      &:nth-child(2) {
        img {
          max-width: 72px;
          max-height: 30px;
          @include xs {
            max-width: 54px;
            max-height: 22px;
          }
        }
      }

      &:nth-child(3) {
        img {
          max-width: 130px;
          max-height: 32px;
          @include xs {
            max-width: 98px;
            max-height: 26px;
          }
        }
      }

      &:nth-child(4) {
        img {
          max-width: 61px;
          max-height: 31px;
          @include xs {
            max-width: 46px;
            max-height: 24px;
          }
        }
      }

      &:nth-child(5) {
        img {
          max-width: 68px;
          max-height: 28px;
          @include xs {
            max-width: 51px;
            max-height: 21px;
          }
        }
      }

      &:nth-child(6) {
        img {
          max-width: 137px;
          max-height: 26px;
          @include xs {
            max-width: 104px;
            max-height: 19px;
          }
        }
      }

      &:nth-child(7) {
        img {
          max-width: 57px;
          max-height: 29px;
          @include xs {
            max-width: 43px;
            max-height: 22px;
          }
        }
      }

      &:nth-child(8) {
        img {
          max-width: 78px;
          max-height: 35px;
          @include xs {
            max-width: 59px;
            max-height: 27px;
          }
        }
      }

      &:nth-child(9) {
        img {
          max-width: 100px;
          max-height: 22px;
          @include xs {
            max-width: 76px;
            max-height: 16px;
          }
        }
      }
    }
  }
}
