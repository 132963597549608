.careers {
  margin-bottom: 0;
  // Backgrounds
  &:before {
    background: top 0 right 0/100% 425px no-repeat url($__IMG_PATH + 'careers/careers-bg-top.jpg');
    @include xs {
      background: top 0 right 0/100% 287px no-repeat url($__IMG_PATH + 'careers/careers-bg-top-mobile.jpg');
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 430px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0.3;
    max-height: 1705px;
    background: bottom 180px center / cover no-repeat url($__IMG_PATH + 'careers/careers-bg-1.jpg'), linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 25%);
    @include sm {
      content: none;
    }
  }

  &_bg {
    &--2 {
      background: top 0 center / cover no-repeat url($__IMG_PATH + 'careers/careers-bg-2.jpg');
      @include sm {
        background: none;
      }
    }
  }

  .info {
    .heading {
      @include sm {
        color: #4996D1;
        background: transparent;
        -webkit-background-clip: initial;
        -webkit-text-fill-color: initial;
      }
    }
  }

  .text_block {
    .row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .col {
        width: 46%;
        @include sm {
          width: 100%;
        }
      }
    }

    h4 {
      display: block;
      margin-bottom: 37px;
      color: #559BEB;
      font-weight: 700;
      font-size: 18px;
      line-height: 1.4;
      letter-spacing: 0.02em;
      background: linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 49.38%, rgba(179, 102, 215, 0.4) 62.97%, rgba(90, 84, 209, 0.4) 87.16%), #4996D1;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      @include sm {
        margin-bottom: 22px;
      }
    }

    ul {
      @extend .ul;
      margin-bottom: 12px;

      li {
        min-height: 61px;
        padding-left: 30px;

        &:before {
          top: 5px;
          width: 15px;
          height: 15px;
        }

        span {
          display: inline-block;
          color: #559BEB;
          background: linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 49.38%, rgba(179, 102, 215, 0.4) 62.97%, rgba(90, 84, 209, 0.4) 87.16%), #4996D1;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }

  // Hero Section
  .hero {
    &_section {
      margin: 0 4px 40px;
    }

    &_top {
      padding: 53px 37px 48px 68px;
      @include sm {
        padding: 41px 13px 60px 25px;
      }

      .text {
        width: 52%;
        margin-bottom: 15px;
        @include sm {
          width: auto;
          margin-bottom: 0;
        }

        .title {
          display: block;
          margin-bottom: 7px;
          @include sm {
            display: none;
          }
        }

        .heading {
          @include sm {
            color: #4996D1;
            background: transparent;
            -webkit-background-clip: initial;
            -webkit-text-fill-color: initial;
          }
          @include xxs {
            max-width: 200px;
          }
        }

        .subtitle {
          font-size: 18px;
        }
      }

      .big_img {
        .border {
          padding-bottom: 48.6%;
        }
      }
    }

    &_bottom {
      padding: 68px 37px 44px 73px;
      @include sm {
        padding: 40px 10px 59px 25px;
      }
    }

    &_grid {
      display: flex;
      margin-left: -26px;
      margin-right: -26px;
      @include sm {
        margin-left: 0;
        margin-right: 0;
        flex-direction: column;
        align-items: flex-start;
      }

      &_item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        //justify-content: space-between;
        min-height: 131px;
        width: 100%;
        padding-left: 26px;
        padding-right: 26px;
        @include sm {
          padding-left: 0;
          padding-right: 0;
          margin-top: 52px;
        }

        &:nth-child(1) {
          max-width: 283px;
          @include sm {
            margin-top: 0;
          }
        }

        &:nth-child(2) {
          max-width: 345px;
        }

        &:nth-child(3) {
          max-width: 279px;
        }

        &:nth-child(4) {
          max-width: 284px;
        }

        .description {
          margin-top: 16px;
          font-weight: 600;
          font-size: 18px;
          letter-spacing: 0.02em;
          color: #5C6876;
        }
      }
    }
  }

  // Our Team Section
  .our_team {
    &_inner {
      position: relative;
      display: flex;
      padding-top: 23px;
      padding-bottom: 183px;
      @include sm {
        flex-wrap: wrap;
        padding-bottom: 30px;
      }
    }

    &_images {
      position: relative;
      width: 100%;
      max-width: 591px;
      max-height: 485px;
      margin-left: auto;
      margin-right: 32px;
      @include sm {
        max-width: 296px;
        min-height: 471px;
        //margin-left: 0;
        margin-right: auto;
      }
    }

    &_content {
      width: 100%;
      max-width: 538px;
      margin-right: 37px;
      @include sm {
        max-width: none;
        margin-right: 0;
        margin-left: 0;
      }
    }

    &_title {
      margin-bottom: 17px;
      font-weight: 700;
      font-size: 28px;
      line-height: 1.28;
      letter-spacing: 0.02em;
      color: #4996D1;
    }

    &_text {
      font-weight: 600;
      font-size: 18px;
      line-height: 1.485;
      letter-spacing: inherit;
      color: #5C6876;

      p + p {
        margin-top: 28px;
      }
    }

    &_img {
      position: absolute;
      display: inline-block;
      //width: 100%;
      //max-width: 340px;
      border-radius: 14px;
      overflow: hidden;
      background: linear-gradient(155deg, #FFFFFF 29.81%, rgba(219, 230, 242, 0) 53%, #8F9FAE 100%);
      @include sm {
        max-width: none;
      }

      &:nth-child(odd) {
        box-shadow: 16px 4px 64px 0 rgba(18, 61, 101, 0.3), -16px 6px 40px 0 rgba(255, 255, 255, 0.8), -8px -6px 80px 0 rgba(255, 255, 255, 0.18) inset;
      }

      &:nth-child(even) {
        box-shadow: 0 4px 30px 0 rgba(43, 45, 74, 0.17);
      }

      .border img {
        transform: scale(1.01);
      }

      &--1 {
        top: 25.9%;
        right: 40.7%;
        left: 2%;
        max-width: 337px;
        @include sm {
          top: -6%;
          right: 50%;
          left: 0;
          max-width: 152px;
        }

        .border {
          padding-bottom: 56%;
          @include sm {
            padding-bottom: 94%;
          }
        }
      }

      &--2 {
        top: 5.4%;
        left: 63.2%;
        right: 17.2%;
        max-width: 115px;
        @include sm {
          top: 5.7%;
          left: 55.4%;
          right: 14.5%;
          max-width: 91px;
        }

        .border {
          padding-bottom: 96%;
        }
      }

      &--3 {
        top: 69%;
        left: 11.4%;
        right: 40.6%;
        max-width: 283px;
        @include sm {
          top: 27.8%;
          left: 0;
          right: 0;
          max-width: 296px;
          max-height: 156px;
        }

        .border {
          padding-bottom: 72%;
        }
      }

      &--4 {
        top: 33.7%;
        left: 63.2%;
        right: 13.5%;
        max-width: 137px;
        @include sm {
          top: 65.2%;
          left: 7.6%;
          right: 57.5%;
          max-width: 105px;
        }

        .border {
          padding-bottom: 96%;
        }
      }

      &--5 {
        top: -3.1%;
        left: 38.2%;
        right: 40.6%;
        max-width: 125px;
        @include sm {
          top: 64.4%;
          left: 50%;
          right: 0;
          max-width: 147px;
        }

        .border {
          padding-bottom: 95%;
        }
      }

      &--6 {
        top: 66.1%;
        left: 63.1%;
        right: 2%;
        max-width: 206px;
        @include sm {
          display: none;
        }

        .border {
          padding-bottom: 71%;
        }
      }

     /* img {
        width: 100%;
      }*/
    }
  }

  // Vacancies (Accordion) Section
  .vacancies {
    padding-bottom: 50px;
    @include sm {
      padding-bottom: 15px;
    }

    .info {
      padding-left: 80px;
      @include sm {
        padding-left: 0;
      }

      .heading {
        margin-bottom: 0;
        @include sm {
          font-size: 28px;
        }
      }
    }

    .accordion {
      &__item {
        .accordion__heading {
          padding-left: 80px;
          @include sm {
            padding-left: 0;
            margin-bottom: 0;
          }

        }

        .accordion__button {
          position: relative;
          font-weight: 700;

          &:before {
            content: '';
            order: 1;
            display: block;
            width: 30px;
            height: 30px;
            background: center/contain no-repeat url($__IMG_PATH + 'solutions/plus-icon.svg');
            transition: background 0.3s;
          }

          &:after {
            @include sm {
              width: 100%;
              transition: width 0.3s;
              left: 10px;
            }
          }
        }

        &--active {
          .accordion__button {
            color: #559BEB;
            border-bottom: none;

            &:before {
              background: center/contain no-repeat url($__IMG_PATH + 'solutions/minus-icon.svg');
            }

            &:after {
              width: 0;
              transition: width 0.3s;
            }
          }
        }
      }

      &__body {
        border: 1px solid rgba(102, 114, 126, 0.15);
        padding: 50px 80px;
        color: #5C6876;
        font-weight: 600;
        letter-spacing: 0.02em;
        line-height: 1.4;
        @include sm {
          padding: 42px 10px 36px;
        }
      }
    }
  }

  // CTA Section
  .cta {
    position: relative;
    background: linear-gradient(155deg, #FFFFFF 29.81%, rgba(219, 230, 242, 0) 53.04%, #8F9FAE 100%);
    box-shadow: -16px -16px 40px rgba(255, 255, 255, 0.8), 16px 4px 64px rgba(18, 61, 101, 0.3), inset -8px -6px 80px rgba(255, 255, 255, 0.18);
    border-radius: 24px;
    padding: 1px;

    &_inner {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #F2F5FB;
      border-radius: inherit;
      padding: 58px 78px 65px;
      @include sm {
        padding: 32px;
      }
    }

    &_title {
      width: 100%;
      font-weight: 700;
      font-size: 28px;
      line-height: 1.17;
      letter-spacing: 0.02em;
      color: #559BEB;
    }

    &_text {
      max-width: 780px;
      margin-top: 19px;
      margin-bottom: 19px;
      margin-right: 66px;
      font-weight: 600;
      font-size: 18px;
      line-height: 1.4;
      letter-spacing: 0.02em;
      color: #5C6876;
    }
  }

  // Icons Section
  .icons {
    padding-top: 26px;
    padding-bottom: 50px;

    .info {
      .heading {
        margin-bottom: 70px;
        @include sm {
          margin-bottom: 31px;
          font-size: 28px;
        }
      }
    }

    &_grid {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-column-gap: 30px;
      grid-row-gap: 28px;
      @include sm {
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 0;
        grid-row-gap: 31px;
      }
    }

    &_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      //justify-content: space-between;
      @include sm {
        flex-direction: row;
        justify-content: flex-start;
      }
    }

    &_img {
      max-height: 50px;
      height: 100%;
      margin-bottom: 16px;
      @include sm {
        max-width: 34px;
        width: 100%;
        margin-bottom: 0;
      }

      img {
        @include sm {
          max-height: 25px;
        }
      }
    }

    &_text {
      min-height: 76px;
      text-align: center;
      font-weight: 600;
      font-size: 18px;
      line-height: 1.4;
      letter-spacing: 0.02em;
      color: #5C6876;
      @include sm {
        margin-left: 13px;
        min-height: auto;
        text-align: left;
      }

      br {
        @include sm {
          display: none;
        }
      }
    }
  }

  // Locations Section
  .locations {
    padding-left: 85px;
    padding-bottom: 100px;
    @include md-lg {
      padding-left: 0;
    }
    @include sm {
      padding-bottom: 40px;
    }

    &_inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: -15px;
      margin-right: -15px;
      @include sm {
        flex-wrap: wrap;
      }
    }

    &_content,
    &_frame {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
    }

    &_content {
      max-width: 432px;
    }

    &_frame {
      max-width: 660px;
      height: 434px;
      @include sm {
        display: none;
      }
    }
  }

  // Address List
  .address {
    width: 100%;

    &_list {
      position: relative;
      display: grid;
      grid-template-columns: repeat(2, auto);
      justify-content: space-between;
      grid-row-gap: 26px;
      grid-column-gap: 25px;
      transition: 0.3s;
      @include sm {
        grid-template-columns: repeat(1, auto);
      }
    }

    &_item {
      cursor: pointer;
      transition: 0.3s;

      &--animated {
      }

      &--active.address_item--animated {
      }

      &--active {
        grid-column: 1/-1;

        .address {
          &_wrap,
          &_inner {
            width: 100%;
          }

          &_inner {
            padding: 21px 53px 33px 25px;
            @include sm {
              padding-right: 90px;
            }
          }

          &_text {
            position: static;
            transform: translateX(0);
            visibility: visible;
            opacity: 1;
          }

          &_point {
            position: static;
            visibility: visible;
            opacity: 1;
            transform: scale(1);
            @include sm {
              display: none;
            }
          }
        }
      }
    }

    &_wrap {
      display: inline-block;
      padding: 1px;
      background: linear-gradient(155deg, #FFFFFF 30%, rgba(219, 230, 242, 0) 53%, #93DEEF 100%);
      box-shadow: 16px 4px 64px 0 rgba(18, 61, 101, 0.3), -15px 2px 40px 0 rgba(255, 255, 255, 0.8), -8px -6px 80px 0 rgba(255, 255, 255, 0.18) inset;
      border-radius: 24px;
    }

    &_inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 64px;
      padding: 20px 25px;
      background: linear-gradient(0deg, #FFFFFF 90%, rgba(255, 255, 255, 0) 105%);
      border-radius: inherit;
      transition: padding-top 0.3s, padding-bottom 0.3s;
    }

    &_info {
      position: relative;
      padding-left: 30px;
      font-weight: 600;
      font-size: 18px;
      line-height: 1.4;
      letter-spacing: 0.02em;

      &:before {
        content: '';
        position: absolute;
        top: 3px;
        left: 0;
        width: 16px;
        height: 16px;
        background: center / contain no-repeat url($__IMG_PATH + 'icons/ellipse.svg');
        box-shadow: -2px -1px 4px rgba(255, 255, 255, 0.4), 2px 1px 3px rgba(24, 47, 91, 0.2), inset 3px 3px 5px rgba(213, 222, 245, 0.4), inset -4px -3px 4px rgba(79, 103, 120, 0.2);
        border-radius: 50%;
      }
    }

    &_title {
      display: inline-block;
      white-space: nowrap;
      color: #559BEB;
      background: linear-gradient(90.36deg, #6EACF6 22%, #A685F3 77%), #4996D1;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &_text {
      margin-top: 13px;
      color: #5C6876;
      position: absolute;
      visibility: hidden;
      opacity: 0;
      transform: translateX(-100%);
      transition: transform 0.3s, opacity 0.3s;
    }

    &_point {
      position: absolute;
      margin-left: 10px;
      visibility: hidden;
      opacity: 0;
      transform: scale(0);
      transition: transform 0.3s, opacity 0.3s;
    }
  }

  .map {
    height: 100%;

    &_border {
      height: 100%;
      padding: 1px;
      background: linear-gradient(244.98deg, #FFFFFF 29.81%, rgba(219, 230, 242, 0) 53.04%, #8F9FAE 100%);
      border-radius: 18px;
    }

    &_inner {
      height: 100%;
      border-radius: inherit;
      overflow: hidden;
      background: #fff;
      box-shadow: 16px 4px 64px 0 rgba(18, 61, 101, 0.3), -16px 6px 40px 0px rgba(255, 255, 255, 0.8), -8px -6px 80px 0px rgba(255, 255, 255, 0.18) inset;
    }

    #map {
      height: 100%;

      .gm-style {
        a img,
        .gm-fullscreen-control,
        .gm-style-cc,
        .gmnoprint {
          display: none;
        }
      }
    }
  }
}
