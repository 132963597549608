body {
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
  position: relative;
}

body, input, textarea {
  font-family: $ff;
}

a {
  text-decoration: none;
  color: $color_default;
}

img {
  display: block;
  max-width: 100%;
}

label {
  display: inline-block;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #559BEB;
  -webkit-background-clip: text;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @include sm {
    padding-top: 68px;
  }
}

.clearfix {
  clear: both;
}

.no-scroll {
  overflow: hidden;
}

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
  word-break: normal;
}

.page-home {
  @include sm {
    padding-top: 0;
  }
}

.mobile-border {
  @include xs {
    position: relative;
  }

  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(0deg, rgba(73, 150, 209, 0), rgba(73, 150, 209, 0)),
    linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 36.82%, rgba(179, 102, 215, 0.4) 67.04%, rgba(90, 84, 209, 0.4) 87.16%);

    @include xs {
      content: '';
    }
  }
}

.container {
  width: $container-xl;
  margin: 0 auto;
  @include md {
    width: $container;
  }
  &--lg {
    max-width: $container-xxl;
    width: $container;
  }

  &--xl {
    max-width: 1450px;
    width: 100%;
  }

  &--md_none {
    @include md {
      max-width: none;
      width: 100%;
    }
  }
}