.contacts {
  padding: 145px 0 50px;
  background: top 0 left 0 / 100% no-repeat url($__IMG_PATH + 'contacts/bg-1.jpg'), bottom -540px right 70px / 100% no-repeat url($__IMG_PATH + 'contacts/bg-2.jpg');
  @include sm {
    background: top 0 left 0 / 100% no-repeat url($__IMG_PATH + 'contacts/bg-md-1.jpg'), bottom 0 left 0 / 100% no-repeat url($__IMG_PATH + 'contacts/bg-md-2.jpg');
  }

  .subtitle {
    @include sm {
      letter-spacing: initial;
      font-size: 18px;
    }
  }

  &__card {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 40px 90px 50px 60px;
    background-color: #FFFFFF;
    border-radius: 24px;
    box-shadow: 0 4px 30px 0 rgba(43, 45, 74, 0.17);
    @include sm {
      display: contents;
    }

    &--mobile {
      display: contents;
      @include sm {
        display: block;
        background: #FFFFFF;
        box-shadow: 0 4px 30px 0 rgba(43, 45, 74, 0.17);
        border-radius: 8px;
        padding: 33px 0 20px;
        margin-bottom: 40px;
      }

      .title {
        @include sm {
          display: none;
        }
      }

      .heading {
        @include sm {
          padding-right: 15px;
          padding-left: 26px;
          margin-bottom: 12px;
        }
      }
    }
  }

  .info {
    width: 100%;
    max-width: none;

    .heading {
      @include sm {
        -webkit-background-clip: initial;
        -webkit-text-fill-color: initial;
        background: none;
      }
    }
  }

  &__form {
    width: 52%;
    @include sm {
      width: 100%;
    }
  }

  &__description {
    width: 41%;
    @include sm {
      width: 100%;
    }

    .title,
    .subtitle,
    .heading {
      padding-left: 20px;
      @include sm {
        padding-left: 0;
      }
    }

    .subtitle {
      @include sm {
        padding-right: 15px;
        padding-left: 26px;
      }

      ul {
        li {
          @include sm {
            margin-bottom: 50px;
          }
        }
      }
    }

    .subtitle--lg {
      margin-bottom: 50px;
      @include sm {
        border-bottom: 1px solid rgba(92, 104, 118, 0.3);
        margin-bottom: 20px;
        padding-bottom: 25px;
      }
    }

    ul {
      display: flex;
      flex-direction: column;

      li {
        display: flex;
        align-items: start;
        margin-bottom: 30px;
        @include sm {
          flex-direction: column;
        }

        &:last-child {
          margin-bottom: 0;
        }

        img {
          flex: 1 1 40px;
          margin-top: -3px;
          padding-right: 10px;
          max-width: 45px;
          @include sm {
            margin-top: 0;
            max-width: 65px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  &__image {
    position: relative;
    padding: 1px;
    margin-bottom: 35px;
    border-radius: 18px;
    background: linear-gradient(245deg, #FFFFFF 30%, rgba(219, 230, 242, 0) 53%, #8F9FAE 100%);
    @include sm {
      display: none;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: inherit;
      box-shadow: 16px 4px 64px 0 rgba(18, 61, 101, 0.3), -16px 6px 40px 0 rgba(255, 255, 255, 0.8), -8px -6px 80px 0 rgba(255, 255, 255, 0.18) inset;
    }
  }

  &__border {
    border-radius: inherit;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  &__list {
    margin-top: 55px;

    .info {
      font-size: 18px;

      .heading {
        font-size: 28px;
        @include xs {
          display: none;
        }
      }

      .title {
        margin-bottom: 8px;
        @include xs {
          margin-bottom: 3px;
        }
      }

      .text,
      .title {
        @include xs {
          font-size: 14px;
        }
      }

      .row {
        column-gap: initial;
        justify-content: space-between;
        @include md {
          flex-wrap: wrap;
          justify-content: flex-start;
        }

        .col {
          letter-spacing: 0.02em;
          @include md {
            width: 33.3333%;
            margin-bottom: 40px;
          }
          @include sm {
            width: 50%;
          }
        }

        .col:last-child {
          @include sm {
            margin-bottom: 23px;
          }
        }
      }
    }
  }


  &--message {
    padding-bottom: 106px;

    .container {
      max-width: 1000px;
    }

    .info {
      max-width: 640px;
      margin: auto;

      .image {
        margin-top: 70px;
        margin-bottom: 48px;
        @include sm {
          margin-top: 30px;
          margin-bottom: 30px;
        }

        img {
          display: inline-block;
        }
      }

      .heading {
        margin-bottom: 26px;
      }

      .subtitle {
        margin-bottom: 26px;
        font-size: 24px;
        @include sm {
          font-size: 18px;
        }

        strong {
          color: $color_primary;
          background: linear-gradient(90deg, rgba(110, 172, 246, 0.4) 22%, rgba(166, 133, 243, 0.4) 50%, rgba(179, 102, 215, 0.4) 63%, rgba(90, 84, 209, 0.4) 87%), #4996D1;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      .btn {
        margin-top: 0;
        @include sm {
          margin-bottom: 25px;
        }
      }
    }

    .contacts__card {
      padding: 60px;
      text-align: center;
      background: center / cover no-repeat url($__IMG_PATH + 'contacts/card-bg.jpg');
    }

    .contacts__card--mobile {
      padding: 40px;
      background: center / cover no-repeat url($__IMG_PATH + 'contacts/card-bg.jpg');
      @include sm {
        padding: 25px;
      }

      .heading {
        @include sm {
          padding: 0;
        }
      }
    }
  }

  &--unsuccess,
  &--success {
    display: none;
  }

  &--unsuccess {
    .heading {
      color: #CE72CB;
    }
  }

  &--404 {
    .info {
      margin-bottom: 67px;
    }

    .image {
      margin-top: 38px;
      margin-bottom: 0;
      margin-right: 55px;
    }
  }
}
