/*============ Gradients ==============*/
/* 55555 */
$primary_gradient: linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 49.38%, rgba(179, 102, 215, 0.4) 62.97%, rgba(90, 84, 209, 0.4) 87.16%), #4996D1;
/* 585959 */
$secondary_gradient: linear-gradient(90.36deg, #6EACF6 21.97%, #A685F3 76.97%), #4996D1;
/* Gradient for Controls */
$controls_gradient: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #F2F5FB;
/* Gradients for steps */
$border_gradient_primary: linear-gradient(155deg, #FFFFFF 29.81%, rgba(219, 230, 242, 0) 53.04%, #8F9FAE 100%);
$border_gradient_secondary: linear-gradient(231deg, rgba(72, 100, 125, 0.5) 3.33%, rgba(107, 130, 153, 0.5) 28.55%, rgba(218, 226, 235, 0.5) 50.97%, rgba(140, 162, 183, 0.5) 76.74%, rgba(237, 242, 247, 0.5) 110.93%);
/* Gradient for border */
$border_gradient: linear-gradient(304.83deg, rgba(89, 119, 173, 0.7) -3.37%, rgba(128, 149, 188, 0.7) 60.9%, rgba(218, 226, 235, 0.7) 118.03%, rgba(140, 155, 183, 0.7) 183.73%, rgba(237, 242, 247, 0.7) 270.85%);

/*============ Shadows ================*/
$primary_shadow: -16px -16px 40px rgba(255, 255, 255, 0.8), 16px 4px 64px rgba(18, 61, 101, 0.3), inset -8px -6px 80px rgba(255, 255, 255, 0.18);
/* Shadow for steps */
$steps_shadow_gradient: 18px 12px 45px rgba(23, 37, 87, 0.42), -8px -10px 30px rgba(232, 234, 243, 0.05), inset -16px -6px 80px rgba(248, 249, 249, 0.03);
/* Shadow for border line */
$border_shadow: inset 0.3px 0.3px 0.5px rgba(33, 68, 104, 0.4), inset -0.5px -0.5px 0.5px rgba(255, 255, 255, 0.4);

/* For text gradient @include gradient_text($primary_gradient) */
@mixin gradient_text($gradient) {
  color: $color_primary;
  background: $gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.scheme {
  margin-bottom: 80px;
  @include xs {
    margin-bottom: 15px;
    padding-bottom: 60px;
  }

  // Info Section
  &__info {
    margin-top: 54px;
    @include md-lg {
      margin-top: 36px;
    }
    @include sm {
      margin-top: 56px;
    }
    @include xs {
      margin-top: 24px;
    }
  }

  // Tabs Section
  &__tabs {
    min-height: 500px;
    max-width: 1318px;
    padding-right: 24px;
    padding-left: 24px;
    margin: 64px auto 0;
    @include md-lg {
      max-width: 1150px;
    }
    @include sm {
      padding-right: 15px;
      padding-left: 15px;
      margin-top: 54px;
    }
    @include xs {
      min-height: 767px;
      margin-top: 25px;
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  // Tab Controls
  &__controls {
    display: flex;
    width: 100%;
  }

  &__control {
    position: relative;
    width: 25%;
    border-radius: 24px 24px 0 0;
    @include xs {
      border-radius: 8px 8px 0 0;
    }

    &:not(:last-child) {
      margin-right: 22px;
      @include md-lg {
        margin-right: 18px;
      }
      @include sm {
        margin-right: 16px;
      }
      @include xs {
        margin-right: 4px;
      }
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 480px;
      background: $controls_gradient;
      box-shadow: $primary_shadow;
      border-radius: 24px;
      @include xs {
        min-height: 433px;
        box-shadow: 0 4px 30px rgba(43, 45, 74, 0.17);
        border-radius: 8px;
      }
    }

    // Active State for tab control
    &.active {
      box-shadow: $primary_shadow;

      &:before {
        background: #FFFFFF;
        min-height: 465px;
        @include xs {
          min-height: 376px;
        }
      }

      .btn {
        background: #FFFFFF;

        &__text,
        &__prefix {
          @include gradient_text($primary_gradient)
        }
      }
    }

    // Tab Control Button
    .btn {
      display: flex;
      align-items: center;
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      min-height: 90px;
      padding: 17px 20px 9px 30px;
      border-radius: inherit;
      font-size: 18px;
      text-align: left;
      user-select: none;
      font-weight: 700;
      line-height: 1.28;
      letter-spacing: 0.02em;
      color: #959BAF;
      @include sm {
        padding: 12px 10px 5px 34px;
      }
      @include xs {
        justify-content: center;
        min-height: 33px;
        padding: 7px 5px 0;
      }

      &__text,
      &__prefix {
        display: block;
        pointer-events: none;
      }

      &__text {
        margin-left: 19px;
        @include md-lg {
          margin-left: 0;
        }
        @include xs {
          display: none;
        }
      }

      &__prefix {
        font-size: 50px;
        @include md-lg {
          display: none;
        }
        @include xs {
          display: flex;
          margin: auto;
          font-size: 20px;
        }

        span {
          display: none;
          @include xs {
            display: block;
          }
        }
      }
    }
  }

  // Tab Content
  &__content {
    margin-top: -1px;
    font-size: 14px;
    line-height: 1.28;
    letter-spacing: 0.02em;
    @include xs {
      font-size: 10px;
    }
  }

  &__tab {
    position: relative;
    min-height: 411px;
    border-radius: 24px;
    background: #FFFFFF;
    box-shadow: $primary_shadow;
    @include xs {
      min-height: 735px;
      padding: 10px;
      border-radius: 18px;
    }

    &:first-child.active {
      border-radius: 0 24px 24px 24px;
      @include xs {
        border-radius: 0 18px 18px 18px;
      }
    }

    &:last-child.active {
      border-radius: 24px 0 24px 24px;
      @include xs {
        border-radius: 18px 0 18px 18px;
      }
    }

    // Tab 1
    &--1 {
      padding: 48px 46px 54px 24px;
      @include md-lg {
        padding: 64px 27px 45px;
      }
      @include sm {
        padding: 64px 23px 45px 27px;
      }
      @include xs {
        padding: 40px 10px 23px;
      }

      .scheme__title {
        &--mobile {
          margin-bottom: 14px;
          margin-left: 10px;
        }
      }
    }

    // Tab 2
    &--2 {
      padding: 54px 40px 54px 71px;
      @include md-lg {
        padding: 58px 64px 60px 53px;
      }
      @include sm {
        padding: 58px 44px 65px 26px;
      }
      @include xs {
        padding: 34px 10px 37px;
      }

      .scheme__title {
        &--mobile {
          margin-bottom: 14px;
          margin-left: 10px;
        }
      }
    }

    // Tab 3
    &--3 {
      padding: 54px 46px 54px 43px;
      @include md-lg {
        padding: 44px 10px 56px 55px;
      }
      @include sm {
        padding: 44px 10px 56px 32px;
      }
      @include xs {
        padding: 39px 10px 17px;
      }

      .scheme__title {
        &--mobile {
          margin-bottom: 14px;
          margin-left: 10px;
        }
      }
    }

    // Tab 4
    &--4 {
      padding: 52px 40px 54px 71px;
      @include md-lg {
        padding: 57px 17px 71px 64px;
      }
      @include sm {
        padding: 57px 36px 72px 36px;
      }
      @include xs {
        padding: 39px 10px 30px;
      }

      .scheme__title {
        &--mobile {
          margin-bottom: 14px;
          margin-left: 10px;
        }
      }
    }
  }

  &__steps {
    display: flex;
    align-items: center;
    @include xs {
      flex-direction: column;
    }
  }

  // Step by step =)
  &__step {
    // Step 1 (Tab 1)
    &--1 {
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-template-rows: repeat(2, auto);
      grid-column-gap: 66px;
      grid-row-gap: 38px;
      align-items: center;
      max-width: 443px;
      margin-right: 77px;
      @include md-lg {
        grid-row-gap: 26px;
        max-width: 292px;
        margin-right: 74px;
      }
      @include sm {
        margin-right: 34px;
      }
      @include xs {
        grid-row-gap: 19px;
        max-width: none;
        margin-right: 0;
      }

      .scheme {
        &__item {
          // Item 1 (Step 1)
          &--1 {
            grid-area: 1 / 1 / 2 / 3;
            @include md-lg {
              border-radius: 14px;
            }
            @include xs {
              margin-right: 10px;
            }

            &.delimiter {
              &--right {
                &:after {
                  right: -15px;
                  @include md-lg {
                    top: 84%;
                    right: -14px;
                  }
                  @include sm {
                    right: -2px;
                  }
                  @include xs {
                    top: 100%;
                    right: 11px;
                    transform: translate(0, 12px);
                    width: 17px;
                    height: 207px;
                    background: center / cover no-repeat url($__IMG_PATH + 'schema/delim-vertical-lg.png');
                  }
                  @include xxs {
                    width: 22px;
                    height: 229px;
                  }
                }
              }
            }

            .scheme {
              &__inner {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(120px, auto));
                grid-column-gap: 20px;
                align-items: center;
                min-height: 63px;
                padding: 12px 36px;
                background-position: top 0 right 0;
                @include md-lg {
                  grid-column-gap: 16px;
                  align-items: baseline;
                  padding: 12px 6px 12px 20px;
                }
              }

              &__title {
                font-size: 18px;
                letter-spacing: 0.02em;
                white-space: nowrap;
                @include md-lg {
                  font-size: 16px;
                }
              }

              &__items {
                .item {
                  p {
                    max-width: 138px;
                    @include md-lg {
                      //margin-left: 16px;
                    }
                    @include xs {
                      font-size: 14px;
                    }
                  }

                  img {
                    max-width: 35px;
                    margin-right: 20px;

                    //margin-left: 37px;
                    @include md-lg {
                      display: none;
                    }
                  }
                }
              }
            }
          }

          // Item 2 (Step 1)
          &--2 {
            grid-area: 2 / 1 / 3 / 2;
            margin-left: 15px;
            @include md-lg {
              display: none;
            }

            .scheme {
              &__items {
                .item {
                  flex-direction: column;
                  align-items: baseline;

                  & + .item {
                    margin-top: 28px;
                  }

                  img {
                    margin-left: 10px;
                  }
                }
              }
            }
          }

          // Item 3 (Step 1)
          &--3 {
            grid-area: 2 / 2 / 3 / 3;
            max-width: 279px;
            margin-left: auto;
            margin-right: auto;
            @include md-lg {
              grid-area: 2 / 1 / 3 / 3;
            }
            @include xs {
              max-width: none;
              margin-left: 0;
            }
            @include xxs {
              max-width: 234px;
              margin-left: 5px;
              margin-right: 18%;
            }

            &.delimiter {
              &--right {
                &:after {
                  right: -15px;
                  @include md-lg {
                    top: 73%;
                    right: -21px;
                  }
                  @include sm {
                    right: -8px;
                  }
                  @include xs {
                    top: 100%;
                    right: auto;
                    left: 12px;
                    transform: translate(0, 4px);
                    width: 19px;
                    height: 47px;
                    background: center / cover no-repeat url($__IMG_PATH + 'schema/delim-vertical.png');
                  }
                }
              }
            }

            .scheme {
              &__inner {
                padding: 20px 37px 27px 38px;
                @include xs {
                  padding: 20px 15px 20px 30px;
                }
              }

              &__title {
                margin-bottom: 10px;
                font-size: 18px;
                @include xs {
                  margin-bottom: 8px;
                  font-size: 16px;
                  letter-spacing: initial;
                }
              }

              &__items {
                margin-left: 3px;

                .item {
                  & + .item {
                    margin-top: 22px;
                  }

                  img {
                    max-width: 35px;
                    margin-right: 23px;
                    @include xs {
                      max-width: 29px;
                      margin-right: 21px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Step 2 (Tab 1)
    &--2 {
      max-width: 384px;
      margin-right: 78px;
      @include md-lg {
        margin-right: 71px;
      }
      @include sm {
        margin-right: 26px;
      }
      @include xs {
        max-width: none;
        margin-top: 33px;
        margin-right: 0;
      }

      .scheme {
        &__item {
          &--1 {
            .scheme {
              &__inner {
                display: flex;
                flex-direction: column;
                min-height: 303px;
                padding: 25px 14px 33px 34px;
                @include md-lg {
                  min-height: 300px;
                  padding: 22px 8px 12px 32px;
                }
                @include sm {
                  padding: 22px 8px 12px 32px;
                }
                @include xs {
                  min-height: 242px;
                  padding: 20px 8px 12px 20px;
                }
              }

              &__title {
                margin-bottom: 9px;

                img {
                  max-width: 98px;
                  @include xs {
                    max-width: 80px;
                  }
                }
              }

              &__subtitle {
                margin-bottom: 17px;
                font-size: 14px;
                letter-spacing: initial;
                @include xs {
                  margin-bottom: 13px;
                  font-size: 11px;
                  letter-spacing: inherit;
                }
              }

              &__items {
                .ul {
                  display: grid;
                  grid-template-columns: repeat(auto-fit, minmax(130px, auto));
                  grid-column-gap: 32px;
                  padding-bottom: 9px;
                  @include md-lg {
                    grid-column-gap: 14px;
                  }
                  @include sm {
                    grid-column-gap: 34px;
                  }
                  @include xs {
                    grid-template-columns: repeat(2, auto);
                    grid-column-gap: 23px;
                    padding-bottom: 7px;
                  }
                }

                .item {
                  padding-bottom: 10px;
                  font-weight: 600;
                  line-height: 1.4;
                  letter-spacing: initial;
                  @include xs {
                    padding-bottom: 8px;
                    font-size: 11px;
                    letter-spacing: 0.03em;
                  }
                }

                .border {
                  &--between {
                    grid-template-columns: repeat(2, auto);
                    grid-column-gap: 15px;
                    padding-top: 17px;
                    padding-bottom: 0;
                    @include sm {
                      grid-template-columns: repeat(auto-fit, minmax(130px, auto));
                      grid-column-gap: 20px
                    }
                    @include xs {
                      grid-template-columns: repeat(2, auto);
                      grid-column-gap: 7px;
                      padding-top: 14px;
                    }

                    .item {
                      padding: 11px 10px 5px 0;
                      line-height: 1.2;
                      @include xs {
                        padding: 8px 5px 5px;
                      }

                      &:nth-child(odd) {
                        max-width: 142px;
                        @include sm {
                          max-width: 145px;
                        }
                        @include xs {
                          max-width: none;
                        }
                        @include xxs {
                          max-width: 118px;
                        }
                      }

                      &:before {
                        content: none;
                      }

                      img {
                        max-width: 40px;
                        margin-right: 15px;
                        @include xs {
                          max-width: 32px;
                          margin-right: 12px;
                        }
                      }

                      p {
                        @include gradient_text($primary_gradient);
                      }
                    }
                  }
                }

                &.border {
                  &--top {
                    &:after {
                      left: -10px;
                      right: 27px;
                      width: auto;
                      @include xs {
                        left: 2px;
                        right: 15px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Step 3 (Tab 1)
    &--3 {
      max-width: 217px;
      @include md-lg {
        max-width: 191px;
      }
      @include xs {
        max-width: none;
      }

      .scheme {
        &__item {
          &--1 {
            .scheme {
              &__items {
                margin-top: -20px;
                margin-bottom: -20px;
                @include xs {
                  display: grid;
                  grid-template-columns: repeat(3, auto);
                  grid-column-gap: 10px;
                  justify-content: space-evenly;
                  margin-top: 62px;
                  margin-bottom: 0;
                }
                @include xxs {
                  justify-content: flex-start;
                }

                .border {
                  &--top {
                    &:after {
                      @include xs {
                        content: none;
                      }
                    }

                    &:first-child {
                      &:after {
                        content: none;
                      }
                    }
                  }
                }

                .delimiter {
                  &--left {
                    &:before {
                      left: -15px;
                      @include md-lg {
                        left: -21px;
                        width: 42px;
                        height: 21px;
                        background: center / cover no-repeat url($__IMG_PATH + 'schema/delim-sm.png');
                      }
                      @include sm {
                        left: 17px;
                      }
                      @include xs {
                        top: 0;
                        left: 50%;
                        transform: translate(0, -100%);
                        width: 19px;
                        height: 47px;
                        background: center / cover no-repeat url($__IMG_PATH + 'schema/delim-vertical.png');
                      }
                    }
                  }
                }

                .item {
                  justify-content: space-between;
                  width: 100%;
                  @include xs {
                    justify-content: flex-start;
                    width: auto;
                  }

                  &__img {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    max-width: 72px;
                    @include sm {
                      justify-content: flex-end;
                    }
                    @include xs {
                      width: auto;
                      max-width: none;
                    }
                  }

                  &__text {
                    width: 100%;
                    max-width: 133px;
                    margin-left: 10px;
                    font-weight: 600;
                    @include md-lg {
                      font-size: 12px;
                    }
                    @include xs {
                      font-size: 10px;
                    }
                    @include xxs {
                      font-size: 7px;
                    }
                  }
                }

                & > .item {
                  padding-top: 20px;
                  padding-bottom: 20px;
                  @include xs {
                    padding-top: 0;
                    padding-bottom: 0;
                  }

                  &:first-child {
                    flex-direction: column;
                    align-items: baseline;
                    justify-content: center;
                  }

                  &:nth-child(1) {
                    .item {
                      &:nth-child(1) {
                        .item__img {
                          img {
                            margin-left: auto;
                            @include md-lg {
                              max-width: 45px;
                            }
                            @include xs {
                              max-width: 25px;
                            }
                          }
                        }
                      }

                      &:nth-child(2) {
                        .item__img {
                          img {
                            margin-left: auto;
                            @include md-lg {
                              max-width: 62px;
                            }
                            @include xs {
                              max-width: 32px;
                            }
                          }
                        }
                      }
                    }
                  }

                  &:nth-child(2) {
                    .item {
                      &__img {
                        img {
                          @include md-lg {
                            max-width: 45px;
                          }
                          @include xs {
                            max-width: 28px;
                          }
                        }
                      }
                    }
                  }

                  &:nth-child(3) {
                    .item {
                      &__img {
                        img {
                          @include md-lg {
                            max-width: 45px;
                          }
                          @include xs {
                            max-width: 38px;
                          }
                        }
                      }
                    }
                  }

                  .item {
                    & + .item {
                      margin-top: 15px;
                      @include xs {
                        margin-top: 2px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Step 4 (Tab 2)
    &--4 {
      max-width: 384px;
      margin-right: 62px;
      @include md-lg {
        max-width: 307px;
        margin-right: 88px;
      }
      @include sm {
        margin-right: 57px;
      }
      @include xs {
        max-width: none;
        margin-right: 0;
        margin-bottom: 45px;
      }

      .scheme {
        &__item {
          &--1 {
            @include md-lg {
              border-radius: 18px;
            }

            .scheme {
              &__inner {
                padding: 29px 38px 49px 38px;
                @include md-lg {
                  padding: 26px 34px 45px 30px;
                }
                @include xs {
                  padding: 18px 21px 32px 30px;
                }
              }

              &__title {
                margin-bottom: 40px;
                font-size: 18px;
                letter-spacing: inherit;
                @include md-lg {
                  margin-bottom: 52px;
                }
                @include xs {
                  margin-bottom: 20px;
                }
              }

              &__items {
                display: grid;
                grid-template-columns: 136px auto;
                grid-column-gap: 10px;
                grid-row-gap: 47px;
                @include md-lg {
                  grid-template-columns: repeat(auto-fit, minmax(100px, auto));
                  grid-column-gap: 4px;
                  grid-row-gap: 40px;
                }
                @include xs {
                  grid-template-columns: repeat(2, 1fr);
                  grid-row-gap: 34px;
                }

                .item {
                  align-items: flex-start;

                  &:nth-child(n+3) {
                    align-items: center;
                  }

                  &__img {
                    width: 100%;
                    max-width: 31px;
                    text-align: center;
                    @include md-lg {
                      max-width: 20px;
                    }
                  }

                  &__text {
                    margin-left: 12px;
                    @include md-lg {
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Step 5 (Tab 2)
    &--5 {
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-column-gap: 56px;
      align-items: center;
      max-width: 496px;
      margin-right: 65px;
      @include md-lg {
        grid-template-rows: repeat(2, auto);
        grid-template-columns: none;
        grid-row-gap: 20px;
        grid-column-gap: 0;
        max-width: 300px;
        margin-right: 116px;
      }
      @include sm {
        margin-right: 71px;
      }
      @include xs {
        grid-row-gap: 17px;
        max-width: none;
        margin-right: 0;
        margin-bottom: 34px;
      }

      .scheme {
        &__item {
          // Item 1 (Step 5)
          &--1 {
            width: 100%;
            max-width: 194px;
            @include md-lg {
              max-width: none;
              border-radius: 18px;
            }

            .delimiter {
              &--left {
                &:before {
                  left: -7px;
                  @include md-lg {
                    top: 33%;
                    left: -23px;
                    width: 42px;
                    height: 21px;
                    background: center / cover no-repeat url($__IMG_PATH + 'schema/delim-sm.png');
                  }
                  @include sm {
                    left: -10px;
                  }
                  @include xs {
                    top: 19px;
                    left: 21px;
                    transform: translate(0, -100%);
                    width: 19px;
                    height: 47px;
                    background: center / cover no-repeat url($__IMG_PATH + 'schema/delim-vertical.png');
                  }
                }
              }
            }

            .scheme {
              &__inner {
                padding: 31px 18px 26px 27px;
                @include md-lg {
                  padding: 21px 18px 17px 27px;
                }
              }

              &__title {
                margin-bottom: 7px;
                @include md-lg {
                  max-width: 83px;
                }
              }

              &__subtitle {
                margin-bottom: 15px;
                font-size: 16px;
                letter-spacing: initial;
                @include md-lg {
                  margin-bottom: 0;
                  margin-right: 14px;
                  font-size: 14px;
                }
              }

              &__items {
                @include md-lg {
                  display: flex;
                  align-items: center;
                }

                .item {
                  a {
                    @include md-lg {
                      padding-right: 15px;
                      font-size: 12px;
                    }

                    &:after {
                      @include md-lg {
                        width: 9px;
                        height: 9px;
                      }
                    }
                  }
                }
              }
            }
          }

          // Item 2 (Step 5)
          &--2 {
            width: 100%;
            max-width: 246px;
            @include md-lg {
              max-width: none;
              border-radius: 18px;
            }

            .delimiter {
              &--left {
                &:before {
                  left: -3px;
                  @include md-lg {
                    top: 15%;
                    left: -23px;
                    width: 42px;
                    height: 21px;
                    background: center / cover no-repeat url($__IMG_PATH + 'schema/delim-sm.png');
                  }
                  @include sm {
                    left: -10px;
                  }
                  @include xs {
                    top: 12px;
                    left: 21px;
                    transform: translate(0, -100%);
                    width: 19px;
                    height: 47px;
                    background: center / cover no-repeat url($__IMG_PATH + 'schema/delim-vertical.png');
                  }
                }
              }
            }

            .scheme {
              &__inner {
                padding: 25px 25px 13px 43px;
                @include md-lg {
                  padding: 19px 12px 4px 18px;
                }
              }

              &__title {
                margin-bottom: 7px;
                @include md-lg {
                  max-width: 85px;
                  margin-bottom: 4px;
                }
              }

              &__subtitle {
                margin-bottom: 12px;
                font-size: 16px;
                letter-spacing: initial;
                @include md-lg {
                  font-size: 14px;
                }
              }

              &__items {
                @include md-lg {
                  display: grid;
                  grid-template-columns: 144px auto;
                  grid-column-gap: 6px;
                  letter-spacing: initial;
                }

                .item {
                  align-items: flex-start;
                  @include md-lg {
                    font-size: 12px;
                    line-height: 1.4;
                    padding-bottom: 18px;
                  }
                  @include xs {
                    padding-left: 24px;
                  }

                  &:before {
                    @include md-lg {
                      top: 2px;
                      width: 10px;
                      height: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Step 6 (Tab 2)
    &--6 {
      max-width: 150px;
      @include xs {
        align-self: flex-start;
        max-width: 267px;
        padding-left: 19px;
      }

      .scheme {
        &__item {
          &--1 {
            .delimiter {
              &--left {
                &:before {
                  top: 49%;
                  left: -9px;
                  @include md-lg {
                    top: 45%;
                    left: -29px;
                    width: 42px;
                    height: 21px;
                    background: center / cover no-repeat url($__IMG_PATH + 'schema/delim-sm.png');
                  }
                  @include sm {
                    left: -14px;
                  }
                  @include xs {
                    top: -5px;
                    left: 4px;
                    transform: translate(0, -100%);
                    width: 19px;
                    height: 47px;
                    background: center / cover no-repeat url($__IMG_PATH + 'schema/delim-vertical.png');
                  }
                }
              }
            }

            .scheme {
              &__items {
                @include xs {
                  display: grid;
                  grid-template-columns: repeat(auto-fit, minmax(73px, auto));
                  grid-column-gap: 12px;
                  align-items: center;
                }

                .item {
                  & + .item {
                    margin-top: 30px;
                    @include xs {
                      margin-top: 0;
                    }
                  }

                  &__img {
                    width: 100%;
                    max-width: 51px;
                    text-align: center;
                    @include xs {
                      max-width: 30px;
                    }

                    img {
                      @include xs {
                        max-height: 30px;
                      }
                    }
                  }

                  &__text {
                    margin-left: 15px;
                    @include xs {
                      max-width: 44px;
                      margin-left: 10px;
                      font-size: 9px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Step 7 (Tab 3)
    &--7 {
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-column-gap: 50px;
      align-items: center;
      max-width: 448px;
      margin-right: 53px;
      @include md-lg {
        grid-template-columns: none;
        grid-template-rows: repeat(2, auto);
        grid-column-gap: 0;
        grid-row-gap: 41px;
        max-width: 307px;
        margin-right: 85px;
      }
      @include sm {
        margin-right: 66px;
      }
      @include xs {
        grid-row-gap: 8px;
        max-width: none;
        margin-right: 0;
        margin-bottom: 30px;
      }

      .scheme {
        &__item {
          // Item 1 (Step 7)
          &--1 {
            width: 100%;
            max-width: 89px;
            @include md-lg {
              max-width: none;
            }
            @include xs {
              max-width: 236px;
              margin-right: auto;
              margin-left: auto;
            }

            .delimiter {
              &--right {
                &:after {
                  right: -14px;
                  width: 42px;
                  height: 21px;
                  background: center / cover no-repeat url($__IMG_PATH + 'schema/delim-sm.png');
                  @include md-lg {
                    content: none;
                  }
                }
              }

              &--bottom {
                &:after {
                  content: none;
                  @include md-lg {
                    content: '';
                    bottom: 0;
                    left: 50%;
                    transform: translate(-50%, 100%);
                    width: 19px;
                    height: 41px;
                    background: center / cover no-repeat url($__IMG_PATH + 'schema/delim-vertical-sm.png');
                  }
                  @include xs {
                    content: none;
                  }
                }
              }
            }

            .scheme {
              &__items {
                margin-bottom: 25px;
                @include md-lg {
                  display: flex;
                  justify-content: space-between;
                  margin-right: 20px;
                  margin-bottom: 0;
                }
                @include xs {
                  margin-top: 7px;
                  margin-right: 0;
                }

                .item {
                  flex-direction: column;
                  align-items: baseline;
                  @include md-lg {
                    font-size: 12px;
                  }
                  @include xs {
                    font-size: 10px;
                  }

                  & + .item {
                    margin-top: 27px;
                    @include md-lg {
                      margin-top: 0;
                    }
                  }

                  &__img {
                    margin-left: 10px;
                    @include md-lg {
                      display: flex;
                      justify-content: center;
                      width: 100%;
                      margin-left: 0;
                      margin-bottom: 5px;
                    }

                    img {
                      @include md-lg {
                        max-width: 30px;
                      }
                      @include xs {
                        max-width: 25px;
                      }
                    }
                  }
                }
              }
            }
          }

          // Item 2 (Step 7)
          &--2 {
            width: 100%;
            max-width: 312px;
            @include xs {
              max-width: none;
            }

            &.delimiter {
              &--right {
                &:after {
                  @include md-lg {
                    right: -14px;
                    width: 42px;
                    height: 21px;
                    background: center / cover no-repeat url($__IMG_PATH + 'schema/delim-sm.png');
                  }
                  @include sm {
                    right: 1px;
                  }
                  @include xs {
                    top: 100%;
                    right: auto;
                    left: 12px;
                    transform: translate(0, 4px);
                    width: 19px;
                    height: 47px;
                    background: center / cover no-repeat url($__IMG_PATH + 'schema/delim-vertical.png');
                  }
                }
              }
            }

            .scheme {
              &__inner {
                min-height: 303px;
                padding: 29px 10px 45px 29px;
                @include md-lg {
                  min-height: 215px;
                  padding: 40px 27px 25px 29px;
                }
                @include xs {
                  min-height: 160px;
                  padding: 25px 31px 25px 23px;
                }
              }

              &__title {
                margin-bottom: 31px;
                @include md-lg {
                  margin-bottom: 41px;
                  font-size: 12px;
                }
                @include xs {
                  margin-bottom: 13px;
                }
              }

              &__items {
                @include md-lg {
                  display: grid;
                  grid-template-columns: repeat(2, auto);
                }

                .item {
                  max-width: 215px;
                  margin-left: auto;
                  margin-right: auto;
                  @include md-lg {
                    font-size: 10px;
                  }

                  & + .item {
                    margin-top: 33px;
                    @include md-lg {
                      margin-top: 0;
                    }
                  }

                  &__img {
                    width: 100%;
                    max-width: 37px;
                    text-align: center;
                    @include md-lg {
                      max-width: 25px;
                    }
                  }

                  &__text {
                    width: 100%;
                    max-width: 127px;
                    margin-left: 20px;
                    @include md-lg {
                      max-width: 80px;
                      margin-left: 14px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Step 8 (Tab 3)
    &--8 {
      max-width: 384px;
      margin-right: 75px;
      @include md-lg {
        max-width: 325px;
      }
      @include sm {
        margin-right: 45px;
      }
      @include xs {
        max-width: none;
        margin-right: 0;
      }

      .scheme {
        &__item {
          &--1 {
            @include md-lg {
              border-radius: 18px;
            }

            .scheme {
              &__inner {
                min-height: 303px;
                padding: 18px 14px 33px 31px;
                @include md-lg {
                  min-height: 309px;
                  padding: 25px 14px 15px 27px;
                }
                @include xs {
                  min-height: 224px;
                  padding: 25px 14px 6px 14px;
                }
              }

              &__title {
                margin-bottom: 11px;
                @include md-lg {
                  max-width: 80px;
                  margin-bottom: 0;
                }
                @include xs {
                  margin-bottom: 3px;
                }
              }

              &__subtitle {
                margin-bottom: 17px;
                font-size: 14px;
                letter-spacing: initial;
                @include md-lg {
                  margin-bottom: 39px;
                  font-size: 12px;
                }
                @include xs {
                  margin-bottom: 13px;
                }
              }

              &__items {
                .ul {
                  display: grid;
                  grid-template-columns: repeat(auto-fit, minmax(130px, auto));
                  grid-column-gap: 3px;
                  padding-bottom: 9px;
                  @include md-lg {
                    grid-column-gap: 18px;
                    grid-row-gap: 22px;
                    padding-bottom: 13px;
                  }
                  @include xs {
                    grid-template-columns: repeat(auto-fit, minmax(110px, auto));
                    grid-column-gap: 11px;
                    grid-row-gap: 2px;
                    padding-bottom: 4px;
                  }
                }

                .item {
                  @include md-lg {
                    font-size: 10px;
                  }

                  &:before {
                    @include md-lg {
                      width: 8px;
                      height: 8px;
                    }
                  }
                }

                &.border {
                  &--top {
                    &:after {
                      left: -9px;
                      right: 22px;
                      width: auto;
                      @include md-lg {
                        left: 5px;
                      }
                      @include xs {
                        left: 0;
                        right: 18px;
                      }
                    }

                    .ul {
                      padding-top: 23px;
                      padding-bottom: 0;
                      grid-column-gap: 21px;
                      @include md-lg {
                        padding-top: 27px;
                        grid-column-gap: 18px;
                      }
                      @include xs {
                        padding-top: 18px;
                        grid-column-gap: 25px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Step 9 (Tab 3)
    &--9 {
      max-width: 217px;
      @include md-lg {
        max-width: 191px;
      }
      @include sm {
        max-width: 185px;
      }
      @include xs {
        max-width: none;
      }

      .scheme {
        &__item {
          &--1 {
            .scheme {
              &__items {
                margin-top: -20px;
                margin-bottom: -20px;
                @include xs {
                  margin-top: 30px;
                  margin-bottom: 0;
                  margin-left: 15px;
                }

                &.delimiter {
                  &--top {
                    &:after {
                      content: none;
                      @include xs {
                        content: '';
                        top: -5px;
                        left: -2px;
                        transform: translate(0, -100%);
                        width: 19px;
                        height: 47px;
                        background: center / cover no-repeat url($__IMG_PATH + 'schema/delim-vertical.png');
                      }
                    }
                  }
                }

                .border {
                  &--top {
                    &:after {
                      @include md-lg {
                        content: none;
                      }
                    }

                    &:first-child {
                      &:after {
                        content: none;
                      }
                    }
                  }
                }

                .delimiter {
                  &--left {
                    &:before {
                      left: -15px;
                      @include md-lg {
                        left: -11px;
                        width: 42px;
                        height: 21px;
                        background: center / cover no-repeat url($__IMG_PATH + 'schema/delim-sm.png');
                      }
                      @include sm {
                        left: 9px;
                      }
                      @include xs {
                        content: none;
                      }
                    }
                  }
                }

                .item {
                  justify-content: space-between;
                  width: 100%;
                  @include xs {
                    justify-content: flex-start;
                    width: auto;
                  }

                  &__img {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    max-width: 72px;
                    @include sm {
                      justify-content: flex-end;
                    }
                    @include xs {
                      width: auto;
                      max-width: none;
                    }
                  }

                  &__text {
                    width: 100%;
                    max-width: 133px;
                    margin-left: 10px;
                    font-weight: 600;
                    @include md-lg {
                      font-size: 12px;
                    }
                    @include xs {
                      font-size: 10px;
                      max-width: none;
                    }
                  }
                }

                & > .item {
                  padding-top: 20px;
                  padding-bottom: 20px;
                  @include xs {
                    padding-top: 9px;
                    padding-bottom: 9px;
                  }

                  &:first-child {
                    flex-direction: column;
                    align-items: baseline;
                    justify-content: center;
                    @include xs {
                      flex-direction: row;
                      align-items: center;
                      justify-content: flex-start;
                    }
                  }

                  &:nth-child(1) {
                    .item {
                      &:nth-child(1) {
                        .item__img {
                          img {
                            margin-left: auto;
                            @include md-lg {
                              max-width: 45px;
                            }
                            @include xs {
                              max-width: 36px;
                            }
                          }
                        }
                      }

                      &:nth-child(2) {
                        .item__img {
                          img {
                            margin-left: auto;
                            @include md-lg {
                              max-width: 62px;
                            }
                            @include xs {
                              max-width: 48px;
                            }
                          }
                        }
                      }
                    }
                  }

                  &:nth-child(2) {
                    .item {
                      &__img {
                        img {
                          @include md-lg {
                            max-width: 45px;
                          }
                          @include xs {
                            max-width: 39px;
                          }
                        }
                      }
                    }
                  }

                  &:nth-child(3) {
                    @include xs {
                      padding: 0;
                    }

                    .item {
                      &__img {
                        img {
                          @include md-lg {
                            max-width: 45px;
                          }
                          @include xs {
                            max-width: 38px;
                          }
                        }
                      }
                    }
                  }

                  .item {
                    & + .item {
                      margin-top: 15px;
                      @include xs {
                        margin-top: 0;
                        margin-left: 10px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Step 10 (Tab 4)
    &--10 {
      max-width: 384px;
      margin-right: 63px;
      @include md-lg {
        max-width: 307px;
        margin-right: 82px;
      }
      @include sm {
        margin-right: 67px;
      }
      @include xs {
        max-width: none;
        margin-right: 0;
        margin-bottom: 35px;
      }

      .scheme {
        &__item {
          &--1 {
            &.delimiter {
              &--right {
                &:after {
                  @include md-lg {
                    top: 47%;
                    right: -22px;
                    width: 42px;
                    height: 21px;
                    background: center / cover no-repeat url($__IMG_PATH + 'schema/delim-sm.png');
                  }
                  @include sm {
                    right: -10px;
                  }
                  @include xs {
                    top: 100%;
                    right: auto;
                    left: 21px;
                    transform: translate(0, 9px);
                    width: 19px;
                    height: 47px;
                    background: center / cover no-repeat url($__IMG_PATH + 'schema/delim-vertical.png');
                  }
                }
              }
            }

            .scheme {
              &__inner {
                min-height: 303px;
                padding: 29px 22px 40px 39px;
                @include md-lg {
                  min-height: 279px;
                  padding: 29px 20px 30px 25px;
                }
                @include xs {
                  min-height: 248px;
                  padding: 29px 20px 20px 25px;
                }
              }

              &__title {
                margin-bottom: 30px;
                font-size: 18px;
                @include md-lg {
                  margin-bottom: 40px;
                  font-size: 14px;
                }
                @include xs {
                  margin-bottom: 30px;
                }

                img {
                  vertical-align: middle;
                }
              }

              &__items {
                display: grid;
                grid-template-columns: repeat(2, auto);
                grid-column-gap: 10px;
                grid-row-gap: 35px;
                @include md-lg {
                  grid-row-gap: 43px;
                }
                @include sm {
                  grid-template-columns: repeat(auto-fit, minmax(110px, auto));
                }
                @include xs {
                  grid-template-columns: repeat(2, auto);
                  grid-row-gap: 35px;
                }

                .item {
                  align-items: flex-start;

                  &:nth-child(odd) {
                    max-width: 128px;
                    @include md-lg {
                      max-width: 107px;
                    }
                    @include xs {
                      max-width: none;
                    }
                    @include xxs {
                      max-width: 107px;
                    }
                  }

                  &__img {
                    width: 100%;
                    max-width: 34px;
                    text-align: center;
                    @include md-lg {
                      max-width: 28px;
                    }
                  }

                  &__text {
                    margin-left: 7px;
                    @include md-lg {
                      font-size: 10px;
                    }

                    img {
                      max-height: 11px;
                      vertical-align: middle;
                      @include md-lg {
                        max-height: 10px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Step 11 (Tab 4)
    &--11 {
      max-width: 468px;
      margin-right: 89px;
      @include md-lg {
        max-width: 316px;
        margin-right: 78px;
      }
      @include sm {
        margin-right: 71px;
      }
      @include xs {
        max-width: none;
        margin-right: 0;
        margin-bottom: 52px;
      }

      .scheme {
        &__item {
          &--1 {
            &.delimiter {
              &--right {
                &:after {
                  @include md-lg {
                    top: 47%;
                    right: -15px;
                    width: 42px;
                    height: 21px;
                    background: center / cover no-repeat url($__IMG_PATH + 'schema/delim-sm.png');
                  }
                  @include xs {
                    top: 100%;
                    right: auto;
                    left: 21px;
                    transform: translate(0, -19px);
                    width: 19px;
                    height: 47px;
                    background: center / cover no-repeat url($__IMG_PATH + 'schema/delim-vertical.png');
                  }
                }
              }
            }

            .border {
              &--between {
                .item {
                  &:after {
                    top: 20px;
                  }
                }
              }
            }

            .scheme {
              &__inner {
                min-height: 303px;
                padding: 26px 6px 20px 37px;
                @include md-lg {
                  min-height: 279px;
                  padding: 34px 10px 20px 25px;
                }
                @include xs {
                  min-height: 258px;
                  padding: 28px 10px 20px 26px;
                }
              }

              &__title {
                display: flex;
                margin-bottom: 13px;
                @include xs {
                  margin-bottom: 21px;
                }

                img {
                  align-self: flex-start;
                  @include md-lg {
                    max-width: 80px;
                  }
                }

                .scheme__subtitle {
                  width: auto;
                  margin-top: -5px;
                  margin-bottom: 0;
                  margin-left: 13px;
                  letter-spacing: 1.5px;
                  @include md-lg {
                    margin-top: -1px;
                  }
                  @include xs {
                    margin-left: 10px;
                  }
                }
              }

              &__subtitle {
                width: 100%;
                margin-bottom: 11px;
                font-size: 16px;
                letter-spacing: initial;
                @include md-lg {
                  margin-bottom: 18px;
                  font-size: 12px;
                }
                @include xs {
                  margin-bottom: 14px;
                }
              }

              &__items {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-column-gap: 23px;
                @include md-lg {
                  grid-template-columns: repeat(2, auto);
                  grid-column-gap: 3px;
                }
                @include xs {
                  grid-column-gap: 0;
                }

                .item {
                  display: block;

                  &:nth-child(odd) {
                    max-width: 190px;
                    @include md-lg {
                      max-width: 130px;
                    }
                  }

                  .border {
                    &--right {
                      &:after {
                        @include md-lg {
                          content: none;
                        }
                      }
                    }
                  }

                  &:nth-child(even) {
                    .border {
                      &--right {
                        &:after {
                          content: none;
                        }
                      }
                    }
                  }

                  .ul {
                    li {
                      font-weight: 600;
                      padding-right: 9px;
                      @include md-lg {
                        padding-bottom: 9px;
                        padding-left: 19px;
                        font-size: 10px;
                      }
                      @include xs {
                        padding-bottom: 8px;
                      }

                      &:last-child {
                        padding-bottom: 9px;
                      }

                      &:before {
                        @include md-lg {
                          width: 8px;
                          height: 8px;
                        }
                      }

                      a {
                        padding-right: 14px;
                        font-size: 12px;
                        @include md-lg {
                          margin-top: 19px;
                          font-size: 8px;
                        }
                        @include xs {
                          margin-top: 6px;
                        }

                        & + a {
                          @include md-lg {
                            margin-top: 6px;
                          }
                        }

                        &:after {
                          width: 10px;
                          height: 10px;
                          @include md-lg {
                            width: 8px;
                            height: 8px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Step 12 (Tab 4)
    &--12 {
      max-width: 154px;
      @include md-lg {
        margin-bottom: 16px;
      }
      @include sm {
        max-width: 143px;
      }
      @include xs {
        max-width: 272px;
        margin-bottom: 0;
      }

      .scheme {
        &__item {
          &--1 {
            .scheme {
              &__items {
                @include xs {
                  display: grid;
                  grid-template-columns: repeat(auto-fit, minmax(73px, auto));
                  grid-column-gap: 12px;
                  align-items: center;
                }

                .item {
                  & + .item {
                    margin-top: 15px;
                    @include xs {
                      margin-top: 0;
                    }
                  }

                  &__img {
                    width: 100%;
                    max-width: 67px;
                    text-align: right;
                    @include xs {
                      max-width: 30px;
                    }

                    img {
                      @include xs {
                        max-height: 30px;
                      }
                    }
                  }

                  &__text {
                    margin-left: 15px;
                    @include md-lg {
                      font-size: 12px;
                    }
                    @include xs {
                      max-width: 44px;
                      margin-left: 10px;
                      font-size: 9px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // Universal styles
  &__step {
    width: 100%;
  }

  &__item {
    &--border {
      padding: 1px;
      border-radius: 24px;
      background: $border_gradient_primary;
    }

    &--gradient {
      background: $border_gradient_secondary;
    }
  }

  &__inner {
    color: #5C6876;
    border-radius: inherit;

    &--white {
      background: #FFFFFF;
      box-shadow: $primary_shadow;
    }

    &--gradient {
      color: #FFFFFF;
      background: center / cover no-repeat url($__IMG_PATH + 'bg-block.jpg') #8b98f4;
      box-shadow: $steps_shadow_gradient;
    }
  }

  &__title {
    font-weight: 700;

    &--mobile {
      display: none;
      font-size: 18px;
      @include gradient_text($primary_gradient);
      @include xs {
        display: inline-block;
      }
    }
  }

  &__items {
    .item {
      display: flex;
      align-items: center;

      a {
        position: relative;
        display: inline-block;
        padding-right: 20px;
        font-weight: 600;
        color: $color_secondary;

        &:after {
          content: '';
          display: block;
          width: 13px;
          height: 13px;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(0, -50%);
          transition: transform 0.3s;
          background: center / cover no-repeat url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='none'%3E%3Cpath fill='%23CE72CB' d='M6.99967.333496 5.82467 1.5085l4.65003 4.65833H.333008V7.8335H10.4747l-4.65003 4.6583 1.175 1.175 6.66663-6.66664L6.99967.333496Z'/%3E%3C/svg%3E");
        }

        &:hover {
          &:after {
            transform: translate(-3px, -50%);
          }
        }
      }
    }
  }

  // Delimiters
  .delimiter {
    position: relative;

    &:before,
    &:after {
      content: '';
      width: 50px;
      height: 20px;
      position: absolute;
      z-index: 1;
    }

    &--left {
      &:before {
        top: 50%;
        left: -5px;
        transform: translate(-100%, -50%);
        background: center / cover no-repeat url($__IMG_PATH + 'schema/delim.png');
      }
    }

    &--right {
      &:after {
        top: 50%;
        right: -5px;
        transform: translate(100%, -50%);
        background: center / cover no-repeat url($__IMG_PATH + 'schema/delim.png');
      }
    }
  }

  .border {
    position: relative;

    &--top {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        opacity: 0.5;
        box-shadow: $border_shadow;
        background: $border_gradient;
      }
    }

    &--right {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        opacity: 0.5;
        right: 0;
        width: 1px;
        height: 100%;
        box-shadow: $border_shadow;
        background: $border_gradient;
      }
    }

    &--between {
      .item:nth-child(odd) {
        &:after {
          content: '';
          position: absolute;
          top: 0;
          opacity: 0.5;
          right: 0;
          width: 1px;
          height: 100%;
          box-shadow: $border_shadow;
          background: $border_gradient;
        }
      }
    }
  }

  img {
    display: inline-block;
  }

  &__subtitle,
  strong {
    display: inline-block;
    @include gradient_text($primary_gradient);
  }
}
