.footer {
  padding-top: 55px;
  padding-bottom: 47px;
  background: center / cover no-repeat url($__IMG_PATH + 'bg-footer.jpg') #2a40a5;
  @include md-lg {
    padding-top: 60px;
    padding-bottom: 72px;
    background: center / cover no-repeat url($__IMG_PATH + 'bg-footer-tablet.jpg') #2a40a5;
  }
  @include xs {
    padding-top: 27px;
    padding-bottom: 45px;
    background: center / cover no-repeat url($__IMG_PATH + 'bg-footer-mobile.jpg') #2a40a5;
  }

  &__title {
    color: $color_aqua;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.02em;
    margin-bottom: 12px;
  }

  .btn--default {
    @include xs {
      background: #CE72CB;
    }
  }

  &__info {
    p {
      font-size: 8px;
      letter-spacing: 0.02em;
      line-height: 11px;
      color: #F1F4F6;

      &:first-of-type {
        text-align: justify;
        margin-bottom: 8px;
        @include md-lg {
          margin-bottom: 5px;
        }
      }
    }
  }

  &__menu {
    li {
      &:not(:last-child) {
        margin-bottom: 18px;
      }

      a {
        display: inline-block;
        color: #ffffff;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.02em;

        &:hover {
          color: #EA9BE7;
        }
      }
    }
  }

  &__inner {
    position: relative;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(5, auto);
    grid-template-rows: repeat(2, auto);
    grid-column-gap: 15px;
    grid-row-gap: 8px;
    color: #ffffff;
    @include md-lg {
      grid-template-columns: repeat(4, auto);
    }
    @include xs {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(5, auto);
      grid-column-gap: 17px;
      grid-row-gap: 30px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    &--main {
      position: relative;
      max-width: 225px;
      grid-area: 1 / 1 / 3 / 2;
      @include md-lg {
        max-width: 311px;
      }
      @include xs {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        grid-area: 1 / 1 / 2 / 3;
      }

      .footer__list--socials {
        display: none;
        @include md-lg {
          display: block;
          margin-top: 40px;
        }
        @include xs {
          display: none;
        }
      }
    }

    &--products {
      max-width: 325px;
      grid-area: 1 / 2 / 3 / 3;
      @include md-lg {
        max-width: 157px;
        grid-area: 1 / 2 / 2 / 3;
      }
      @include xs {
        grid-area: 2 / 1 / 3 / 2;
      }
    }

    &--solutions {
      max-width: 240px;
      grid-area: 1 / 3 / 3 / 4;
      @include md-lg {
        max-width: 148px;
        grid-area: 1 / 4 / 3 / 5;
      }
      @include xs {
        grid-area: 3 / 1 / 6 / 2;
      }
    }

    &--company {
      max-width: 169px;
      grid-area: 1 / 4 / 2 / 5;
      @include md-lg {
        max-width: 157px;
        grid-area: 2 / 3 / 3 / 4;
      }
      @include xs {
        grid-area: 4 / 2 / 5 / 3;
      }
    }

    &--contact {
      max-width: 130px;
      grid-area: 1 / 5 / 2 / 6;
      @include md-lg {
        max-width: 157px;
        grid-area: 2 / 2 / 3 / 3;
      }
      @include xs {
        grid-area: 3 / 2 / 4 / 3;
      }
    }

    &--documentation {
      max-width: 169px;
      grid-area: 2 / 4 / 3 / 5;
      @include md-lg {
        max-width: 157px;
        grid-area: 1 / 3 / 2 / 4;
      }
      @include xs {
        grid-area: 2 / 2 / 3 / 3;
      }
    }

    &--socials {
      max-width: 130px;
      grid-area: 2 / 5 / 3 / 6;
      @include md-lg {
        display: none;
      }
      @include xs {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 13px;
        grid-area: 5 / 2 / 6 / 3;
      }

      .footer__menu {
        display: flex;
        align-items: center;
        margin: -5px;

        li a {
          padding: 8px;
        }

        li:not(:last-child) {
          //margin-right: 10px;
          margin-bottom: 0;
        }
      }
    }
  }

  &__creator {
    position: absolute;
    bottom: 0;
    left: 0;
    @include md-lg {
      bottom: -34px;
      left: -22px;
    }
    @include xs {
      bottom: -10px;
      right: 0;
      left: auto;
    }

    img {
      @include md-lg {
        width: 39px;
      }
    }
  }

  &__cta {
    margin-top: auto;
    max-width: 180px;
    @include md-lg {
      display: none;
      max-width: 190px;
      margin-top: 35px;
      padding-left: 8px;
    }
    @include xs {
      width: 60%;
      margin-top: 23px;
      padding-left: 0;
      padding-right: 10px;
    }

    &--mobile {
      display: none;
      @include md-lg {
        display: block;
      }
    }

    p {
      margin-bottom: 18px;
      font-size: 14px;
      line-height: 1.4;
      letter-spacing: 0.05em;
      @include md-lg {
        margin-bottom: 20px;
      }
      @include xs {
        margin-bottom: 12px;
      }
    }
  }

  &__brands {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
    min-height: 39px;
    @include md-lg {
      margin-top: 27px;
    }
    @include xs {
      order: 2;
      width: 40%;
      max-width: 100px;
      margin-top: 10px;
    }

    img {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    img:not(:last-child) {
      margin-right: 8px;
      @include xs {
        margin-right: 0;
      }
    }
  }

  &__lang {
    margin-top: 9px;
    @include md-lg {
      position: absolute;
      top: 2px;
      right: 45px;
      margin-top: 0;
    }
    @include sm {
      right: 0;
      top: 4px;
    }

    .dropdown__content {
      background-color: #374D95;

      li.active a {
        color: #ffffff;
      }

      a {
        color: #959BAF;

        &:hover {
          color: #ffffff;
        }
      }

      svg {
        path {
          fill: #ffffff;
        }
      }
    }
  }
}

.logo {
  &--footer {
    margin-bottom: 18px;
    @include md-lg {
      margin-bottom: 13px;
    }

    img {
      width: 130px;
      height: 32px;
    }
  }
}
