.content_item {
  margin-top: 37px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.02em;
  color: #5C6876;
  @include xs {
    margin-top: 30px;
  }

  h3,
  .content {
    width: 100%;
    max-width: 1055px;
    margin-left: auto;
    margin-right: auto;
  }

  &.full_width_image {
    img {
      width: 100%;
    }
  }

  &.two_col_lists {
    ol, ul {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 10px;
      @include sm {
        display: block;
      }
    }
  }

  &.image_left,
  &.image_right {
    .content_wrapper {
      display: flex;
      align-items: flex-start;
      @include sm {
        display: block;
      }
    }

    img {
      max-width: 535px;
      overflow: inherit;
      margin-bottom: 30px;
      @include sm {
        width: 100%;
        max-width: none;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  &.image_left {
    .content_wrapper {
      flex-direction: row;
    }

    img {
      margin-right: 35px;
    }
  }

  &.image_right {
    padding-left: calc((100% - 1055px) / 2);
    @include sm {
      padding-left: 0;
    }

    h3 {
      max-width: none;
    }

    .content_wrapper {
      flex-direction: row-reverse;
    }

    img {
      margin-left: 35px;
      @include sm {
        margin-left: 0;
      }
    }
  }

  h3 {
    font-size: 28px;
    font-weight: 700;
    color: #4996D1;
    margin-bottom: 25px;
  }

  h4 {
    font-size: 24px;
    color: #4996D1;
    margin-bottom: 20px;
  }

  h5 {
    font-size: 20px;
    color: #4996D1;
    margin-bottom: 15px;
  }

  h6 {
    font-size: 18px;
    color: #8592F4;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 25px;
    margin-top: 25px;
    @include xs {
      text-align: justify;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  img {
    margin-bottom: 50px;
    border-radius: 18px;
    min-height: 333px;
    object-fit: cover;
    overflow: hidden;
  }

  p + ul {
    margin-top: 50px;
  }

  ul, ol {
    margin-bottom: 25px;

    li {
      margin-bottom: 35px;
    }
  }

  ol {
    list-style: auto inside;
  }

  ul {
    li {
      position: relative;
      padding-left: 24px;

      &::before {
        content: '';
        position: absolute;
        top: 6px;
        left: 0;
        display: block;
        width: 12px;
        height: 12px;
        background: center/contain no-repeat #4996D1 url($__IMG_PATH + 'icons/ellipse.svg');
        box-shadow: -2px -1px 4px rgba(255, 255, 255, 0.4), 2px 1px 3px rgba(24, 47, 91, 0.2), inset 3px 3px 5px rgba(213, 222, 245, 0.4), inset -4px -3px 4px rgba(79, 103, 120, 0.2);
        border-radius: 50%;
      }
    }
  }
}