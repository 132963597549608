.intro {
  position: relative;
  background: top 0 left 0 / 100% no-repeat url($__IMG_PATH + 'bg-intro.jpg') #8562E6;
  overflow: hidden;
  @include desc {
    background-size: 1920px;
  }
  @include xs {
    margin-bottom: 24px;
    background: top 0 left 0 / cover no-repeat url($__IMG_PATH + 'bg-intro-mobile.jpg') #8562E6;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(0deg, #fff 0%, #fff 4%, rgba(255, 255, 255, 0) 50%);
    @include xs {
      content: none;
    }
  }

  // Fix line in bottom this section
  & + .brands {
    background: #fff;
    position: relative;
    top: -2px;
    padding-top: 2px;
  }

  &__video {
    position: absolute;
    top: -1px;
    width: 100%;
    @include desc {
      width: 1920px;
    }
  }

  &__caption {
    position: relative;
    z-index: 1;
    max-width: 500px;
    margin-top: 235px;
    margin-bottom: 360px;
    @include md-lg {
      margin-top: 200px;
      margin-bottom: 333px;
      margin-left: 12px;
    }
    @include sm {
      margin-top: 206px;
      margin-bottom: 417px;
      margin-left: 40px;
    }
    @include xs {
      max-width: 100%;
      margin-top: 170px;
      margin-bottom: 165px;
      margin-left: 0;
    }

    .btn {
      margin-top: 36px;
      @include xs {
        margin-top: 24px;
      }
    }
  }

  &__title {
    color: #fff;
    font-size: 52px;
    font-weight: 700;
    line-height: 1.1;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    @include md-lg {
      margin-bottom: 6px;
    }
    @include xs {
      font-size: 42px;
    }

    span, strong {
      color: $color_intro_subtitle;
    }
  }

  &__subtitle {
    font-size: 24px;
    font-weight: 600;
    color: $color_intro_subtitle;
    margin-bottom: 16px;
    @include md {
      font-size: 20px;
    }
    @include xs {
      font-size: 19px;
      line-height: 22px;
      margin-bottom: 32px;
    }
  }

  &__text {
    padding-right: 90px;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.03em;
    color: #fff;
    @include xs {
      padding-right: 0;
      font-size: 18px;
      line-height: 25px;
    }
  }

  &__image {
    position: absolute;
    right: -60px;
    bottom: 20px;
    z-index: 1;
    margin-left: 32%;
    @include md-lg {
      right: -65px;
      bottom: 25px;
      margin-left: 40.5%;
    }
    @include sm {
      right: -110px;
      bottom: 20px;
      margin-left: 34.2%;
    }
    @include xs {
      display: none;
    }
  }

  &__phone {
    position: absolute;
    right: 475px;
    bottom: 67px;
    z-index: 2;
    margin-left: 47.7%;
    @include md-lg {
      right: auto;
      bottom: 60px;
    }
    @include xs {
      display: none;
    }
  }
}
