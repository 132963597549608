.heading {
  font-size: 45px;
  font-weight: 700;
  color: $color_primary;
  line-height: 52px;
  letter-spacing: 0.02em;

  @include xs {
    font-size: 34px;
    line-height: 40px;
    background: $gradient_primary;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.title {
  font-size: 18px;
  font-weight: 700;
  color: $color_title;
  line-height: 1.4;
  letter-spacing: 0.03em;

  &--primary {
    color: $color_primary;
  }
}

.subtitle {
  font-size: 18px;
  font-weight: 600;
  color: $color_default;
  line-height: 1.4;
  letter-spacing: 0.02em;

  &--small {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 1.5;
  }

  &--lg {
    font-size: 24px;
  }
}