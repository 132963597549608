.products_page {
  &--party {
    &:before {
      background: linear-gradient(0deg, #FFFFFF 19.38%, rgba(255, 255, 255, 0) 63.26%), top 0 left 0 / 100% no-repeat url($__IMG_PATH + '3rd-party/party-top.jpg');
      @include sm {
        background: top 0 left 0 / 100% no-repeat url($__IMG_PATH + '3rd-party/party-top-mobile.jpg');
      }
    }

    // Fix highlights from tabs__controls
    .title,
    .heading,
    .second_heading,
    .tabs__content {
      position: relative;
      z-index: 5;
    }

    .tabs {
      &__controls {
        list-style: none;
        display: inline-flex;
        margin-left: -16px;
        margin-bottom: 20px;
        border-radius: 16px;
        @include sm {
          margin-left: 0;
        }

        li {
          &:nth-child(1) {
            .tabs__btn {
              box-shadow: -16px -16px 40px rgba(255, 255, 255, 0.8), 0px 4px 64px rgba(18, 61, 101, 0.3), inset -8px -6px 80px rgba(255, 255, 255, 0.18);
              border-radius: 16px 0 0 16px;
            }
          }

          &:nth-child(2) {
            .tabs__btn {
              border-radius: 0 16px 16px 0;
            }
          }

          & + li {
            position: relative;
            z-index: 1;
          }
        }
      }

      &__btn {
        display: block;
        border: none;
        font-family: inherit;
        background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #F2F5FB;
        box-shadow: -16px -16px 40px rgba(255, 255, 255, 0.8), 16px 4px 64px rgba(18, 61, 101, 0.3), inset -8px -6px 80px rgba(255, 255, 255, 0.18);
        padding: 5px 25px;
        color: #BBC0D1;
        font-size: 45px;
        font-weight: 700;
        line-height: 1.17;
        letter-spacing: 0.02em;
        cursor: pointer;
        @include md {
          font-size: 30px;
          padding: 8px 15px;
        }
        @include xxs {
          font-size: 28px;
        }

        &.active {
          color: #fff;
          position: relative;
          z-index: 3;
          background: linear-gradient(90.36deg, #6EACF6 21.97%, #A685F3 76.97%), linear-gradient(0deg, #4996D1, #4996D1);
        }
      }
    }

    .products_page__hero {
      padding-bottom: 66px;
      @include sm {
        padding-bottom: 0;
      }
    }

    .products_page__hero_left {
      @include sm {
        padding-top: 36px;
        padding-bottom: 25px;
      }

      .title,
      .heading {
        margin-bottom: 12px;
        position: relative;
        z-index: 5;
        @include md {
          margin-bottom: 18px;
        }
      }

      .heading {
        @include sm {
          font-size: 32px;
        }
      }

      .subtitle {
        @include md {
          margin-bottom: 0;
        }
      }
    }

    .products_page__hero_grid {
      margin-top: 44px;
      margin-left: 0;
      align-items: flex-start;
      @include md {
        flex-wrap: wrap;
        margin-top: 0;
      }
      @include sm {
        padding-top: 39px;
        margin-left: 10px;
      }
    }

    .products_page__hero_grid_item {
      padding-right: 11px;

      p {
        margin-left: 0;
      }

      //&:nth-child(1) {
      //  width: 25.5%;
      //}
      //
      //&:nth-child(2) {
      //  width: 20.5%;
      //}
      //
      //&:nth-child(3) {
      //  width: 23.5%;
      //}

      &:nth-child(4) {
        //width: 20.5%;
        width: 25%;
        padding-left: 11px;
        @include md {
          padding-left: 0;
        }
      }

      &:nth-child(1),
      &:nth-child(2) {
        @include md {
          margin-bottom: 64px;
        }
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        @include md {
          width: 45%;
          padding-right: 0;
        }
        @include sm {
          width: 100%;
          margin-bottom: 64px;
        }

        img {
          margin-bottom: 18px;
          @include sm {
            margin-bottom: 21px;
          }
        }
      }

      &:last-child {
        @include sm {
          margin-bottom: 8px;
        }
      }
    }

    .img_wrapp_small {
      bottom: 185px;
      left: -16px;
    }

    .products_page__bg {
      background: top -100px left 0 / 100% 1200px no-repeat url($__IMG_PATH + '3rd-party/party-middle.jpg'),
      top 2100px left 0 / 100% 1875px no-repeat url($__IMG_PATH + '3rd-party/party-bottom.jpg');
      @include sm {
        background: none;
      }
    }

    .products_page__related {
      padding: 68px 0 0 5.3%;
      margin-top: 0;
      @include md {
        padding-right: 8px;
      }
      @include sm {
        padding-top: 45px;
      }

      .tabs__controls {
        margin-left: 0;
      }

      .heading {
        margin-bottom: 12px;
        @include sm {
          margin-bottom: 26px;
        }
      }

      .subtitle {
        display: none;
        @include sm {
          display: block;
        }
      }

      .related_lists {
        margin-top: 50px;
        @include sm {
          margin-top: 36px;
        }

        ul {
          min-width: 0;
          width: 100%;
        }

        ul:nth-child(1) {
          max-width: 325px;
        }

        ul:nth-child(2) {
          max-width: 347px;
        }

        ul:nth-child(3) {
          max-width: 260px;
        }

        ul:nth-child(4) {
          max-width: 265px;
        }

        ul:nth-child(1),
        ul:nth-child(2),
        ul:nth-child(3),
        ul:nth-child(4) {
          @include md {
            max-width: 325px;
          }
        }
      }
    }

    .products_page__video {
      @include sm {
        display: none;
      }

      .video {
        .title {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: $color_primary;
          background: linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 49.38%, rgba(179, 102, 215, 0.4) 62.97%, rgba(90, 84, 209, 0.4) 87.16%), #4996D1;
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          text-fill-color: transparent;
          font-weight: 600;
        }
      }
    }

    .products_page__img_text {
      margin-bottom: 86px;
      @include sm {
        margin-bottom: 48px;
      }

      &:not(.products_page__card_experiences) {
        @include sm {
          padding-bottom: 24px;
        }
      }

      .products_page__img_text_wrapp {
        padding-top: 47px;
        padding-bottom: 69px;
        @include sm {
          padding-top: 20px;
          padding-bottom: 23px;
        }
      }

      .title {
        @include sm {
          margin-bottom: 13px;
        }
      }

      .block_text {
        &--ordered {
          .ul {
            order: inherit;
          }
        }

        &--padding {
          padding-left: 30px;
          /*@include sm {
            padding-left: 0;
          }*/
        }

        & + .block_text {
          width: 50%;
          @include xs {
            width: 100%;
          }
        }
      }

      .ul {
        align-items: self-start;

        li {
          max-width: 245px;
          padding-left: 35px;
          margin-bottom: 2px;
        }

        &.ul--small {
          li {
            font-size: 14px;
            padding-left: 30px;
            margin-bottom: 0;

            &:before {
              width: 12px;
              height: 12px;
            }
          }
        }

        &.ul--full {
          li {
            min-width: 100%;
            max-width: none;
          }
        }
      }

      .tabs__controls {
        margin-left: 0;
        margin-bottom: 37px;
        @include sm {
          margin-bottom: 10px;
        }
      }

      .tabs__content {
        &:last-child {
          .item {
            margin-bottom: 0;
          }
        }

        .item {
          margin-bottom: 62px;
          @include sm {
            margin-bottom: 20px;
          }

          a {
            color: #A685F3;
            font-weight: 700;
            background: linear-gradient(90.36deg, #6EACF6 21.97%, #A685F3 76.97%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          .ul {
            margin-left: 0;
          }

          .block_text {
            .number {
              font-weight: 900;
              font-size: 28px;
              line-height: 34px;
              letter-spacing: 0.1em;
              text-transform: uppercase;
              color: #7E9EDE;
              //-webkit-text-stroke: 0.5px #F6F7FB;
              text-shadow: 2px 2px 12px rgba(118, 136, 170, 0.38), -8px -10px 30px rgba(232, 234, 243, 0.05);
            }
          }

          &.text-first {
            .second_heading {
              margin-bottom: 15px;
              letter-spacing: 0.02em;
            }

            .block_text {
              .text {
                line-height: 26.73px;
                letter-spacing: 0;
                margin-bottom: 27px;
                @include sm {
                  margin-bottom: 42px;
                }
              }
            }

            .block_img {
              padding-bottom: 32.8%;
              margin-top: 14px;
              @include sm {
                padding-bottom: 72.4%;
                margin-top: 0;
              }
            }
          }

          &.img-first {
            .second_heading {
              letter-spacing: 0.027em;
              margin-bottom: 8px;
              @include sm {
                margin-bottom: 21px;
              }
            }

            .title {
              margin-left: 5px;
              @include sm {
                order: 3;
                margin-left: 0;
              }
            }

            .text {
              margin-bottom: 14px;
              @include sm {
                margin-bottom: 42px;
              }
            }

            .btn {
              margin-top: 20px;
              @include sm {
                margin-top: 0;
              }
            }

            .block_img {
              padding-bottom: 47.3%;
              @include sm {
                padding-bottom: 72.4%;
                margin-top: 0;
              }
            }
          }
        }
      }
    }

    .products_page__reviews {
      margin-bottom: 70px;
      position: relative;
      @include sm {
        margin-bottom: 47px;
        padding-bottom: 45px;
      }

      &:after {
        @include sm {
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          left: -4.5%;
          right: -4.5%;
          height: 1px;
          background: linear-gradient(0deg, rgba(73, 150, 209, 0), rgba(73, 150, 209, 0)), linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 36.82%, rgba(179, 102, 215, 0.4) 67.04%, rgba(90, 84, 209, 0.4) 87.16%);
        }
      }
    }

    .products_page__steps {
      margin-bottom: 66px;
      padding-left: 10.7%;
      @include xs {
        padding-left: 3.5%;
        padding-bottom: 33px;
      }

      &_bg {
        &:after {
          background: top 0 right 0/100% 100% no-repeat url($__IMG_PATH + "3rd-party/party-bottom.jpg");

        }
      }

      .heading {
        max-width: none;
      }

      .item {
        padding-bottom: 33px;
        @include xs {
          padding-top: 6px;
          padding-bottom: 28px;
        }

        &:nth-child(-n+2) {
          padding-bottom: 68px;
          @include xs {
            padding-bottom: 28px;
          }
        }

        &:last-child {
          padding-bottom: 52px;
        }

        &:after {
          width: 16px;
          height: 16px;
          left: -10px;
        }
      }

      .heading {
        margin-bottom: 31px;
        @include sm {
          color: #559BEB;
          background: none;
          -webkit-background-clip: initial;
          -webkit-text-fill-color: initial;
        }
      }

      .quote {
        position: absolute;
        font-size: 14px;
        line-height: 1.1;
        letter-spacing: 0.02em;
        color: $color_primary;
        background: linear-gradient(90.36deg, #6EACF6 21.97%, #A685F3 76.97%), #4996D1;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
        @include xs {
          display: none;
        }
      }

      .btn {
        margin-left: 25.7%;
        @include xs {
          display: none;
        }
      }
    }

    .products_page__card_experiences {
      .tabs__controls {
        margin-left: 0;
      }

      .ul {
        li {
          margin-bottom: 2px;
        }
      }

      strong {
        color: $color_primary;
        background: linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 49.38%, rgba(179, 102, 215, 0.4) 62.97%, rgba(90, 84, 209, 0.4) 87.16%), #4996D1;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
      }
    }
  }
}
