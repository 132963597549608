.form {
  textarea {
    resize: none;
    min-height: 160px;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: inherit;
    font-size: 14px;
  }

  select,
  textarea::placeholder,
  input::placeholder {
    color: rgba(85, 155, 235, 0.5);
  }

  &__description {
    margin-bottom: 30px;
  }

  &__group {
    margin-bottom: 10px;
  }

  &__control {
    position: relative;
    width: 100%;
    margin-bottom: 20px;

    &--file {
      position: relative;

      &.form__control--error {
        .form__error {
          top: 13px;
        }

        .form__file-name {
          color: #CE72CB;
        }
      }

      .form__file {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 0;
        opacity: 0;
        visibility: hidden;
      }
    }

    &--radio {
      display: inline-flex;
      align-items: center;
      flex-wrap: wrap;
      width: auto;

      .form__error {
        top: 9px;
        left: auto;
        right: -12px;
        z-index: 0;
        @include sm {
          width: 100%;
        }
      }

      .form__tooltip {
        position: absolute;
        left: 20px;
        white-space: nowrap;
        @include sm {
          position: relative;
          left: auto;
        }
      }
    }

    &--error {
      //border-color: #CE72CB !important;

      .form__error {
        display: flex;
      }

      .choices,
      .form__input {
        border-color: #CE72CB !important;
      }
    }

    &--success {
      .choices,
      .form__input {
        border-color: #6F8EDE;
      }
    }
  }

  &__error {
    display: none;
    position: absolute;
    top: 22px;
    z-index: 1;
    transform: translateY(-50%);
    align-items: center;
    @include sm {
      position: static;
      transform: none;
    }

    &--left {
      left: -40px;
      flex-direction: row;

      .form__tooltip {
        margin-left: 20px;
        @include sm {
          margin-left: 0;
        }
      }
    }

    &--right {
      right: -40px;
      flex-direction: row-reverse;
      @include sm {
        flex-direction: row;
      }

      .form__tooltip {
        margin-right: 20px;
        @include sm {
          margin-right: 0;
        }

        &:before {
          left: auto;
          right: -8px;
          transform: translateY(-50%) rotateZ(180deg);
        }
      }
    }

    &--top {
      top: auto;
      transform: none;

      svg {
        vertical-align: middle;
      }

      .form__tooltip {
        position: absolute;
        left: -7px;
        bottom: 100%;
        max-width: 270px;
        width: 270px;
        margin-bottom: 15px;
        @include sm {
          position: relative;
          left: auto;
          bottom: auto;
          max-width: none;
          width: auto;
          margin-bottom: 0;
        }

        &:before {
          left: 15px;
          right: auto;
          top: 100%;
          transform: translateY(-8px) rotateZ(270deg);
        }
      }
    }

    &:hover {
      .form__tooltip {
        display: block;
      }
    }

    svg {
      cursor: pointer;
      @include sm {
        display: none;
      }
    }
  }

  &__tooltip {
    display: none;
    position: relative;
    max-width: 375px;
    padding: 12px 18px;
    background-color: #fff;
    border-radius: 8px;
    color: #959BAF;
    font-size: 15px;
    line-height: 1.26;
    box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.16), 0 0 4px 0 rgba(0, 0, 0, 0.04);
    @include sm {
      display: block;
      max-width: none;
      margin-top: 6px;
      padding: 0;
      background-color: transparent;
      border-radius: 0;
      color: #CE72CB;
      font-size: 10px;
      box-shadow: none;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: -8px;
      top: 50%;
      width: 8px;
      height: 20px;
      transform: translateY(-50%);
      background: center / cover no-repeat url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='20' fill='none'%3E%3Cpath fill='%23fff' fill-rule='evenodd' d='M0 10c0 3 8 5.9999 8 10V0c0 3.9749-8 7-8 10Z' clip-rule='evenodd'/%3E%3C/svg%3E");
      @include sm {
        content: none;
      }
    }

    a {
      color: #559BEB;
    }

    &--purple {
      font-weight: 700;
      color: #CD72CA;
    }

    &--mobile {
      @include sm {
        display: none;
      }
    }
  }

  &__label {
    position: absolute;
    pointer-events: none;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 0;

    &--select {
      /*&::after {
        content: '';
        width: 9px;
        height: 8px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='8' fill='none'%3E%3Cpath fill='%234996D1' d='M5.23799 7.5817C5.08952 7.84314 4.81004 8 4.50436 8s-.58515-.15686-.73363-.4183L.111354 1.26362c-.1484719-.26144-.1484719-.583882 0-.84532C.259825.156862.539293 0 .84497 0h7.31004c.30568 0 .58516.156862.73364.4183.14847.261438.14847.58388 0 .84532L5.23799 7.5817Z'/%3E%3Cpath fill='url(%23a)' fill-opacity='.4' d='M5.23799 7.5817C5.08952 7.84314 4.81004 8 4.50436 8s-.58515-.15686-.73363-.4183L.111354 1.26362c-.1484719-.26144-.1484719-.583882 0-.84532C.259825.156862.539293 0 .84497 0h7.31004c.30568 0 .58516.156862.73364.4183.14847.261438.14847.58388 0 .84532L5.23799 7.5817Z'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='1.9893' x2='7.89294' y1='-.00001296' y2='.0413248' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%236EACF6'/%3E%3Cstop offset='.420549' stop-color='%23A685F3'/%3E%3Cstop offset='.628891' stop-color='%23B366D7'/%3E%3Cstop offset='1' stop-color='%235A54D1'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
        position: absolute;
        right: 11.5px;
        top: 50%;
        margin-top: -2.5px;
        pointer-events: none;
      }*/
    }
  }

  &__select {
    background-repeat: no-repeat;
    background-size: 9px 8px;
    background-position: top 50% right 11.5px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='8' fill='none'%3E%3Cpath fill='%234996D1' d='M5.23799 7.5817C5.08952 7.84314 4.81004 8 4.50436 8s-.58515-.15686-.73363-.4183L.111354 1.26362c-.1484719-.26144-.1484719-.583882 0-.84532C.259825.156862.539293 0 .84497 0h7.31004c.30568 0 .58516.156862.73364.4183.14847.261438.14847.58388 0 .84532L5.23799 7.5817Z'/%3E%3Cpath fill='url(%23a)' fill-opacity='.4' d='M5.23799 7.5817C5.08952 7.84314 4.81004 8 4.50436 8s-.58515-.15686-.73363-.4183L.111354 1.26362c-.1484719-.26144-.1484719-.583882 0-.84532C.259825.156862.539293 0 .84497 0h7.31004c.30568 0 .58516.156862.73364.4183.14847.261438.14847.58388 0 .84532L5.23799 7.5817Z'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='1.9893' x2='7.89294' y1='-.00001296' y2='.0413248' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%236EACF6'/%3E%3Cstop offset='.420549' stop-color='%23A685F3'/%3E%3Cstop offset='.628891' stop-color='%23B366D7'/%3E%3Cstop offset='1' stop-color='%235A54D1'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  }

  &__input {
    width: 100%;
    padding: 13px 17px;
    border: 1px solid #C2D1EF;
    border-radius: 4px;
    background-color: transparent;
    color: #559BEB;
    font-size: 14px;

    &:focus,
    &:active {
      border-color: #6F8EDE;
    }
  }

  &__file {
    &-trigger {
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        margin-right: 6px;
      }
    }

    &-name {
      font-size: 14px;
      color: rgba(85, 155, 235, 0.5);
    }

    &-added {
      color: #559BEB;
    }
  }

  &__phone {
    display: flex;
    align-items: center;
    position: relative;
    padding: 13px 13px 13px 0;
    border-radius: 4px;

    .form__control {
      margin-bottom: 0;
    }

    .form__input {
      padding: 0;
      border: none;
      border-radius: 0;

      &:focus,
      &:active {
        & + .form__label {
          border: 1px solid #6F8EDE;
        }
      }
    }

    .form__label {
      border-radius: inherit;
      border: 1px solid #C2D1EF;
    }

    #countryCode {
      display: none;
    }

    &.form__control--success {
      .form__label {
        border-color: #6F8EDE;
      }
    }

    &.form__control--error {
      .form__label {
        border-color: #CE72CB!important;
      }

      & ~ .form__error {
        display: flex;
      }
    }

    .form__prefix {
      border: none;
      max-width: 57px;
      margin-right: 12px;
      padding-right: 12px;
      padding-left: 13px;
      border-right: 1px solid #C5DDF4;
      color: #559BEB;
      background-color: transparent;
      background-image: none;

      & ~ .choices__list {
        display: block;
        color: #559BEB;
        background: linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 49.38%, rgba(179, 102, 215, 0.4) 62.97%, rgba(90, 84, 209, 0.4) 87.16%), linear-gradient(0deg, #4996D1, #4996D1);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 14px;
        margin-right: 12px;
        padding-right: 12px;
        padding-left: 13px;
        border-right: 1px solid #C5DDF4;

        .choices__item--selectable {
          display: block;
          color: #559BEB;
          background: linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 49.38%, rgba(179, 102, 215, 0.4) 62.97%, rgba(90, 84, 209, 0.4) 87.16%), linear-gradient(0deg, #4996D1, #4996D1);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    .choices {
      padding: 0;
      border: none;
      width: auto;

      &[data-type*=select-one]::after {
        display: none;
      }

      &.is-focused {
        & ~ .form__label {
          border: 1px solid #6F8EDE;
        }
      }

      &.is-flipped {
        .choices__list--dropdown {
          margin-bottom: 30px;
        }
      }

      &__list {
        &--dropdown {
          min-width: 100px;
        }
      }
    }
  }

  &__checkbox {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    max-width: 460px;
    margin-left: 3px;
    padding-bottom: 12px;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    label {
      display: block;
      position: relative;
      padding-left: 43px;
      color: #5C6876;
      font-size: 14px;
      letter-spacing: 0.02em;
      line-height: 1.4;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      a {
        text-decoration: underline;
        color: #559BEB;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .form__checkmark {
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      border: 0.5px solid #959BAF;
      border-radius: 4px;

      &:after {
        content: '';
        position: absolute;
        display: none;
        left: 8px;
        top: -4px;
        width: 7px;
        height: 14px;
        border: solid #559BEB;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    input:checked ~ .form__checkmark {
      &:after {
        display: block;
      }
    }

    &.form__control--error {
      label {
        color: #CE72CB;
      }

      .form__checkmark {
        border-color: #CE72CB;
      }
    }

    .form__error {
      top: 11px;
      @include sm {
        display: none;
      }

      &--left {

      }
    }
  }

  &__radio {
    display: flex;
    align-items: center;
    margin-right: 32px;

    input {
      width: 24px;
      height: 24px;
      margin-right: 12px;
      border-radius: 50%;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-color: transparent;
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"%3E%3Crect width="23" height="23" x=".5" y=".5" fill="%23fff" rx="11.5"/%3E%3Crect width="23" height="23" x=".5" y=".5" stroke="%234996D1" rx="11.5"/%3E%3Crect width="23" height="23" x=".5" y=".5" stroke="url(%23a)" stroke-opacity=".4" rx="11.5"/%3E%3Cdefs%3E%3ClinearGradient id="a" x1="5.3" x2="21" y1="0" y2=".1" gradientUnits="userSpaceOnUse"%3E%3Cstop stop-color="%236EACF6"/%3E%3Cstop offset=".4" stop-color="%23A685F3"/%3E%3Cstop offset=".6" stop-color="%23B366D7"/%3E%3Cstop offset="1" stop-color="%235A54D1"/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E');
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-print-color-adjust: exact;
      color-adjust: exact;

      &:checked {
        background-color: transparent;
        background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"%3E%3Crect width="23" height="23" x=".5" y=".5" fill="%23fff" rx="11.5"/%3E%3Crect width="12" height="12" x="6" y="6" fill="%234996D1" rx="6"/%3E%3Crect width="12" height="12" x="6" y="6" fill="url(%23a)" fill-opacity=".4" rx="6"/%3E%3Crect width="23" height="23" x=".5" y=".5" stroke="%234996D1" rx="11.5"/%3E%3Crect width="23" height="23" x=".5" y=".5" stroke="url(%23b)" stroke-opacity=".4" rx="11.5"/%3E%3Cdefs%3E%3ClinearGradient id="a" x1="8.7" x2="16.5" y1="6" y2="6" gradientUnits="userSpaceOnUse"%3E%3Cstop stop-color="%236EACF6"/%3E%3Cstop offset=".4" stop-color="%23A685F3"/%3E%3Cstop offset=".6" stop-color="%23B366D7"/%3E%3Cstop offset="1" stop-color="%235A54D1"/%3E%3C/linearGradient%3E%3ClinearGradient id="b" x1="5.3" x2="21" y1="0" y2=".1" gradientUnits="userSpaceOnUse"%3E%3Cstop stop-color="%236EACF6"/%3E%3Cstop offset=".4" stop-color="%23A685F3"/%3E%3Cstop offset=".6" stop-color="%23B366D7"/%3E%3Cstop offset="1" stop-color="%235A54D1"/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E');
      }
    }

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 1.29;
      background: linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 49.38%, rgba(179, 102, 215, 0.4) 62.97%, rgba(90, 84, 209, 0.4) 87.16%), #4996D1;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

  }

  .row {
    display: flex;
    align-items: baseline;
    @include xs {
      flex-wrap: wrap;
    }

    > * + * {
      margin-left: 20px;
      @include xs {
        margin-left: 0;
      }
    }

    > .form__control {
      width: 50%;
      @include xs {
        width: 100%;
      }
    }
  }

  &__title {
    margin-bottom: 20px;
    background: linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 49.38%, rgba(179, 102, 215, 0.4) 62.97%, rgba(90, 84, 209, 0.4) 87.16%), linear-gradient(0deg, #4996D1, #4996D1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #559BEB;
    display: inline-block;
  }

  &__submit {
    text-align: right;

    .btn {
      padding: 10px 25px 10px 35px;
      @include xxs {
        width: 100%;
        justify-content: center;
      }

      &::after {
        margin-left: 20px;
      }
    }
  }
}

.choices ~ .form__label--select {
  &::after {
    content: none;
  }
}

.choices {
  position: relative;
  cursor: default;
  width: 100%;
  padding: 13px 17px;
  border: 1px solid #C2D1EF;
  border-radius: 4px;

  &[data-type*=select-one]::after {
    content: '';
    width: 9px;
    height: 8px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='8' fill='none'%3E%3Cpath fill='%234996D1' d='M5.23799 7.5817C5.08952 7.84314 4.81004 8 4.50436 8s-.58515-.15686-.73363-.4183L.111354 1.26362c-.1484719-.26144-.1484719-.583882 0-.84532C.259825.156862.539293 0 .84497 0h7.31004c.30568 0 .58516.156862.73364.4183.14847.261438.14847.58388 0 .84532L5.23799 7.5817Z'/%3E%3Cpath fill='url(%23a)' fill-opacity='.4' d='M5.23799 7.5817C5.08952 7.84314 4.81004 8 4.50436 8s-.58515-.15686-.73363-.4183L.111354 1.26362c-.1484719-.26144-.1484719-.583882 0-.84532C.259825.156862.539293 0 .84497 0h7.31004c.30568 0 .58516.156862.73364.4183.14847.261438.14847.58388 0 .84532L5.23799 7.5817Z'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='1.9893' x2='7.89294' y1='-.00001296' y2='.0413248' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%236EACF6'/%3E%3Cstop offset='.420549' stop-color='%23A685F3'/%3E%3Cstop offset='.628891' stop-color='%23B366D7'/%3E%3Cstop offset='1' stop-color='%235A54D1'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
    position: absolute;
    right: 11.5px;
    top: 50%;
    margin-top: -2.5px;
    pointer-events: none;
  }

  &.is-focused {
    border-color: #6F8EDE;
  }

  &.is-flipped {
    .choices__list--dropdown {
      margin-top: 0;
      margin-bottom: 15px;
      bottom: 100%;
    }
  }

  &[data-type*=select-one].is-open::after {
    transform: rotate(180deg);
  }

  &__list {
    font-size: 14px;

    &--single {
      color: #559BEB;

      .choices__placeholder {
        opacity: 0.5;
        background: linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 49.38%, rgba(179, 102, 215, 0.4) 62.97%, rgba(90, 84, 209, 0.4) 87.16%), linear-gradient(0deg, #4996D1, #4996D1);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: #559BEB;
      }
    }

    &--dropdown {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      z-index: 1;
      left: 0;
      width: 100%;
      margin-top: 30px;
      word-break: break-all;
      will-change: visibility;
      transition: opacity 0.2s;
      overflow: hidden;
      border-radius: 4px;
      background: #FFFFFF;
      box-shadow: 0 0 4px rgba(55, 67, 107, 0.04), 0 4px 32px rgba(38, 49, 84, 0.16);

      &.is-active {
        visibility: visible;
        opacity: 1;
      }

      .choices__list {
        max-height: 205px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        will-change: scroll-position;
      }

      .choices__input,
      .choices__item {
        padding: 10px 15px;
        font-size: 14px;
        line-height: 1.5;
        color: #5C6876;

        &--disabled {
          display: none;
        }

        &.is-highlighted {
          background: linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 36.82%, rgba(179, 102, 215, 0.4) 67.04%, rgba(90, 84, 209, 0.4) 87.16%), linear-gradient(0deg, #4996D1, #4996D1);
          color: #fff;
        }
      }

      .choices__input {
        border-bottom: 0.5px solid #C8E0F1;
        width: 100%;
        padding-left: 30px;

        &::-webkit-search-cancel-button {
          -webkit-appearance: none;
        }
      }

      .choices__input ~ .choices__list {
        &:before {
          content: '';
          position: absolute;
          left: 12px;
          top: 14px;
          width: 10px;
          height: 15px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='15' fill='none'%3E%3Cpath fill='%23C8E0F1' fill-rule='evenodd' d='M7.89255 9.30503h.49826l3.14729 4.00227-.9398 1.1927-3.15355-3.9943v-.63234l-.17029-.22413c-.71901.78447-1.65247 1.25667-2.66791 1.25667-2.26425 0-4.099623-2.32928-4.099623-5.20293C.506927 2.82933 2.3423.5 4.60655.5 6.8708.5 8.70617 2.82933 8.70617 5.70297c0 1.28874-.37212 2.47342-.99022 3.38594l.1766.21612ZM1.7685 5.70299c0 1.99314 1.26773 3.60206 2.8382 3.60206s2.8382-1.60892 2.8382-3.60206-1.26773-3.60206-2.8382-3.60206-2.8382 1.60892-2.8382 3.60206Z' clip-rule='evenodd'/%3E%3C/svg%3E");
        }
      }
    }
  }
}