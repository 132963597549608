.products_page {
  position: relative;
  padding-top: 145px;
  color: $color_default;
  @include xs {
    padding-top: 144px;
  }

  .title {
    display: flex;
    align-items: center;
    position: relative;


    strong {
      width: 100%;
    }

    .number {
      position: absolute;
      top: 50%;
      left: -27px;
      transform: translateY(-50%);
      @include sm {
        position: static;
        margin-right: 13px;
        transform: none;
      }
    }
  }

  .text {
    &--small {
      font-size: 14px;
    }
  }

  .btn {
    padding: 9px 36px;
    display: inline-block;
    text-align: center;
    @include xs {
      width: 100%;
    }
  }

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    max-height: 497px;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, #FFFFFF 19.38%, rgba(255, 255, 255, 0) 63.26%), top 0 left 0 / 100% no-repeat url($__IMG_PATH + 'products/products_top.jpg');
    @include xs {
      background: top 0 left 0 / cover no-repeat url($__IMG_PATH + 'products/products-top-mobile.jpg');
      max-height: 286px;
    }
  }

  &__hero {
    background: #FFFFFF;
    box-shadow: 0 4px 30px rgba(43, 45, 74, 0.17);
    backdrop-filter: blur(150px);
    -webkit-backdrop-filter: blur(150px);
    border-radius: 24px;
    padding: 37px 37px 57px 68px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 4px 0 5px;
    @include xs {
      border-radius: 8px;
      margin: 0;
    }
    @include sm {
      padding: 0;
    }

    &_left {
      width: 45%;
      padding-top: 56px;
      @include sm {
        padding: 42px 13px 29px 25px;
        border-bottom: 1px solid rgba(92, 104, 118, 0.3);
      }

      .title {
        display: block;
        margin-bottom: 8px;
        @include sm {
          display: none
        }
      }

      .heading {
        margin-bottom: 16px;
        @include sm {
          margin-bottom: 12px;
          font-size: 34px;
          line-height: 117.52%;
          br {
            display: none
          }
        }
      }

      .subtitle {
        margin-bottom: 27px;
        max-width: 435px;
        @include sm {
          max-width: 100%;
        }
      }

      @include sm {
        width: 100%;
      }
    }

    &_right {
      position: relative;
      display: flex;
      justify-content: flex-end;
      width: 55%;
      @include sm {
        display: none;
      }
    }

    &_grid {
      margin: 70px -10px 0;
      display: flex;
      //align-items: flex-end;
      justify-content: space-between;
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      letter-spacing: 0.02em;
      //color: #404B56;
      width: 100%;
      @include md {
        padding: 41px 28px 40px;
        flex-wrap: wrap;
        margin: 0;
      }
      @include xs {
        padding: 34px 18px 27px;
      }

      &_item {
        width: 25%;
        padding-left: 11px;
        margin-bottom: 10px;

        img {
          margin-bottom: 14px;
          max-height: 45px;
        }

        &:first-child {
          //width: 28.2%;
        }

        &:nth-child(2) {
          //width: 28.6%;

          /*img {
            margin-bottom: 18px;
          }*/
        }

        &:nth-child(3) {
          //width: 23.2%;

          /*img {
            margin-bottom: 22px;
          }*/
        }

        &:nth-child(4) {
          width: 22%;
          padding-left: 2%;
        }

        /*p {
          margin-left: 11px;
        }*/

        @include md {
          &:first-child, &:nth-child(2),
          &:nth-child(3), &:nth-child(4) {
            width: 49%;
            padding: 0;
          }
        }
        @include xs {
          margin-bottom: 53px;
          &:first-child, &:nth-child(2),
          &:nth-child(3), &:nth-child(4) {
            width: 100%;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .name {
        color: $color_primary;
        background: linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 49.38%, rgba(179, 102, 215, 0.4) 62.97%, rgba(90, 84, 209, 0.4) 87.16%), #4996D1;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .img_wrapp {
    &_big, &_small {
      width: 100%;
      border-radius: 18px;
      //overflow: hidden;
      position: relative;

      img {
        border-radius: 18px;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &_big {
      max-width: 467px;
      padding-bottom: 53.2%;
      background: linear-gradient(244.98deg, #FFFFFF 29.81%, rgba(219, 230, 242, 0) 53.04%, #8F9FAE 100%);
      box-shadow: 16px 4px 64px 0 rgba(18, 61, 101, 0.3), -16px 6px 40px 0 rgba(255, 255, 255, 0.8), -8px -6px 80px 0 rgba(255, 255, 255, 0.18) inset;

      img {
        padding: 1px;
      }
    }

    &_small {
      //max-width: 243px;
      max-width: 42.5%;
      position: absolute;
      //bottom: 230px;
      bottom: -42px;
      left: -11px;
      background: linear-gradient(0deg, #FFFFFF 89.74%, rgba(255, 255, 255, 0) 105.13%);
      box-shadow: 16px 4px 64px 0 rgba(18, 61, 101, 0.3), -15px 10px 40px 0 rgba(255, 255, 255, 0.8), -8px -6px 80px 0 rgba(255, 255, 255, 0.18) inset;
      border-radius: 24px;
      padding: 17.25px 21.83px 18.4px 20.37px;

      &_weight_border {
        position: relative;
        padding-bottom: 73%;
      }

      @include md {
        bottom: -85px;
        left: 30px;
      }
    }
  }

  &__related,
  &__img_text,
  &__steps,
  &__card_experiences {
    position: relative;
    @include xs {
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: -4.5%;
        right: -4.5%;
        bottom: 0;
        background: linear-gradient(0deg, rgba(73, 150, 209, 0), rgba(73, 150, 209, 0)), linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 36.82%, rgba(179, 102, 215, 0.4) 67.04%, rgba(90, 84, 209, 0.4) 87.16%);
        height: 1px;
      }
    }
  }

  &__related {
    margin-top: 68px;
    padding: 0 5.3%;
    margin-bottom: 65px;
    overflow: hidden;
    @include xs {
      margin-top: 32px;
      margin-bottom: 35px;
      padding: 0 8px 30px;
    }

    .title,
    .heading {
      margin-bottom: 6px;
    }

    .title {
      @include sm {
        display: none;
      }
    }

    .heading {
      @include sm {
        margin-bottom: 29px;
      }
    }

    .subtitle {
      br {
        @include sm {
          display: none;
        }
      }
    }

    .related_lists {
      margin-top: 60px;
      @include sm {
        margin-top: 40px;
      }

      ul {
        min-width: 286px;
      }
    }
  }

  &__full {
    width: 100%;
    margin-top: 25px;
  }

  &__video {
    margin-bottom: 48px;
    position: relative;

    .tabs__content {
      mix-blend-mode: multiply;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: -400px;
      left: 0;
      z-index: -2;
      display: block;
      width: 100%;
      min-height: 1369px;
      background: top 0 center /1440px 1369px no-repeat url($__IMG_PATH + 'products/products_center.jpg');
      @include xs {
        display: none;
      }
    }

    &.products_page__video--left {
      .container {
        display: contents;
        @include xs {
          display: block;
        }
      }

      .video_description {
        margin-right: 4.5%;
        @include xs {
          margin-right: 0;
        }
      }

      .video {
        left: -2.5%;
        @include xs {
          left: 0;
        }
      }
    }

    &_wrap {
      display: flex;
      align-items: stretch;
      justify-content: space-between;

      & > div {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        @include xs {
          width: 100%;
        }
      }
    }

    .title {
      margin-bottom: 22px;
    }

    .heading {
      margin-bottom: 26px;
    }

    .subtitle {
      margin-bottom: 18px
    }

    .video {
      padding-bottom: 38%;
      height: 0;
      mix-blend-mode: multiply;
      position: relative;
      @include xs {
        padding-bottom: 100%;
      }

      video, img, iframe {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;
        top: 0;
        left: 0;
      }

      &_description {
        padding-left: 52px;
        margin-left: auto;
        @include xs {
          display: none;
        }

        .btn {
          margin-left: 7px;
        }
      }
    }
  }

  &__img_text {
    margin: 0 5px 95px;
    border-radius: 24px;
    padding: 1px;
    background: linear-gradient(120deg, #FFFFFF 29.81%, rgba(219, 230, 242, 0) 53.04%, rgb(143 159 174 / 60%) 100%);
    @include sm {
      background: none;
      padding: 0;
      margin: 0 3px 46px;
    }
    @include xs {
      padding-bottom: 46px;
    }

    &_wrapp {
      border-radius: 24px;
      background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #F2F5FB;
      padding: 61px 77px 90px 74px;
      box-shadow: -16px -16px 40px rgba(255, 255, 255, 0.8), 16px 4px 64px rgba(18, 61, 101, 0.3), inset -8px -6px 80px rgba(255, 255, 255, 0.18);
      @include sm {
        box-shadow: none;
        padding: 0;
        background: none;
      }
    }

    .title {
      margin-bottom: 10px;
      @include xs {
        margin-bottom: 1px;
      }
    }

    .ul {
      display: flex;
      flex-wrap: wrap;
      margin-left: 5px;
      margin-bottom: 20px;
      justify-content: space-between;
      @include xs {
        margin-bottom: 8px;
      }

      li {
        font-size: 18px;
        line-height: 141%;
        color: $color_default;
        flex: 1;
        max-width: 267px;
        min-width: 220px;
        padding-left: 31px;
        margin-bottom: 16px;
        @include xs {
          max-width: 100%;
          margin-bottom: 4px;
        }

        &:before {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          box-shadow: -2px -1px 4px rgba(255, 255, 255, 0.4), 2px 1px 3px rgba(24, 47, 91, 0.2), inset 3px 3px 5px rgba(213, 222, 245, 0.4), inset -4px -3px 4px rgba(79, 103, 120, 0.2);
        }

        strong {
          color: $color_primary;
          background: linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 49.38%, rgba(179, 102, 215, 0.4) 62.97%, rgba(90, 84, 209, 0.4) 87.16%), #4996D1;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    .item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 65px;
      @include xs {
        margin-bottom: 50px;
      }

      &:last-child {
        margin-bottom: 0
      }

      .block {
        &_img {
          border-radius: 18px;
          height: 0;
          overflow: hidden;
          padding-bottom: 32.5%;
          position: relative;
          margin-top: 9px;
          @include xs {
            order: 2;
            margin-bottom: 24px;
          }

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &_text {
          @include xs {
            display: contents;
          }

          .second_heading {
            @include xs {
              order: 1;
            }
          }

          .text,
          .ul,
          .btn {
            @include xs {
              order: 3;
            }
          }

          br {
            @include xs {
              display: none;
            }
          }

          .text {
            @include xs {
              margin-bottom: 10px;
            }
          }
        }
      }

      &.img-first {
        flex-direction: row-reverse;

        .block_img {
          width: 48.5%;
          padding-bottom: 34.7%;
          @include xs {
            width: 100%;
            padding-bottom: 72%;
          }
        }

        .ul {
          margin-bottom: 32px;
        }

        .block_text {
          width: 48.5%;
          @include xs {
            width: 100%;
          }
        }
      }

      &.text-first {
        flex-direction: row;

        .block_text {
          width: 49%;
          @include xs {
            width: 100%
          }

          .title {
            align-items: flex-start;

            .number {
              transform: none;
              top: -5px;
              @include sm {
                position: absolute;
              }
            }
          }
        }

        &.item-grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: 2%;
          grid-row-gap: 35px;
          @include xs {
            display: block;
          }

          .block_text {
            width: 100%;
            @include xs {
              display: block;
            }

            .title {
              letter-spacing: initial;
            }

            &.products_page__img_text.block_text--padding {
              padding-left: 25px;
            }
          }
        }

        .block_img {
          width: 48.5%;
          padding-bottom: 32.5%;
          @include xs {
            width: 100%;
            padding-bottom: 72%;
          }

          &--mt {
            margin-top: 45px;
            @include xs {
              margin-top: 9px;
            }
          }
        }
      }

      &.img-first,
      &.text-first {
        @include xs {
          flex-direction: column;
        }
      }

      .text {
        font-size: 18px;
        line-height: 148.5%;
        color: $color_default;
        letter-spacing: 0.06px;
        margin-bottom: 20px;

        p {
          margin-bottom: 30px;

          &:last-child {
            margin-bottom: 0
          }
        }
      }

      .second_heading {
        margin-bottom: 10px;
        font-weight: 700;
        color: #559BEB;
        @include xs {
          max-width: 70%;
        }
      }
    }
  }

  .products_page__reviews {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 114px;
    @include xs {
      margin-bottom: 70px;
    }

    .btn {
      margin-top: 50px;
      @include xs {
        margin-top: 30px;
      }
    }
  }

  .products_page__map,
  .products_page__map_constructor {
    position: relative;
    padding-top: 175px;
    padding-bottom: 140px;
    background: top 0 left 0 / 100% no-repeat url($__IMG_PATH + 'products/world-map.jpg');
    @include sm {
      padding-top: 20px;
      padding-bottom: 27px;
      background: none;
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      max-height: none;
      z-index: 0;
      @include sm {
        content: none;
      }
    }

    &:before {
      background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 30%);
    }

    &:after {
      background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 30%);
    }

    .container--lg {
      position: relative;
      z-index: 1;
      padding-left: 2%;
      @include sm {
        padding-left: 0;
      }
    }

    .heading {
      margin-bottom: 12px;

      &:nth-child(2) {
        margin-left: 38px;
      }
    }

    &_card_wrapp {
      padding: 1px;
      border-radius: 24px;
      margin-bottom: 50px;
      background-image: linear-gradient(119deg, #FFFFFF 29.81%, rgba(219, 230, 242, 0) 53.04%, rgba(143, 159, 174, 0.2) 100%);
      box-shadow: -16px -16px 40px rgba(255, 255, 255, 80%), 16px 4px 64px rgba(18, 61, 101, 30%), inset -8px -6px 80px rgba(255, 255, 255, 18%);
      @include sm {
        background: none;
        box-shadow: none;
        margin-bottom: 0;
        padding: 0;
        border-radius: 0;
      }
    }

    &_cards {
      .hero {
        padding-top: 30px;
        @include sm {
          padding-top: 0;
        }

        &:before {
          content: none;
        }
      }

      .hero__header {
        margin-top: 0;
        @include sm {
          background: center / cover no-repeat url($__IMG_PATH + "about-us/hero-mobile-bg.jpg");
          padding: 16px 24px 11px;
          border-radius: 8px;
          box-shadow: 0 4px 30px rgba(43, 45, 74, 0.17);
          overflow: hidden;
        }
      }

      .hero__grid {
        display: grid;
        grid-row-gap: 25px;
        @include sm {
          display: flex;
          grid-row-gap: 0;
        }

        .hero__item {
          @include sm {
            margin-bottom: 11px;
          }
        }

        .hero__item:nth-child(5) {
          grid-area: 1 / 2 / 2 / 3;
        }

        .hero__item:nth-child(6) {
          grid-area: 2 / 2 / 3 / 3;
        }

        .hero__item:nth-child(7) {
          grid-area: 3 / 2 / 4 / 3;
        }

        .hero__wrapper {
          position: relative;
          z-index: 1;
          @include sm {
            filter: none;
            position: static;
          }
        }
      }
    }
  }

  &__steps {
    max-width: 798px;
    padding-left: 18%;
    margin: 0 auto 87px;
    @include xs {
      padding-left: 0;
      padding-bottom: 58px;
      margin: 0 auto 23px;
    }

    &.products_page__steps--horizontal {
      max-width: none;
      margin: 0;
      padding: 75px 0 40px;
      text-align: center;
      border-radius: 24px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.6) 100%);
      @include xs {
        background: none;
        padding-bottom: 58px;
        padding-top: 0;
        margin: 0 auto 23px;
        text-align: left;
        border-radius: 0;
      }

      .btn {
        margin-left: 0;
        margin-top: 50px;
        font-size: 14px;
        @include xs {
          display: none;
        }
      }

      .steps_block {
        display: flex;
        @include xs {
          display: block;
        }

        .item {
          width: 25%;
          padding: 0 10px;
          text-align: center;
          @include xs {
            width: auto;
            padding: 12px 0 46px 26px;
            text-align: left;
          }

          &:before {
            height: 3px;
            width: 100%;
            left: 50%;
            top: 76px;
            @include xs {
              height: 100%;
              top: 15px;
              left: -4px;
              width: 4px;
            }
          }

          &:after {
            width: 15px;
            height: 15px;
            left: 50%;
            top: 70px;
            transform: translateX(-50%);
            @include xs {
              width: 21px;
              height: 21px;
              top: 7px;
              left: -13px;
              transform: none;
            }
          }

          &:last-child {
            padding-bottom: 0;
            @include xs {
              padding-bottom: 23px;
            }
          }

          &:nth-child(1) {
            .text {
              max-width: 250px;
            }
          }

          &:nth-child(2) {
            .text {
              max-width: 300px;
            }
          }

          &:nth-child(3) {
            .text {
              max-width: 250px;
            }
          }

          &:nth-child(4) {
            .text {
              max-width: 240px;
            }
          }

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            .text {
              @include xs {
                max-width: none;
              }
            }
          }

          .number {
            position: static;
            margin-bottom: 66px;
            @include xs {
              position: absolute;
              margin-bottom: 0;
            }
          }

          .title {
            font-size: 18px;
            margin-bottom: 2px;
          }

          .text {
            margin-left: auto;
            margin-right: auto;
            font-size: 14px;
            @include xs {
              margin-left: 0;
            }
          }
        }
      }
    }

    &_bg {
      position: relative;

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        min-width: 1440px;
        min-height: 1726px;
        position: absolute;
        z-index: -1;
        bottom: -87px;
        right: 0;
        background: top 0 right 0/100% 100% no-repeat url($__IMG_PATH + 'products/products_bottom.jpg');
        @include xs {
          display: none;
        }
      }
    }

    .steps_block {
      @include xs {
        padding-left: 44px;
      }
    }

    .heading {
      max-width: 510px;
      margin-bottom: 53px;
      padding-left: 24px;
      @include xs {
        color: #559BEB;
        font-weight: 600;
        margin-bottom: 18px;
        padding-left: 8px;
      }
    }

    .item {
      position: relative;
      padding: 0 0 60px 28px;
      @include xs {
        padding: 12px 0 46px 26px;
      }

      &:last-child {
        padding-bottom: 46px;
        @include xs {
          padding-bottom: 23px;
        }
      }

      &:after, &:before {
        content: '';
        display: block;
        position: absolute;
      }

      &:after {
        background: #4996D1;
        width: 21px;
        height: 21px;
        top: 7px;
        left: -13px;
        border-radius: 50%;
        box-shadow: 2px 1px 3px 0 rgba(24, 47, 91, 0.2), -2px -1px 4px 0 rgba(255, 255, 255, 0.4), -4px -3px 4px 0 rgba(79, 103, 120, 0.2) inset, 3px 3px 5px 0 rgba(213, 222, 245, 0.4) inset, 0 0 4px 0 rgba(49, 46, 87, 0) inset;
      }

      &:before {
        height: 100%;
        top: 15px;
        left: -4px;
        width: 4px;
        background: #4996D1;
        box-shadow: 2px 1px 3px 0 rgba(24, 47, 91, 0.2), -2px -1px 4px 0 rgba(255, 255, 255, 0.4), -4px -3px 4px 0 rgba(79, 103, 120, 0.2) inset, 3px 3px 5px 0 rgba(213, 222, 245, 0.4) inset, 0 0 4px 0 rgba(49, 46, 87, 0) inset;
      }

      &:last-child {
        &:before {
          display: none
        }
      }

      .number {
        position: absolute;
        font-weight: 900;
        font-size: 45px;
        line-height: 54px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        display: block;
        top: -9px;
        left: -60px;
        color: #7E9EDE;
        text-shadow: 2px 2px 12px #7688aa8a, -8px -10px 30px #e8eaf30d, 1px 1px 0 #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
        @include xs {
          top: -7px;
          left: -37px;
          font-size: 28px;
        }
      }

      .title {
        color: #559BEB;
        font-size: 28px;
        letter-spacing: 0.02em;
        line-height: 128.02%;
        margin-bottom: 11px;
        @include xs {
          font-size: 18px;
          margin-bottom: 0;

        }

        br {
          @include xs {
            display: none;
          }
        }
      }

      .text {
        ul {
          li {
            position: relative;
            padding-left: 24px;
            @include md {
              padding-left: 20px;
            }

            &::before {
              content: '';
              position: absolute;
              top: 3px;
              left: 0;
              width: 12px;
              height: 12px;
              background: url($__IMG_PATH + 'icons/ellipse.svg') no-repeat;
              background-size: contain;
              border-radius: 50%;
              box-shadow: 2px 1px 3px 0 rgba(24, 47, 91, 0.2), -2px -1px 4px 0 rgba(255, 255, 255, 0.4), -4px -3px 4px 0 rgba(79, 103, 120, 0.2) inset, 3px 3px 5px 0 rgba(213, 222, 245, 0.4) inset, 0 0 4px 0 rgba(49, 46, 87, 0) inset;
              @include sm {
                top: 2px;
                width: 10px;
                height: 10px;
              }
            }
          }
        }

        strong {
          background: linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 49.38%, rgba(179, 102, 215, 0.4) 62.97%, rgba(90, 84, 209, 0.4) 87.16%), #4996D1;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          color: #559BEB;
        }

        font-size: 18px;
        line-height: 148.5%;
        letter-spacing: 0.03px;
        @include xs {
          font-size: 14px;
          width: 90%;
        }

        br {
          @include xs {
            display: none;
          }
        }
      }

      .ul {
        margin-top: 11px;

        li {
          margin-bottom: 18px;
          padding-left: 30px;

          &:before {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            box-shadow: -2px -1px 4px rgba(255, 255, 255, 0.4), 2px 1px 3px rgba(24, 47, 91, 0.2), inset 3px 3px 5px rgba(213, 222, 245, 0.4), inset -4px -3px 4px rgba(79, 103, 120, 0.2);
          }
        }
      }
    }

    .btn {
      margin-left: 16%;
      @include xs {
        margin: 0;
      }
    }
  }

  &__card_experiences {
    background-image: linear-gradient(119deg, #FFFFFF 29.81%, rgba(219, 230, 242, 0) 53.04%, rgba(143, 159, 174, 0.6) 100%);
    padding: 1px;
    border-radius: 24px;
    margin-bottom: 77px;
    @include xs {
      padding: 0 0 13px 0;
      background: none;
      margin-bottom: 28px;
    }

    &.products_page__img_text {
      margin-left: 0;
      margin-right: 0;
    }

    &_wrapp {
      padding: 56px 55px 40px 66px;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: space-between;
      border-radius: 24px;
      background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 100%), #F2F5FB;
      box-shadow: -16px -16px 40px rgba(255, 255, 255, 0.8), 16px 4px 64px rgba(18, 61, 101, 0.3), inset -8px -6px 80px rgba(255, 255, 255, 0.18);
      @include sm {
        padding: 20px;
      }
      @include xs {
        flex-direction: column;
        background: none;
        box-shadow: none;
        padding: 0;
      }
    }

    .heading {
      margin-bottom: 26px;
      color: #4996D1;
      @include xs {
        font-size: 28px;
        line-height: 117.52%;
        order: 1;
        margin-bottom: 7px;
      }
    }

    .img_wrapp {
      padding-bottom: 43%;
      position: relative;
      margin-top: 105px;
      @include xs {
        margin-top: 0;
        padding-bottom: 68%;
        margin-bottom: 22px;
      }

      &--bottom {
        padding-bottom: 68%;
        margin-bottom: 18px;
      }

      img {
        border-radius: 18px;
        width: 100%;
        max-width: 489px;
        position: absolute;
        top: 0;
        left: 0;
        // height: 100%;
        object-fit: cover;
        @include xs {
          max-width: 100%;
          height: 100%;
        }
      }
    }

    .card_experiences {
      &_left {
        width: 45%;

        p {
          @include xs {
            order: 3;
          }
        }

        .heading {
          position: relative;
          @include xs {
            order: 1;
          }
        }

        .img_wrapp {
          @include xs {
            order: 2;
          }
        }

        &--justify {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          @include md {
            justify-content: flex-start;
          }
        }

        &--noEqual {
          width: 46%;
        }
      }

      &_right {
        margin-top: 4px;
        width: 47%;
        @include xs {
          margin-top: 10px;
        }
      }

      &_left,
      &_right,
      &_left--noEqual {
        @include xs {
          width: 100%;
          display: flex;
          flex-direction: column;
        }
      }
    }

    .second_heading {
      margin-bottom: 9px;
      color: #559BEB;
      font-weight: 700;
      font-size: 28px;
      line-height: 128.02%;
    }

    p {
      margin-bottom: 25px;
      @include xs {
        width: 90%;
      }
    }

    p, ul {
      letter-spacing: 0.02em;
      color: $color_default;
      font-size: 18px;
      line-height: 140%;
    }

    .ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 26px;

      &:last-child {
        margin-bottom: 0;
        @include sm {
          margin-bottom: 26px;
        }
      }

      li {
        width: 48%;
        margin-bottom: 18px;
        padding-left: 30px;
        @include sm {
          width: 100%;
        }

        &:before {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          box-shadow: -2px -1px 4px rgba(255, 255, 255, 0.4), 2px 1px 3px rgba(24, 47, 91, 0.2), inset 3px 3px 5px rgba(213, 222, 245, 0.4), inset -4px -3px 4px rgba(79, 103, 120, 0.2);
        }
      }

      strong {
        display: block;
        color: $color_primary;
        background: linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 49.38%, rgba(179, 102, 215, 0.4) 62.97%, rgba(90, 84, 209, 0.4) 87.16%), #4996D1;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 14px;
      }

      .item,
      .ul {
        width: 48%;
        @include sm {
          width: 100%;
        }
      }

      .ul--full {
        width: 100%;
      }
    }

    .ul--small {
      font-size: 14px;
    }

    .ul--alignTop {
      align-items: flex-start;

      .ul {
        @include sm {
          margin-bottom: 0;
        }
      }
    }

    .ul--full {
      > li {
        width: 100%;

        .ul {
          width: 100%;
        }
      }
    }

    .li--hasChildren {
      &:before {
        content: none;
      }
    }

    .ul--smMarker {
      li:before {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        box-shadow: -2px -1px 4px rgba(255, 255, 255, 0.4), 2px 1px 3px rgba(24, 47, 91, 0.2), inset 3px 3px 5px rgba(213, 222, 245, 0.4), inset -4px -3px 4px rgba(79, 103, 120, 0.2);
      }
    }

    .ul--notEqual {
      li {
        &:nth-child(odd) {
          width: 60.2%;
          @include sm {
            width: 100%;
          }
        }

        &:nth-child(even) {
          width: 38%;
          @include sm {
            width: 100%;
          }
        }
      }
    }
  }

  .second_heading {
    color: #559BEB;
    font-weight: 700;
    font-size: 28px;
    line-height: 128.02%;
  }

  .faq {
    margin-left: 51px;
    margin-right: 14px;
    margin-bottom: 65px;
    @include xs {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 25px;
    }
  }

  &__card_map {
    margin: 0 5px 95px;
    border-radius: 24px;
    padding: 1px;
    background: linear-gradient(120deg, #FFFFFF 29.81%, rgba(219, 230, 242, 0) 53.04%, rgb(143 159 174 / 60%) 100%);
    @include sm {
      background: none;
      padding: 0;
      margin: 0 3px 46px;
    }
    @include xs {
      padding-bottom: 46px;
    }
  }

  &__img_text_slider {
    .reviews_slider .reviews_slider_pagination {
      margin-top: 27px;
      @include xs {
        margin-top: 12px;
        margin-bottom: 36px;
      }
    }

    .products_page__img_text {
      background: none;
      border-radius: 0;
      padding: 0;

      .item.text-first {
        padding: 85px 70px 0;
        //margin-left: 73px;
        @include sm {
          padding: 55px 35px 0;
          margin-left: 0;
        }
        @include xs {
          padding: 0;
          margin-left: 0;
        }

        .block_text {
          width: 43.5%;
          @include xs {
            width: 100%;
          }

          .heading {
            margin-bottom: 12px;
          }

          .text {
            margin-bottom: 23px;
          }

          .second_heading {
            letter-spacing: 0.03em;
            margin-bottom: 21px;
          }

          .ul--full {
            @include xs {
              margin: 0;
              order: 1;
            }

            li {
              max-width: none;
              min-width: 100%;
              margin-bottom: 18px;
            }
          }
        }

        .block_img_wrapp {
          margin-top: -10px;
          width: 49.6%;
          border-radius: 24px;
          padding: 1px;
          background: linear-gradient(120deg, #FFFFFF 29.81%, rgba(219, 230, 242, 0) 53.04%, rgba(143, 159, 174, 0.6) 100%);
          @include sm {
            background: none;
            border-radius: 0;
            padding: 0;
            margin-top: 0;
          }
          @include xs {
            width: 100%;
          }
        }

        .block_img_border {
          padding: 26px;
          background-color: #fff;
          border-radius: 24px;
          box-shadow: -16px -16px 40px rgba(255, 255, 255, 0.8), 16px 4px 64px rgba(18, 61, 101, 0.3), inset -8px -6px 80px rgba(255, 255, 255, 0.18);
          @include sm {
            box-shadow: none;
            border-radius: 0;
            padding: 0;
          }
        }

        .block_img {
          width: 100%;
          margin-top: 0;
          padding-bottom: 87%;
          @include xs {
            padding-bottom: 72%;
          }
        }
      }
    }
  }

  // New Cards
  &__card {
    margin: 0 5px 72px;
    padding: 48px;
    background-color: #FFFFFF;
    box-shadow: 0 4px 30px 0 rgba(43, 45, 74, 0.17);
    height: 100%;
    border-radius: 24px;

    @include xs {
      margin: 0 0 48px;
      padding: 20px;
    }

    & .products_page__card {
      margin: 0;

      &.bg-gradient {
        &-blue {
          background: linear-gradient(328.46deg, #83A0E4 2.26%, #FFFFFF 58.66%);
        }

        &-purple {
          background: linear-gradient(315.6deg, #5A77D1 0.25%, #A2A0E5 22.34%, rgba(252, 211, 248, 0.12) 57.37%);
        }
      }

      .title {
        margin-bottom: 8px;
        font-size: 18px;
        line-height: 1.3333;
        color: #559BEB;
      }

      .ul:last-child {
        li:last-child {
          margin-bottom: 0;
        }
      }
    }

    &_header {
      margin-bottom: 32px;

      .heading {
        margin-bottom: 0;
        font-size: 36px;
        font-weight: 700;
        line-height: 1.175;
        color: #559BEB;

        @include xs {
          font-size: 34px;
        }
      }

      p {
        margin-top: 32px;
      }
    }

    &_inner {
      display: flex;
      flex-wrap: wrap;
      margin-left: -24px;
      margin-right: -24px;
      margin-top: -50px;

      &.gutter-32 {
        margin-left: -16px;
        margin-right: -16px;
        margin-top: -32px;

        & > .products_page__card_col {
          padding-left: 16px;
          padding-right: 16px;
          margin-top: 32px;
        }
      }
    }

    &_col {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 48px;

      @include sm {
        width: 100%;
      }

      &.col-image {
        margin-bottom: -48px;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;

        @include sm {
          display: none;
        }

        .products_page__card_image {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          max-width: calc(100% - 48px);
        }
      }
    }

    &_content {
      font-size: 18px;

      * {
        letter-spacing: 0.02em;
      }

      .ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 48px;

        @include xs {
          display: block;
        }

        &-col-1 {
          display: block;
        }

        &-mark-arrow {
          li {
            padding-left: 36px;

            &:before {
              content: '';
              position: absolute;
              top: 2px;
              left: 2px;
              width: 20px;
              height: 20px;
              border-radius: 5px;
              box-shadow: none;
              background: center / contain no-repeat url($__IMG_PATH + '/icons/list-arrow.svg');
            }
          }
        }
      }

      li {
        padding-left: 31px;
        padding-bottom: 0;

        &:before {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          box-shadow: -2px -1px 4px rgba(255, 255, 255, 0.4),
          2px 1px 3px rgba(24, 47, 91, 0.2),
          inset 3px 3px 5px rgba(213, 222, 245, 0.4),
          inset -4px -3px 4px rgba(79, 103, 120, 0.2);
        }
      }

      li {
        line-height: 1.4;
      }

      p {
        line-height: 1.485;
      }

      .heading,
      li,
      p {
        margin-bottom: 16px;
      }

      &.spacing-32 {
        .heading,
        li,
        p {
          margin-bottom: 32px;
        }
      }

      strong {
        color: #4996D1;
        background: linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 49.38%, rgba(179, 102, 215, 0.4) 62.97%, rgba(90, 84, 209, 0.4) 87.16%), #4996D1;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .heading {
        font-size: 28px;
        font-weight: 700;
        line-height: 1.285;
        color: #8592F4;
        background: none;
        -webkit-background-clip: initial;
        -webkit-text-fill-color: initial;
      }

      .title {
        font-size: 24px;
        background: linear-gradient(90.36deg, #6EACF6 21.97%, #A685F3 76.97%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      & > *:last-child {
        margin-bottom: 0 !important;
      }
    }

    &_btn {
      margin-top: auto;
    }

    .btn {
      margin-top: 30px;
      padding: 8px 16px;
      line-height: 1.4286;
      width: auto;
    }
  }
}
