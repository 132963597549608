.ul {
  li {
    position: relative;
    padding-left: 24px;
    padding-bottom: 13px;

    @include md {
      padding-left: 20px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 3px;
      left: 0;
      width: 12px;
      height: 12px;
      background: url($__IMG_PATH + 'icons/ellipse.svg') no-repeat;
      background-size: contain;
      box-shadow: -2px -1px 4px rgba(255, 255, 255, 0.4), 2px 1px 3px rgba(24, 47, 91, 0.2), inset 3px 3px 5px rgba(213, 222, 245, 0.4), inset -4px -3px 4px rgba(79, 103, 120, 0.2);
      border-radius: 50%;

      @include sm {
        top: 2px;
        width: 10px;
        height: 10px;
      }
    }
  }

  &.ul--unmarked {
    li {
      padding: 0;

      &:before {
        content: none;
      }
    }
  }
}

.related_lists{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  ul{
    display: block;
    width: 25%;
    li{
      font-size: 18px;
      line-height: 128.02%;
      letter-spacing: 0.02em;
      position: relative;
      display: flex;
      align-items: center;
      color: $color_default;
      margin-bottom: 26px;
      &:before{
        content: '';
        display: block;
        background-image: url($__IMG_PATH + 'icons/list-arrow.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        width: 20px;
        min-width: 20px;
        min-height: 20px;
        height: 20px;
        margin-right: 14px;
      }
    }
  }
}
