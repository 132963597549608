.solution_page {
  //background: none;

  &:before {
    background: top 0 left 0 / 100% 500px no-repeat url($__IMG_PATH + 'solution/hero-bg.jpg');
    @include sm {
      background: top 0 left 0 / 100% 300px no-repeat url($__IMG_PATH + 'solution/hero-bg-mobile.jpg');
    }
  }

  &:after {
    display: none;
  }

  .hero_section {
    margin-bottom: 65px;
    @include xs {
      margin-bottom: 27px;
    }

    .hero_top {
      align-items: flex-start;
      padding: 50px 33px 26px 86px;
      @include sm {
        padding: 32px 23px 19px 26px;
      }

      .text {
        padding-top: 24px;
        width: 45%;
        @include sm {
          padding-top: 0;
          width: 100%;
        }

        .title {
          margin-bottom: 5px;
          @include sm {
            display: none;
          }
        }

        .heading {
          margin-bottom: 15px;
        }

        .description {
          font-size: 18px;
          font-weight: 600;
          color: #404B56;
          line-height: 1.4;
          letter-spacing: 0.02em;
        }
      }

      .small_img {
        top: -31px;
        left: 58.5%;
        @include sm {
          left: auto;
          right: 4.5%;
          width: 127px;
        }
        @include xxs {
          max-width: 127px;
          width: 38%;
        }

        .border {
          @include sm {
            padding-bottom: 94.5%;
          }
        }

        &_wrapp {
          @include sm {
            padding: 13px 10px;
          }
        }
      }

      .big_img {
        width: 35.6%;

        .border {
          padding-bottom: 62%;
        }
      }
    }

    .hero_bottom {
      padding: 33px 94px 60px 78px;
      @include sm {
        padding: 1px 11px 33px 17px;
      }
    }

    .hero_list {
      display: flex;
      flex-wrap: wrap;
      //justify-content: space-between;
      letter-spacing: 0.02em;
      margin-left: -10px;
      margin-right: -10px;

      ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 33.3333%;
        @include md {
          width: 100%;
        }
        @include sm {
          padding-left: 10px;
          padding-right: 10px;
        }

        /*&:nth-child(1) {
          @include sm {
            order: 1;
          }
        }

        &:nth-child(3) {
          @include sm {
            order: 2;
          }
        }*/

        .filter_button {
          border: none;
          margin-right: 0;
          margin-top: 19px;
          padding: 10px 32px;
          font-size: 18px;
          @include sm {
            margin-top: 13px;
            font-size: 14px;
          }

          &.active {
            font-weight: 700;
          }
        }
      }
    }
  }

  .cta {
    &_section {
      text-align: center;
      margin-bottom: 55px;
      @include sm {
        margin-bottom: 68px;
        margin-right: 10px;
      }
    }

    &_heading {
      font-size: 36px;
      color: #559BEB;
      letter-spacing: initial;
      line-height: 1.4;
      margin-bottom: 13px;
      @include sm {
        font-size: 18px;
      }
    }

    &_text {
      font-size: 14px;
      color: #404B56;
      margin-bottom: 23px;
      @include sm {
        line-height: 1.1;
        margin-bottom: 24px;
      }

      p + p {
        margin-top: 5px;
      }
    }

    &_btn {
      display: inline-block;
      padding: 10px 40px;
    }
  }

  .content_section {
    margin-bottom: 56px;
    @include sm {
      margin-bottom: 15px;
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}
