.btn {
  padding: 0;
  border: 0;
  background: transparent;
  font-family: inherit;
  letter-spacing: 0.02em;
  position: relative;
  cursor: pointer;
  /*@include xs {
    -webkit-appearance: button;
  }*/

  &--sm {
    font-size: 14px;
    font-weight: 600;
  }

  &--md {
    font-size: 18px;
    font-weight: 400;
  }

  &--default {
    background: $gradient_primary;
    border-radius: 67px;
    color: #fff;
    padding: 9px 25px;

    &Dark {
      background: linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 49.38%, rgba(179, 102, 215, 0.4) 62.97%, rgba(90, 84, 209, 0.4) 87.16%), #4996D1;
    }

    &:hover,
    &:focus {
      text-decoration: none;
      background: $color_secondary;
    }
  }

  &--xs {
    padding: 8px 19px;
    line-height: 1.4 !important;

    &.btn--arrow {
      &:after {
        margin-left: 5px;
      }
    }
  }

  &--arrow {
    display: inline-flex;
    align-items: center;

    &::after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 13px;
      height: 13px;
      background-image: url($__IMG_PATH + 'icons/arrow.svg');
      margin-left: 12px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    &Grad {
      &:after {
        background-image: url($__IMG_PATH + 'icons/arrow-gradient.svg');
        transition: all 0.3s;
      }
    }
  }
}
