.info {
  max-width: 55%;
  @include xs {
    max-width: 85%;
  }

  .title {
    margin-bottom: 18px;

    @include xs {
      margin-bottom: 28px;
    }
  }

  .heading {
    margin-bottom: 18px;

    @include xs {
      margin-bottom: 28px;
    }
  }

  .icon {
    margin-bottom: 5px;
  }

  .btn {
    margin-top: 18px;

    @include xs {
      margin-top: 28px;
    }
  }

  &--full {
    max-width: 100%;
  }

  .row {
    display: flex;
    column-gap: 5%;

    @include xxs {
      flex-wrap: wrap;
    }

    .col:not(:last-child) {
      @include xxs {
        margin-bottom: 15px;
      }
    }
  }
}
