$phone_frame_background: #E5E7EC;
$phone_screen_background: #fff;

.iphone {
  width: 270px;
  height: 555px;
  border-radius: 40px;
  position: relative;
  user-select: none;
  box-shadow: 16px 4px 64px rgba(18, 61, 101, 0.3), inset -8px -6px 80px rgba(255, 255, 255, 0.18);
  @include md-lg {
    width: 245px;
    height: 500px;
  }
  @include md {
    width: 215px;
    height: 450px;
  }

  &__border {
    &-outside,
    &-inside {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: inherit;
      border: 1px solid rgba(165, 161, 169, 0.3);
    }

    &-outside {
      height: 100%;
      background-color: $phone_screen_background;
      box-shadow: inset 0 0 4px 1px $phone_screen_background;
    }

    &-inside {
      position: relative;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      background-color: $phone_frame_background;
      overflow: hidden;

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        border: 1px solid #fff;
        pointer-events: none;
        z-index: 100;
      }
    }
  }

  &__buttons {
    & > * {
      position: absolute;
      left: -2px;
      width: 2px;
      z-index: 0;
      background: linear-gradient(270deg, transparent, #fff);
      border-radius: 20px 10px 10px 20px;
    }
  }

  &__switch {
    top: 107px;
    height: 18px;
  }

  &__vol {
    height: 40px;

    &-up {
      top: 144px;
    }

    &-down {
      top: 190px;
    }
  }

  &__lock {
    top: 160px;
    right: -3px;
    left: auto;
    height: 50px;
    border-radius: 10px 20px 20px 10px;
  }

  &__bang {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    padding-top: 4px;
    width: 85px;
    height: 25px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    background-color: inherit;
    z-index: 100; // Comment this for hide

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 7px solid transparent;
    }

    &:before {
      left: -17px;
      border-right-color: $phone_frame_background;
      transform: rotate(-50deg);
    }

    &:after {
      right: -17px;
      border-left-color: $phone_frame_background;
      transform: rotate(50deg);
    }
  }

  &__speaker {
    width: 29px;
    height: 5px;
    border-radius: 4px;
    box-shadow: inset .5px -.5px 1px 0 #fff, inset -.5px .5px 1px 0 #b7b7ba;
  }

  &__camara {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 14px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    box-shadow: inset 0.5px -0.5px 1px 0 #fff, inset -0.5px 0.5px 1px 0 #b7b7ba;

    &:before {
      content: '';
      position: absolute;
      border-radius: inherit;
    }

    &:before {
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      box-shadow: .5px -.5px 1px 0 #fff, -.5px .5px 1px 0 #b7b7ba;
    }
  }

  &__screen {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 14px);
    height: calc(100% - 14px);
    padding: 24px 20px;
    border-radius: 35px;
    background-color: $phone_screen_background;
    background-blend-mode: soft-light;
    overflow: hidden;
    @include md-lg {
      padding: 24px 12px;
    }
  }
}

// Fake Form
//====================================
.fake-form {
  max-width: 205px;
  align-self: baseline;

  .mt-32 {
    margin-top: 32px;
    @include md-lg {
      margin-top: 16px;
    }
  }

  .mb {
    &-4 {
      margin-bottom: 4px !important;
      @include md-lg {
        margin-bottom: 2px !important;
      }
    }

    &-8 {
      margin-bottom: 8px;
      @include md-lg {
        margin-bottom: 4px;
      }
    }

    &-16 {
      margin-bottom: 16px;
      @include md-lg {
        margin-bottom: 8px;
      }
    }

    &-32 {
      margin-bottom: 32px;
      @include md-lg {
        margin-bottom: 16px;
      }
    }
  }

  strong {
    font-weight: 700;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--center {
      justify-content: center;
    }

    &--stretch {
      align-items: stretch;
    }

    &--gap {
      margin-left: -4px;
      margin-right: -4px;

      & > * {
        flex: 1 1 100%;
        padding-left: 4px;
        padding-right: 4px;
      }

      &2 {
        margin-left: -12px;
        margin-right: -12px;

        & > * {
          padding-left: 12px;
          padding-right: 12px;
        }
      }
    }
  }

  &__header,
  &__info,
  &__card,
  &__control {
    text-align: center;
  }

  &__surtitle,
  &__title,
  &__text,
  &__subtitle,
  &__link {
    color: #5B6775;
  }

  &__surtitle {
    font-size: 20px;
    @include md-lg {
      font-size: 16px;
    }
  }

  &__title {
    font-size: 24px;
    @include md-lg {
      font-size: 20px;
    }
  }

  &__subtitle {
    font-size: 12px;
  }

  &__text {
    font-size: 14px;

    &--light {
      font-weight: 300;
      color: #5C6876;
    }
  }

  &__link {
    font-size: 16px;
  }

  &__body {
    position: relative;
  }

  &__control {
    height: 100%;
    flex-direction: column;
    padding: 6px;
    font-size: 10px;
    color: #5C6876;
    opacity: .8;
    border: 1px solid #ADB3BA;
    border-radius: 10px;
    @include md {
      padding: 3px;
    }

    &.active {
      color: #3A9CF2;
      border-color: #3A9CF2;
      opacity: 1;
    }
  }

  &__card {
    padding: 20px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    @include md-lg {
      padding: 10px;
    }
  }

  &__logo {
    margin-left: auto;
    margin-right: auto;
  }

  &__label {
    font-size: 12px;
    color: #333333;
    text-transform: capitalize;
  }

  &__input {
    padding: 8px 10px;
    width: 100%;
    font-weight: 700;
    font-size: 12px;
    color: #5B6775;
    border: 1px solid #ADB3BA;
    border-radius: 6px;
    background: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    transition: transform 0.1s;
    @include md {
      padding: 4px 10px;
    }

    &::placeholder {
      font-weight: 400;
      font-size: 12px;
      color: #ADB3BA;
    }
  }

  &__btn {
    padding: 11px 10px 10px;
    font-size: 16px;
    color: #fff;
    border-radius: 5px;
    background-color: #3A9CF2;
    transition: transform 0.1s;
    @include md {
      padding: 6px;
    }
  }

  &--scaled {
    transition: transform 0.1s;
    transform: scale(1.1);
  }

  &__step {
    background-color: $phone_screen_background;
    &--hidden {
      display: none;
    }
  }
}