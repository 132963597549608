.testimonials {
  padding-top: 50px;
  padding-bottom: 10px;

  @include sm {
    padding: 20px 33px 10px;
  }

  .info {
    margin-bottom: 28px;
    @include sm {
      max-width: none;
    }
    @include xs {
      margin-bottom: 5px;
    }

    .title {
      margin-bottom: 8px;
    }

    .heading {
      margin-bottom: 7px;
    }

    .subtitle {
      letter-spacing: initial;
    }

    .btn {
      margin-top: 31px;
      padding-left: 35px;
      padding-right: 35px;
    }
  }

  &__item {
    position: relative;
    padding-left: 215px;
    padding-right: 110px;
    color: $color_default;
    @include md-lg {
      padding-left: 150px;
      padding-right: 150px;
    }
    @include sm {
      padding-left: 50px;
      padding-right: 30px;
    }
    @include xs {
      padding: 0;
    }
  }

  &__quotes {
    position: absolute;
    display: block;
    @include xs {
      position: static;
      max-width: 60px;
    }

    &.quotes--open {
      top: -10px;
      left: 88px;
      @include md-lg {
        left: 32px;
      }
      @include sm {
        left: -78px;
      }
    }

    &.quotes--close {
      right: 5px;
      bottom: 20px;
      @include md-lg {
        right: 60px;
      }
      @include sm {
        right: -76px;
      }
      @include xs {
        float: right;
      }
    }
  }

  &__text {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 25px;
    letter-spacing: 0.02em;
    line-height: 1.4;

    &,
    p {
      @include xs {
        display: block;
        margin-bottom: 8px;
        font-size: 18px;
        color: $color_primary;
        background: linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(90, 84, 209, 0.4) 87.16%), linear-gradient(0deg, #4996D1, #4996D1);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  &__author {
    font-size: 18px;
    font-weight: 600;
  }

  .purple--text {
    font-weight: 700;
    color: $color_secondary;
    @include xs {
      display: block;
    }
  }
}
