.tags {
  margin-top: 20px;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.02em;

  &_title {
    line-height: 1.4;
    color: #8592F4;
  }

  &_group {
    margin-top: 16px;
    @include sm {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &_item {
    display: inline-block;
    padding: 9px 25px;
    margin-right: 17px;
    margin-bottom: 13px;
    color: #559BEB;
    border: 1px solid #559BEB;
    border-radius: 67px;
    @include sm {
      padding-right: 36px;
      padding-left: 36px;
      margin-bottom: 18px;
    }
  }
}
