.accordion {
  &__item {
    &:last-child {
      .accordion__button {
        @include xs {
          &:after {
            display: none;
          }
        }
      }
    }

    &--active {
      .accordion__button {
        svg path:nth-child(2) {
          transform: rotate(45deg);
        }
      }
    }
  }

  &__heading {
    @include xs {
      margin-bottom: 18px;
    }
  }

  &__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: none;
    border-bottom: 1px solid rgba(102, 114, 126, 0.15);
    padding: 36px 36px 36px 0;
    background-color: transparent;
    color: #959BAF;
    font-size: 26px;
    letter-spacing: 0.02em;
    text-align: left;
    cursor: pointer;
    font-family: inherit;
    line-height: 135%;
    font-weight: 600;
    @include xs {
      font-size: 18px;
      font-weight: 500;
      padding: 30px 0 33px;
      border-bottom: none;
      position: relative;
    }

    &:after {
      @include xs {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: -4.5%;
        right: -4.5%;
        height: 1px;
        background: linear-gradient(0deg, rgba(73, 150, 209, 0), rgba(73, 150, 209, 0)), linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 36.82%, rgba(179, 102, 215, 0.4) 67.04%, rgba(90, 84, 209, 0.4) 87.16%);
      }
    }

    svg {
      min-width: 30px;
      min-height: 30px;
    }

    svg path:nth-child(2) {
      transform-origin: center;
      transition: transform 0.2s;
    }
  }

  &__collapse {
    height: 0;
    transition: .5s ease;
    overflow: hidden;
  }

  &__body {
    padding: 20px;
    font-size: 18px;
  }

  // Accordion Large cards
  &--lg {
    margin: 0 4px 0 5px;
    @include sm {
      margin: 0;
    }

    .accordion {
      &__item {
        border-radius: 24px;
        box-shadow: -16px -16px 40px rgba(255, 255, 255, 0.8), 16px 4px 64px rgba(18, 61, 101, 0.3), inset -8px -6px 80px rgba(255, 255, 255, 0.18);
        @include sm {
          border-radius: 8px;
        }

        & + .accordion__item {
          margin-top: 66px;
        }

        &--active {
          .accordion__button {
            &--closed {
              @include sm {
                display: none !important;
              }
            }

            &--opened {
              @include sm {
                display: block !important;
              }

              &:after {
                transform: rotate(-90deg);
              }
            }

            &:before {
              background: center/contain no-repeat url($__IMG_PATH + 'solutions/minus-icon.svg');
            }
          }
        }
      }

      &__border {
        padding: 1px;
        border-radius: inherit;
        background: linear-gradient(155deg, rgba(255, 255, 255, 0.6) 30%, rgba(219, 230, 242, 0) 53%, rgba(143, 159, 174, 0.6) 100%);
      }

      &__inner {
        position: relative;
        padding: 58px 75px;
        border-radius: inherit;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%), rgba(242, 245, 251, 1);
        @include sm {
          padding: 125px 15px 82px 25px;
        }
      }

      &__heading {
        display: flex;
        align-items: center;

        .info {
          max-width: 540px;
          width: 100%;

          .title {
            margin-bottom: 15px;
            font-weight: 700;
            font-size: 28px;
            line-height: 1.28;
            letter-spacing: 0.02em;
            color: #4996D1;
            @include sm {
              margin-bottom: 8px;
            }
          }

          .text {
            font-weight: 600;
            font-size: 18px;
            line-height: 1.485;
            color: #5C6876;
            @include sm {
              margin-bottom: 22px;
            }
          }

          .row {
            display: grid;
            grid-template-columns: repeat(2, auto);
            column-gap: 0;
            margin-left: -17px;
            margin-right: -17px;
            @include sm {
              flex-wrap: nowrap;
              margin-left: -7px;
              margin-right: -7px;
            }

            .item {
              display: flex;
              align-items: center;
              padding: 17px;
              font-weight: 600;
              font-size: 18px;
              line-height: 1.175;
              letter-spacing: 0.03em;
              color: #5C6876;
              @include sm {
                padding: 7px;
                font-size: 14px;
              }

              &:nth-child(1) {
                img {
                  @include sm {
                    max-width: 15px;
                  }
                }
              }

              &:nth-child(2) {
                img {
                  @include sm {
                    max-width: 35px;
                  }
                }
              }

              img {
                margin-right: 10px;
              }
            }
          }
        }

        .image {
          margin: auto;
          @include sm {
            position: absolute;
            top: -40px;
            left: 0;
            border-radius: 24px;
            box-shadow: 16px 4px 64px 0 #123D654D, -15px 10px 40px 0px #FFFFFFCC, -8px -6px 80px 0px #FFFFFF2E inset;
          }

          &_border {
            @include sm {
              padding: 1px;
              border-radius: inherit;
              background: linear-gradient(155deg, #FFFFFF 30%, rgba(219, 230, 242, 0) 53%, #8F9FAE 100%);
            }
          }

          &_inner {
            @include sm {
              padding: 14px;
              border-radius: inherit;
              background: linear-gradient(0deg, #FFFFFF 90%, rgba(255, 255, 255, 0) 105%);
            }
          }

          &_wrapper {
            max-width: 200px;
            @include sm {
              display: flex;
              align-items: center;
              justify-content: center;
              max-width: none;
              width: 120px;
              height: 120px;
              padding: 12px;
              border-radius: 14px;
              background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #F2F5FB;
            }
          }
        }
      }

      &__button {
        position: absolute;
        top: 60px;
        right: 56px;
        display: block;
        width: auto;
        border: none;
        padding: 0;
        background-color: transparent;
        cursor: pointer;
        font-family: inherit;
        color: #F3F5F7;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.4;
        text-align: center;
        letter-spacing: 0.02em;
        @include sm {
          top: auto;
          right: auto;
          left: 19px;
          bottom: 26px;
        }

        &:before {
          content: '';
          order: 1;
          display: block;
          width: 30px;
          height: 30px;
          background: center/contain no-repeat url($__IMG_PATH + 'solutions/plus-icon.svg');
          transition: background 0.3s;
          @include sm {
            content: none;
          }
        }

        &:after {
          @include sm {
            content: none;
          }
        }

        &--closed,
        &--opened {
          display: none !important;
          @extend .btn, .btn--default, .btn--arrow;
          @include sm {
            padding-left: 25px !important;
            padding-right: 25px !important;
          }
        }

        &--closed {
          @include sm {
            display: block !important;
          }
        }

        &--opened {
          @include sm {
            display: none !important;
          }
        }
      }

      &__body {
        padding: 23px 0;
        font-size: 18px;
        @include sm {
          padding: 5px 0;
        }

        .items {
          .item {
            position: relative;
            padding: 60px 21px;
            @include sm {
              padding: 25px 0;
            }

            &:last-child {
              padding-bottom: 0;
            }

            &:after {
              content: '';
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              height: 1px;
              background: linear-gradient(0deg, rgba(73, 150, 209, 0), rgba(73, 150, 209, 0)), linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 36.82%, rgba(179, 102, 215, 0.4) 67.04%, rgba(90, 84, 209, 0.4) 87.16%);
              @include sm {
                content: none;
              }
            }

            &_inner {
              display: flex;
              align-items: flex-start;
              @include sm {
                display: block;
              }

              .title {
                max-width: 163px;
                width: 100%;
                margin-bottom: 30px;
                margin-right: 52px;
                @include md-lg {
                  margin-right: 30px;
                }
                @include sm {
                  max-width: none;
                  margin-right: 0;
                  margin-bottom: 30px;
                  padding-bottom: 30px;
                }

                &:after {
                  @include sm {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 1px;
                    background: linear-gradient(0deg, rgba(73, 150, 209, 0), rgba(73, 150, 209, 0)), linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 36.82%, rgba(179, 102, 215, 0.4) 67.04%, rgba(90, 84, 209, 0.4) 87.16%);
                  }
                }
              }

              .ul {
                display: grid;
                grid-template-columns: repeat(2, minmax(280px, 337px));
                grid-row-gap: 25px;
                grid-column-gap: 100px;
                @include md-lg {
                  grid-column-gap: 30px;
                }
                @include sm {
                  display: block;
                }

                &--unmarked {
                  li {
                    padding-left: 0 !important;
                  }
                }

                li {
                  padding-bottom: 0;
                  padding-left: 35px;
                  font-weight: 600;
                  font-size: 18px;
                  //line-height: 1.175;
                  line-height: 24px;
                  letter-spacing: 0.03em;
                  color: #5C6876;
                  @include sm {
                    padding-bottom: 27px;
                  }

                  &:before {
                    top: 0;
                    width: 24px;
                    height: 24px;
                    background: center / contain no-repeat url($__IMG_PATH + 'payments-methods/tick-square.svg');
                    box-shadow: none;
                  }

                  a {
                    display: inline-block;
                    padding: 9px 36px;
                    border-radius: 67px;
                    background: #C8E0F1;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 1.28;
                    letter-spacing: 0.02em;
                    color: #559BEB;
                  }

                  span {
                    display: inline-block;
                    color: #4996D1;
                    background: linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 49.38%, rgba(179, 102, 215, 0.4) 62.97%, rgba(90, 84, 209, 0.4) 87.16%), #4996D1;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
