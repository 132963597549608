.docs_page {
  scroll-behavior: smooth;
  font-size: 18px;
  line-height: 140%;
  font-weight: 600;
  color: $color_default;
  letter-spacing: 0.02em;
  position: relative;
  padding-bottom: 135px;
  //overflow: hidden;
  @include sm {
    overflow: hidden;
    margin-top: 67px;
    font-size: 14px;
    line-height: 140%;
    font-weight: 600;
    padding-left: 7px;
    padding-bottom: 25px;
  }

  &:after, &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
  }

  &:before {
    top: 0;
    z-index: -1;
    max-height: 425px;
    min-height: 425px;
    min-width: 1440px;
    background: top 0 left 0 / 100% no-repeat url($__IMG_PATH + 'docs/top_docs.jpg');
    @include sm {
      max-height: 219px;
      min-height: auto;
      min-width: auto;
      background: top 0 left 0 / cover no-repeat url($__IMG_PATH + 'docs/top_docs-2.jpg');
    }
  }

  &:after {
    bottom: 0;
    z-index: -2;
    background: bottom 0 left 0 / 100% 1200px no-repeat url($__IMG_PATH + 'docs/bottom_docs.jpg');
    @include sm {
      background: bottom 0 left 0 / cover no-repeat url($__IMG_PATH + 'docs/bottom_docs.jpg');
    }
  }

  & > .container {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    width: 100%;
  }

  &__content {
    width: 80%;
    max-width: 1074px;
    padding-top: 52px;
    @include sm {
      width: 100%;
      padding-top: 42px;
    }
  }

  &__section {
    position: relative;
    padding-bottom: 36px;
    padding-top: 38px;
    @include sm {
      padding-bottom: 11px;
      padding-top: 34px;
    }

    &::after {
      content: '';
      background: linear-gradient(0deg, rgba(73, 150, 209, 0), rgba(73, 150, 209, 0)), linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 36.82%, rgba(179, 102, 215, 0.4) 67.04%, rgba(90, 84, 209, 0.4) 87.16%);
      display: block;
      position: absolute;
      bottom: 0;
      left: -22px;
      width: calc(100% + 22px);
      height: 1px;
      @include md {
        width: calc(100% + 40px);
        left: -23px;
      }
    }

    &:last-child {
      padding-bottom: 0;

      &:after {
        display: none
      }
    }

    a {
      color: $color_primary
    }

    p {
      margin-bottom: 25px;
      width: 100%;
      max-width: 798px;
    }

    ul {
      max-width: 798px;
    }

    .sub_title {
      font-size: 28px;
      line-height: 33px;
      color: $color_primary;
      margin-bottom: 36px;
      @include sm {
        margin-bottom: 21px;
      }
    }
  }

  &__list {
    &_block {
      &.col {
        &_1, &_2 {
          li {
            padding-bottom: 13px;
            @include md {
              padding-bottom: 16px;
            }
          }
        }

        &_1 {
          .docs_page__list_item {
            width: 100%;
            max-width: 768px;
          }
        }

        &_2 {
          display: flex;
          align-items: flex-start;
          gap: 0 21px;
          margin-top: 60px;
          @include md {
            flex-wrap: wrap;
          }
          @include sm {
            margin-top: 20px;
          }

          .docs_page__list_item {
            width: 49%;
            max-width: 471px;
            @include md {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .sidebar {
    flex: 1;
    padding-top: 127px;
    max-width: 236px;
    @include md {
      padding-left: 30px;
    }
    @include sm {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      opacity: 0;
      pointer-events: none;
      transform: translateY(-10px);
      max-width: 100%;
      padding-top: 0;
      padding-left: 0;
      background-color: rgba(255, 255, 255, .9);
    }

    &.active {
      opacity: 1;
      pointer-events: auto;
      transform: none;
      z-index: 200;
    }

    &_list {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      padding-top: 50px;
      @include sm {
        position: static;
        display: block;
        height: auto;
        border-radius: 18px;
        background: #fff;
        padding: 54px 0 25px;
        margin: 30px auto;
        width: 91%;
        box-shadow: 0 4px 30px rgba(43, 45, 74, 0.17);
      }

      ul {
        position: relative;
        @include sm {
          padding: 0 10px 23px;
          margin-bottom: 40px;
          &:before {
            content: "";
            position: absolute;
            width: 100%;
            left: 0;
            background: linear-gradient(0deg, rgba(73, 150, 209, 0), rgba(73, 150, 209, 0)), linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 36.82%, rgba(179, 102, 215, 0.4) 67.04%, rgba(90, 84, 209, 0.4) 87.16%);
            height: 1px;
            top: 100%;
          }
          &:last-child {
            margin-bottom: 0;

            &:before {
              display: none
            }
          }
        }
      }
    }

    li {
      display: block;
      padding: 8.9px 20px;
      border-radius: 8px;
      background: none;

      &.active {
        background: #C8E0F1;
      }
    }

    a {
      display: block;
      @include sm {
        color: $color_primary;
        background: linear-gradient(90.36deg, #6EACF6 21.97%, #A685F3 76.97%), #4996D1;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &:hover {
        color: $color_primary;
        @include sm {
          -webkit-text-fill-color: #CE72CB;
        }
      }


    }
  }

  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .sidebar {
      @include sm {
        background-color: rgba(255, 255, 255, .9);
        backdrop-filter: blur(150px);
        -webkit-backdrop-filter: blur(150px);
      }
    }
  }

  .info_block {
    margin-bottom: 40px;
    @include sm {
      background: #fff;
      border-radius: 8px;
      padding: 33px 10px 24px 26px;
      box-shadow: 0 4px 30px rgba(43, 45, 74, 0.17);
      //backdrop-filter: blur(150px);
      //-webkit-backdrop-filter: blur(150px);
      //margin-left: -7px;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 0;
      font-size: 18px;
      line-height: 140%;
    }
  }

  .title {
    margin-bottom: 54px;
    display: block;
    color: $color_primary;
    @include sm {
      display: none;
    }
  }

  .heading {
    margin-bottom: 24px;
    @include sm {
      font-size: 45px;
      line-height: 117.52%;
      margin-bottom: 11px;
      background: none;
      -webkit-text-fill-color: inherit;
    }
  }

  .ul {
    li {
      padding-left: 34px;
      @include sm {
        padding-left: 40px;
      }

      &:before {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        box-shadow: -2px -1px 4px rgba(255, 255, 255, 0.4), 2px 1px 3px rgba(24, 47, 91, 0.2), inset 3px 3px 5px rgba(213, 222, 245, 0.4), inset -4px -3px 4px rgba(79, 103, 120, 0.2);
      }
    }
  }

  // Docs Page (Integration)
  &.docs_page--integration {
    padding-top: 130px;
    padding-bottom: 100px;
    @include sm {
      padding-top: 99px;
      padding-bottom: 68px;
      overflow: visible;
    }

    &:after {
      content: none;
    }

    &:before {
      @include sm {
        max-height: 199px;
        background: top 0 left 0 / 100% 199px no-repeat url($__IMG_PATH + 'docs/top_docs-3.jpg');
      }
    }

    & > .container {
      display: block;
      max-width: 1270px;
      padding-right: 55px;
      @include sm {
        padding-right: 15px;
      }
    }

    .docs_page {
      &__content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 62px 95px;
        padding-top: 0;
        width: 100%;
        max-width: none;
        @include sm {
          grid-template-columns: repeat(1, 1fr);
          grid-gap: 62px 0;
        }
      }

      &__item {
        .title {
          margin-bottom: 10px;
          font-size: 28px;
          line-height: 1.1752;
          letter-spacing: 0.02em;
          font-weight: 700;
          color: #559BEB;
          @include sm {
            display: block;
            font-size: 24px;
          }
        }

        .description {
          margin-bottom: 20px;
          font-size: 18px;
          line-height: 1.4;
          font-weight: 600;
          letter-spacing: 0.02em;
          color: #5C6876;
        }

        .btn {
          padding: 8px 25px 9px 39px;
          line-height: 1.4;

          &:after {
            margin-left: 16px;
          }
        }
      }
    }
  }
}
