.media_page.media_page--post {
  &:before {
    background: top 0 left 0/100% no-repeat url($__IMG_PATH + 'post/post-top.jpg');
  }

  .media_blocks:before {
    background: top 0 left 0/contain no-repeat url($__IMG_PATH + 'post/post-bottom.jpg');
  }

  .media_blocks a.active {
    color: #559BEB;
    background: #C8E0F1;
    border-color: #C8E0F1;
    font-weight: 700;
    padding: 10px 32px;
    border-radius: 67px;
  }

  .categories {
    @include xs{
      display: none;
    }
    .category {
      display: inline-block;

      a {
        display: inline-block;
        padding: 9px 29px;
        margin-bottom: 20px;
        margin-right: 20px;
        border-radius: 67px;
        font-size: 14px;
        color: #fff;
        background-color: #959BAF;
      }

      &--news-media {
        a {
          background-color: #559BEB;
        }
      }

      &--blog-media {
        a {
          background-color: #5C6876;
        }
      }

      &--portfolio-media {
        a {
          background-color: #CE72CB;
        }
      }
    }
  }

  .hero_section {
    margin-bottom: 57px;
    @include sm {
      margin-bottom: 30px;
    }
    @include xs{
      margin-bottom: 40px;
    }
  }

  .hero_top {
    align-items: flex-start;

    @include xs{
      padding: 36px 26px 19px;
    }
    .text {
      width: 40%;
      padding-top: 45px;
      @include sm {
        width: 100%;
        padding-top: 0;
      }

      .heading{
        @include xs{
          font-size: 38px;
          line-height: 47px;
        }
      }

      .subtitle {
        margin-bottom: 53px;
        @include sm {
          margin-bottom: 20px;
        }
        @include xs{
          margin-bottom: 33px;
        }
      }

      .date {
        font-size: 14px;
        letter-spacing: 0.02em;
        color: #8592F4;
      }
    }

    .big_img {
      width: 60%;

      .border {
        padding-bottom: 40.5%;
      }
    }
  }

  .hero_bottom {
    padding-bottom: 50px;
    @include sm {
      padding-bottom: 20px;
    }
    @include xs{
      padding: 29px 15px 42px 27px;
    }
    .hero_grid {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      @include sm {
        flex-wrap: wrap;
      }

      &_item {
        + .hero_grid_item {
          @include sm {
            margin-top: 14px;
          }
          @include xs{
            margin-top: 25px;
          }
        }

        &:nth-child(1) {
          width: 29%;
          @include sm {
            width: 100%;
          }
        }

        &:nth-child(2) {
          width: 19%;
          @include sm {
            width: 100%;
          }
        }

        &:nth-child(3) {
          width: 25%;
          @include sm {
            width: 100%;
          }
        }

        &:nth-child(4) {
          width: 25%;
          @include sm {
            width: 100%;
          }
        }

        .title {
          margin-bottom: 14px;
        }

        ul li {
          display: flex;
          align-items: center;
          letter-spacing: 0.02em;
          font-size: 18px;

          &:before {
            content: '';
            display: inline-block;
            margin-right: 12px;
            width: 20px;
            height: 20px;
            background: center / contain no-repeat url($__IMG_PATH + 'post/hero-list.svg');
          }

          + li {
            margin-top: 14px;
          }
        }
      }
    }
  }

  .reviews_slider {
    &_delimiter {
      position: relative;

      &:after {
        content: '';
        display: block;
        margin-top: 100px;
        width: 100%;
        height: 1px;
        background: linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 36.82%, rgba(179, 102, 215, 0.4) 67.04%, rgba(90, 84, 209, 0.4) 87.16%), linear-gradient(0deg, rgba(73, 150, 209, 0), rgba(73, 150, 209, 0));
        @include sm {
          margin-top: 50px;
        }
        @include xs{
          margin-top: 72px;
        }
      }

      &--nomargin {
        &:after {
          margin-top: 0;
        }
      }

      &--modmargin {
        &:after {
          margin-top: 54px;
          margin-bottom: 48px;
        }
      }

      &:last-child {
        &:after {
          content: none;
        }
      }
    }

    .reviews_slider__reviewer {
      @include sm {
        padding-left: 4.5%;
        padding-right: 4.5%;
      }
    }

    &__text {
      @include sm {
        margin-left: auto;
        margin-right: auto;
      }

      ul {
        display: flex;
        padding-top: 22px;
        @include sm {
          flex-wrap: wrap;
          padding-top: 10px;
        }

        li {
          position: relative;
          padding-left: 38px;
          padding-right: 19px;

          & + li {
            margin-left: 23px;
            @include sm {
              margin-left: 0;
              margin-top: 15px;
            }
          }

          &:before {
            content: '';
            position: absolute;
            top: 3px;
            left: 0;
            width: 15px;
            height: 15px;
            background: center / contain no-repeat url($__IMG_PATH + '/icons/ellipse.svg');
          }
        }
      }

      .heading {
        font-size: 28px;
        margin-bottom: 13px;
      }

      .hero_top {
        justify-content: center;
        padding: 0;
        margin-top: 80px;
        margin-bottom: 45px;
        border-bottom: none;
        @include sm {
          margin-top: 40px;
          margin-bottom: 30px;
        }

        .big_img {
          width: auto;
          @include sm {
            display: block;
          }

          .border {
            height: auto;
            padding-bottom: 0;
            border-radius: inherit;
            @include xs{
              padding-bottom: 0;
            }

            img {
              position: static;
              width: auto;
              height: auto;
              object-fit: contain;
              border-radius: inherit;
            }
          }
        }
      }

      &:before,
      &:after {
        width: 100px;
        height: 80px;
        @include sm {
          width: auto;
          height: auto;
        }
      }

      &--noqote {
        padding-top: 0;
        padding-left: 166px;
        padding-right: 166px;
        text-align: left;
        font-size: 18px;
        color: #5C6876;
        letter-spacing: 0.025em;
        @include sm {
          padding-left: 0;
          padding-right: 0;
          width: 93%;
        }
        @include xs{
          display: flex;
          flex-direction: column;
          margin-top: 59px;
        }
        .heading{
          @include xs{
            order: 1;
            line-height: 36px;
            margin-bottom: 7px;
          }
        }
        .hero_top{
          @include xs{
            margin-top: 0;
            order: 2;
            margin-bottom: 10px;
          }
        }
        ul{
          @include xs{
            order: 3;
          }
        }
        p {
          margin-bottom: 24px;
          @include xs{
            order: 3;
          }
        }

        &:before,
        &:after {
          content: none;
        }
      }

      strong {
        color: $color_primary;
        background: linear-gradient(90.36deg, #6EACF6 21.97%, #A685F3 76.97%), #4996D1;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .pro_tips_block,
  .key_takeaways_block,
  .table_block,
  .related_readings_block {
    position: relative;
    width: 100%;
    max-width: 1175px;
    margin: 32px auto;
    padding-left: 166px;
    padding-right: 166px;
    @include sm {
      padding-left: 0;
      padding-right: 0;
      width: 93%;
    }

    & * {
      letter-spacing: 0.02em;
    }
  }

  .key_takeaways_block,
  .pro_tips_block {
    &_inner {
      padding: 48px;
      border-radius: 24px;
      background: linear-gradient(90.36deg, #6EACF6 21.97%, #A685F3 76.97%);
      box-shadow: 0 4px 30px 0 rgba(43, 45, 74, 0.17);
      @include sm {
        padding: 20px;
      }
    }

    &_header {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }

    &_icon {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
      max-width: 36px;
      max-height: 36px;
    }

    &_title {
      font-size: 28px;
      line-height: 36px;
      font-weight: 700;
      color: #9FF8FF;
      @include sm {
        font-size: 24px;
        line-height: 28px;
      }
    }

    &_description {
      color: #FFFFFF;
      font-size: 18px;
      line-height: 24px;

      & * + * {
        margin-top: 16px;
      }

      li {
        position: relative;
        padding-left: 31px;

        &:before {
          content: '';
          position: absolute;
          top: 4px;
          left: 0;
          width: 15px;
          height: 15px;
          box-shadow: 2px 1px 3px 0 rgba(24, 47, 91, 0.2),
          -2px -1px 4px 0px rgba(255, 255, 255, 0.4),
          -4px -3px 4px 0px rgba(79, 103, 120, 0.2) inset,
          3px 3px 5px 0px rgba(213, 222, 245, 0.4) inset,
          0px 0px 4px 0px rgba(49, 46, 87, 0) inset;
          border-radius: 50%;
          background: center / contain no-repeat url($__IMG_PATH + '/icons/ellipse.svg');
        }
      }
    }
  }

  .related_readings_block {
    &_inner,
    &_card_inner {
      padding: 48px;
      border-radius: 24px;
      background: #FFFFFF;
      @include sm {
        padding: 20px;
      }
    }

    &_inner {
      box-shadow: 0 4px 30px 0 rgba(43, 45, 74, 0.17);
    }

    &_card_inner {
      display: flex;
      align-items: flex-start;
      box-shadow: 16px 4px 64px 0 rgba(18, 61, 101, 0.3),
      -16px -16px 40px 0px rgba(255, 255, 255, 0.8),
      -8px -6px 80px 0px rgba(255, 255, 255, 0.18) inset;
      @include xs {
        display: block;
      }
    }

    &_header {
      margin-bottom: 48px;
      @include sm {
        margin-bottom: 20px;
      }
    }

    &_card_header {
      margin-bottom: 16px;
    }

    &_title,
    &_card_title {
      margin-bottom: 16px;
      font-size: 28px;
      line-height: 36px;
      font-weight: 700;
      color: #8592F4;
    }

    &_description,
    &_card_description {
      font-size: 18px;
      line-height: 24px;
      color: #5C6876;

      & * + * {
        margin-top: 16px;
      }

      li {
        position: relative;
        padding-left: 36px;

        &:before {
          content: '';
          position: absolute;
          top: 2px;
          left: 2px;
          width: 20px;
          height: 20px;
          background: center / contain no-repeat url($__IMG_PATH + '/icons/list-arrow.svg');
        }
      }

      .title {
        color: #559BEB;
        font-size: 18px;
      }

      a:not(.btn) {
        color: #559BEB;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &_card_social {
      display: flex;
      flex-wrap: wrap;
      margin-top: 16px;
      margin-left: -8px;
      margin-right: -8px;

      & > * {
        margin-top: 8px;
        padding-left: 8px;
        padding-right: 8px;
      }
    }

    &_card_image_wrapper {
      flex: 1 0 200px;
      margin-right: 48px;
      margin-bottom: 20px;
      @include sm {
        margin-right: 24px;
        flex-basis: 160px;
      }
      @include xs {
        margin-right: 0;
      }
    }

    &_card_image {
      position: relative;
      padding-bottom: 100%;
      border-radius: 16px;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .table_block {
    @include xs {
      margin-top: -32px;
      width: 100%;
    }

    &_grid {
      overflow: visible;
      @include xs {
        overflow: hidden;
      }

      &_inner {
        position: relative;
        display: grid;
        border-radius: 24px;
        overflow: hidden;
        box-shadow: 0 4px 30px 0 rgba(43, 45, 74, 0.17);
        @include xs {
          padding: 32px 3.5%;
          display: flex;
          overflow: visible;
          border-radius: 0;
          box-shadow: none;
        }
      }

      &_col {
        position: static;
        @include xs {
          position: relative;
          border-radius: 24px;
          overflow: hidden;
          box-shadow: 0 4px 30px 0 rgba(43, 45, 74, 0.17);
        }
      }

      &_heading,
      &_content {
        padding: 20px;
      }

      &_heading {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.01em;
        font-weight: 700;
        color: #FFFFFF;
        background-color: #6EACF6;
        height: var(--grid-table-header-height, auto);

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: var(--grid-table-header-height, auto);
          background: linear-gradient(90.36deg, #6EACF6 21.97%, #A685F3 76.97%);
        }

        span {
          position: relative;
          z-index: 1;
        }
      }

      &_content {
        font-size: 14px;
        line-height: 1.2;
        font-weight: 400;
        box-shadow: 0 0.5px 0.25px 0 #959BAF;
        height: var(--grid-table-content-height, auto);
        &:last-child {
          box-shadow: none;
        }
      }
    }

    &_slider {
      &_navigation {
        display: none;
        margin-left: auto;
        margin-right: auto;
        width: calc(93% + 16px);
        justify-content: space-between;
        align-items: center;
        @include xs {
          display: flex;
        }
      }

      &_button {
        margin: 0;
        padding: 0;
        border-radius: 0;
        border: none;
        background: none;
        appearance: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-family: $ff;
        color: #4996D1;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.4;

        svg {
          flex-shrink: 0;
          margin-left: 8px;
          margin-right: 8px;
        }
      }

      &_pagination {
        display: flex;
        align-items: center;

        .swiper-pagination-bullet {
          position: relative;
          margin: 4px;
          padding: 2px;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: 1px solid #4996D1;
          cursor: pointer;

          &-active {
            &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              display: block;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: #4996D1;
            }
          }
        }
      }
    }
  }
}
