.payments_methods {
  &_bg {
    background: top 0 center / cover no-repeat url($__IMG_PATH + 'payments-methods/bg-bottom.jpg');
  }


  .products_page__hero {
    align-items: center;
    padding-bottom: 37px;
    @include sm {
      padding-bottom: 0;
    }

    .products_page__hero_left {
      width: 53%;
      @include sm {
        width: 100%;
        padding: 36px 13px 29px 25px;
        border-bottom: none;
      }

      .heading {
        margin-bottom: 43px;
        @include sm {
          margin-bottom: 10px;
          max-width: 200px;
          background: transparent;
          -webkit-background-clip: initial;
          -webkit-text-fill-color: initial;
        }
      }

      .subtitle {
        max-width: none;
        @include sm {
          margin-bottom: 17px;
        }
      }
    }

    .products_page__hero_right {
      width: 40%;

      .img_wrapp_big {
        padding-bottom: 62%;
      }
    }
  }

  .accordion--lg {
    padding-top: 62px;
    padding-bottom: 92px;
  }
}