.news {
  padding-bottom: 70px;
  background: top center / cover no-repeat url('/assets/website/img/bg-news.jpg');
  @include xs {
    padding-bottom: 40px;
    background: bottom center / cover no-repeat url('/assets/website/img/bg-news-mobile.jpg');
  }

  .brands {
    padding: 9px 0;
    margin-bottom: 40px;
    @include md-lg {
      margin-bottom: 0;
    }
    @include xs {
      padding: 30px 0;
      margin-bottom: 30px;
    }

    &__list img {
      @include xs {
        width: 26%;
      }
      @include xxs {
        width: 45%;
      }
    }

    /*.container {
      overflow-x: clip;
    }

    .swiper {
      overflow: initial;
    }*/

    &__item {
      flex-direction: column;
      transition: 0.3s;

      &:hover {
        transform: translateY(-50%);
        @include xs {
          transform: none;
        }

        img {
          opacity: 0;
          @include xs {
            opacity: 1;
          }
        }

        .brands__hover {
          opacity: 1;
        }
      }
    }

    &__hover {
      margin-top: 30px;
      opacity: 0;
      transition: 0.5s;
      @include sm {
        margin-top: 15px;
      }
      @include xs {
        display: none;
      }
    }

    &__name {
      color: #CE72CB;
      font-size: 18px;
      font-weight: 600;
    }

    &__activity {
      color: #404b56;
      font-size: 18px;
    }

    &__list {
      img {
        max-width: 150px;
        width: 100%;
        transition: 0.3s;
      }

      img:not(:last-child) {
        margin-right: 0;
      }

      &.swiper-wrapper {
        flex-wrap: nowrap;
        gap: 0;
        padding: 0;
        @include xs {
          flex-wrap: wrap;
          gap: 12px 10%;
        }

        .second_heading {
          color: #4996D1;
          font-weight: 600;
          font-size: 28px;
          line-height: 128.02%;
        }

        .swiper-slide {
          display: flex;
          align-items: center;
          @include xs {
            width: 26%;
            justify-content: center;
          }

          img {
            @include xs {
              width: 100%;
            }
          }
        }
      }
    }
  }


  .posts {
    .info {
      @include sm {
        padding-left: 32px;
      }
      @include xs {
        padding-left: 0;
      }
    }

    &__inner {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 30px 0;
      @include xs {
        flex-direction: column;
      }
    }
  }

  .post:nth-child(1) {
    width:  calc(71% - 20px);
    max-width: 778px;
    margin-right: 20px;
    margin-bottom: 20px;
    @include xs {
      max-width: none;
      width: 100%;
      margin-right: 0;
    }
    @include xs {
      align-items: flex-start;
      flex-direction: column;
    }

    .post__preview {
      flex: 1 1 55%;
      margin-right: 10%;
      @include xs {
        margin-right: 0;
        margin-bottom: 15px;
      }
    }

    .post__content {
      flex: 1 2 45%;
    }

    .post__excerpt {
      display: block;
    }
  }

  .post:nth-child(2) {
    width:  calc(29% - 20px);
    max-width: 300px;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    @include xs {
      max-width: none;
      width: 100%;
    }

    .post__preview {
      margin-bottom: 15px;
      flex: none;

      .responsive--ratio {
        padding-bottom: 56.25%;
      }
    }

    .post__content {
      flex: none;
    }
  }

  .post:nth-child(3) {
    width:  calc(49% - 20px);
    max-width: 539px;
    margin-right: 20px;
    @include xs {
      max-width: none;
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  .post:nth-child(4) {
    width: 49%;
    max-width: 539px;
    @include xs {
      max-width: none;
      width: 100%;
    }
  }

  .post:nth-child(3),
  .post:nth-child(4) {
    .post__preview {
      .responsive--ratio {
        @include xs {
          padding-bottom: 100%;
        }
      }
    }
  }

  .post {
    display: flex;
    align-items: center;
    padding: 20px;
    font-size: 14px;
    border-radius: 24px;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(242, 245, 251, 0.6);
    box-shadow: -16px -16px 40px rgba(255, 255, 255, 0.8), 16px 4px 64px rgba(18, 61, 101, 0.3), inset -8px -6px 80px rgba(255, 255, 255, 0.18);
    @include xs {
      padding: 16px;
    }

    &__preview {
      width: 100%;
      flex: 1 1 40%;
      border-radius: 18px;
      overflow: hidden;
      margin-right: 5%;

      .responsive--ratio {
        position: relative;
        display: block;
        padding-bottom: 75%;

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &__content {
      flex: 1 2 60%;

      & > *:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    &__date {
      display: block;
      color: #8592F4;
      font-weight: 600;
    }

    &__heading {
      font-size: 18px;
      color: #4996D1;

      a {
        color: inherit;
      }
    }

    &__excerpt {
      display: none;
      line-height: 1.5;
      color: #5C6876;
    }

    &__btn {
      display: inline-block;
      vertical-align: middle;
      font-weight: 600;
      color: #A685F3;
      background: linear-gradient(90.36deg, #6EACF6 21.97%, #A685F3 76.97%), linear-gradient(0deg, #4996D1, #4996D1);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      &:after {
        vertical-align: middle;
      }

      &:hover {
        &:after {
          margin-left: 5px;
        }
      }
    }
  }
}


