.header {
  @include sm {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 150;
    transition: background-color 0.2s;
  }

  & .menu {
    width: 100%;
    display: flex;
    align-items: center;
    @include sm {
      display: block;
      overflow: hidden;
      padding: 30px 0;
    }

    & > ul {
      @include sm {
        overflow: auto;
        height: 100%;
        position: relative;
        margin: 0 auto;

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
          margin-top: 20px;
          margin-bottom: 20px;
        }

        &::-webkit-scrollbar-thumb {
          background: #CE72CB;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: darken(#CE72CB, 10%);
        }
      }

      li {
        @include sm {
          height: auto;
        }
      }
    }
  }

  .burger,
  .logo {
    @include sm {
      filter: brightness(10);
      transition: filter 0.2s;
    }
  }

  &--fixed {
    @include sm {
      background-color: #fff;
    }

    .burger,
    .logo {
      @include sm {
        filter: brightness(1);
        transition: filter 0.2s;
      }
    }
  }

  &--white {
    background-color: #fff;

    .burger,
    .logo {
      @include sm {
        filter: brightness(1);
      }
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    @include sm {
      padding-top: 30px;
      padding-bottom: 19px;
    }
  }

  &__action {
    margin-right: auto;
    white-space: nowrap;
    @include sm {
      display: none
    }
  }

  &.scrolled {
    background: #fff;
  }

  &_docs {
    display: flex;
    align-items: center;
    box-shadow: 16px 4px 64px 0 rgba(18, 61, 101, 0.3), -16px -16px 40px 0 rgba(255, 255, 255, 0.8), -8px -6px 80px 0 rgba(255, 255, 255, 0.18) inset;

    @include sm {
      height: 67px;
      box-shadow: none;
    }

    .header__inner {
      display: flex;
      justify-content: space-between;
      padding: 42px 0 36px 8px;
      @include sm {
        padding: 20px 0 29px;
      }
    }

    .container {
      @include sm {
        max-width: 87%;
      }
    }

    .menu {
      margin: 0;
      padding: 0;
      height: 0;
      width: 0;
      @include sm {
        width: 100%;
        height: 100%;
      }
    }

    .logo {
      display: flex;
      align-items: flex-end;
      @include sm {
        display: none;
      }

      img {
        width: 178px;
      }
    }

    .burger {
      margin-left: 0;
    }

    &--integration {
      .container {
        width: 98%;
        @include sm {
          padding: 29px 15px 27px;
          max-width: none;
          width: 100%;
        }
      }

      .logo {
        @include sm {
          display: block;
        }

        img {
          @include sm {
            width: auto;
          }
        }
      }
    }
  }
}

.search_btn {
  border: none;
  background: none;
  cursor: pointer;
}

.burger {
  display: none;
  margin-left: auto;
  @include sm {
    display: block;
  }
}

.lang {
  @include sm {
    display: none;
  }
}

.close {
  padding: 5px;
  position: absolute;
  top: 50px;
  right: calc(9% - 5px);
  display: none;
  @include sm {
    display: block;
  }
}

.logo {
  img {
    width: 133px;
    height: 28px;

    @include sm {
      width: 98px;
      height: 19px;
    }
  }
}

.user {
  margin-left: 18px;
  display: flex;
  align-items: center;

  @include sm {
    display: none;
  }
}
