.solutions_page {
  margin-bottom: 0;
  padding-bottom: 62px;
  overflow: hidden;
  @include xs {
    padding-bottom: 25px;
    padding-top: 154px;
  }

  &:before {
    height: 425px;
    background: linear-gradient(0deg, #FFFFFF 13.55%, rgba(255, 255, 255, 0) 81.02%), top 0 right 0/100% 425px no-repeat url($__IMG_PATH + 'solutions/hero-bg.jpg');
    @include xs {
      height: 280px;
      background: top 0 right 0/100% 280px no-repeat url($__IMG_PATH + 'solutions/hero-bg-mob.jpg');
    }
  }

  &:after {
    top: 425px;
    bottom: 0;
    max-height: none;
    background:  linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 30%), bottom 0 left 0/100% 1200px no-repeat url($__IMG_PATH + 'solutions/bg.jpg');
    @include xs {
      top: 280px;
      background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 20%), bottom 0 left 0/100% 1200px no-repeat url($__IMG_PATH + 'solutions/bg-mob.jpg');
    }
  }

  .hero_section {
    margin-bottom: 5px;

    .hero_top {
      position: relative;
      align-items: flex-end;
      padding-top: 30px;

      .text {
        width: 55%;
        @include xs {
          width: 80%;
        }

        .heading {
          -webkit-text-fill-color: #4996D1;
          background: none;

          br {
            display: none;
            @include sm {
              display: block;
            }
          }
        }

        .subtitle {
          font-size: 18px;
          margin-bottom: 6px;
          @include sm {
            margin-bottom: 0;
          }
        }
      }

      .small_img {
        position: absolute;
        top: -50px;
        left: 47.5%;
        z-index: 1;
        width: 167px;
        min-width: 0;
        @include sm {
          display: block;
          left: auto;
          right: 6%;
          width: 116px;
        }

        &_wrapp {
          padding: 15px;
          @include sm {
            padding: 9px;
          }
        }
      }

      .big_img {
        width: 35%;

        .border {
          padding-bottom: 54.5%;
        }
      }
    }

    .hero_bottom {
      padding-top: 30px;
      padding-bottom: 23px;
      @include md {
        padding: 25px 20px;
      }
      @include sm {
        padding-top: 26px;
        padding-bottom: 32px;
      }

      .filter {
        display: flex;
        @include sm {
          justify-content: space-between;
        }

        &_menu {
          margin-right: 0;

          ul {
            height: auto;
            justify-content: flex-end;
            @include sm {
              padding-top: 45px;
              padding-bottom: 45px;
            }

            li {
              @include sm {
                max-width: none;
                width: auto;
              }
            }
          }
        }

        &_button {
          color: #5C6876;
          border-color: #5C6876;
          font-weight: 400;
          padding: 9px 32px;
          margin-top: 10px;
          margin-bottom: 10px;
          @include md {
            margin-right: 20px;
          }
          @include sm {
            margin-right: 0;
          }

          &:hover,
          &.active {
            color: #559BEB;
            background: #C8E0F1;
            border-color: #C8E0F1;
          }
        }

        .form {
          &__tooltip {
            color: #CE72CB;
            font-weight: 400;
          }
        }
      }
    }
  }

  .content_section {
    .accordion__solutions {
      .accordion__item {
        &--active {
          .accordion__button {
            color: #559BEB;

            &:before {
              background: center/contain no-repeat url($__IMG_PATH + 'solutions/minus-icon.svg');
            }

            &:after {
              width: 0;
              transition: width 0.3s;
            }
          }
        }
      }

      .accordion__heading {
        @include sm {
          margin-bottom: 0;
        }
      }

      .accordion__button {
        padding-left: 93px;
        padding-right: 85px;
        padding-bottom: 32px;
        font-weight: 700;
        @include sm {
          padding-left: 10px;
          padding-right: 10px;
        }

        &:before {
          content: '';
          order: 1;
          display: block;
          width: 30px;
          height: 30px;
          background: center/contain no-repeat url($__IMG_PATH + 'solutions/plus-icon.svg');
          transition: background 0.3s;
        }

        &:after {
          @include sm {
            width: 100%;
            transition: width 0.3s;
            left: 10px;
          }
        }
      }

      .accordion__collapse {
        height: auto;
        transition: none;
        @include sm {
          padding-left: 10px;
          padding-right: 10px;
        }
      }

      .accordion__body {
        border: 1px solid rgba(102, 114, 126, 0.15);
        padding: 30px 80px 50px 90px;
        color: #404B56;
        font-weight: 600;
        letter-spacing: 0.02em;
        line-height: 1.4;
        @include sm {
          padding: 30px 26px 36px;
        }
      }

      .accordion__description {
        max-width: 675px;
        font-size: 14px;
      }

      .accordion__list {
        ul {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          flex-wrap: wrap;
          margin-left: -15px;
          margin-right: -15px;
          @include md {
            display: flex;
          }

          li {
            margin-top: 35px;
            font-size: 18px;
            //width: 33.3333%;
            padding-left: 15px;
            padding-right: 15px;
            @include md {
              width: 100%;
            }
          }
        }
      }

      .accordion__link {
        margin-top: 40px;
        padding: 9px 36px;

        &:after {
          margin-left: 16px;
        }
      }
    }
  }
}

.d-none {
  display: none;
}

.search {
  width: 30%;
  margin-top: 16px;
  @include sm {
    width: 100%;
    margin-top: 0;
  }

  &_group {
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding-right: 30px;
    @include sm {
      flex-wrap: wrap;
      align-items: center;
    }

    &.form__control--error {
      .search_input {
        color: #CE72CB;
      }
    }
  }

  &_label,
  &_clear {
    position: absolute;
    top: 0;
    z-index: 1;
    cursor: pointer;
  }

  &_label {
    left: 0;

    svg {
      vertical-align: middle;
    }
  }

  &_clear {
    right: 0;
    padding: 5px;
    cursor: pointer;
    @include sm {
      padding: 3px 5px;
    }
  }

  &_wrapper {
    width: 100%;
  }

  &_input {
    width: 100%;
    margin-left: 30px;
    padding-right: 35px;
    color: #559BEB;
    font-size: 18px;
  }

  &_error {
    position: relative;
    right: auto;
    @include sm {
      width: 100%;
      margin-left: 30px;
    }
  }

  &_list {
    margin-left: 30px;

    li {
      cursor: pointer;
      margin-top: 13px;
      color: #559BEB;
      
      &.highlighted {
        background-color: #C8E0F1;
      }
    }
  }

  &_result {
    padding: 75px 93px 10px;
    @include sm {
      padding-left: 10px;
      padding-right: 10px;
    }

    .found {
      font-size: 34px;
      font-weight: 700;
      color: #4996D1;
      letter-spacing: 0.02em;
    }

    .not_found {
      text-align: center;

      img {
        max-width: 120px;
        margin: 35px auto 46px;
        @include sm {
          margin-top: 0;
        }
      }

      h3 {
        font-size: 28px;
        font-weight: 700;
        letter-spacing: 0.02em;
        color: #559BEB;
        margin-bottom: 15px;
      }

      p {
        margin-bottom: 104px;
        @include sm {
          margin-bottom: 35px;
        }
      }
    }
  }
}

.marked {
  position: relative;
  padding-left: 3px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    width: 3px;
    margin-left: -9px;
    background-color: #CE72CB;
  }

  a {
    color: #559BEB;
    background: #C8E0F1;
  }

  mark {
    background-color: transparent;
    color: inherit;
  }
}
