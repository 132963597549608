@import '../variables';
@import '../mixins';

// For new design
.grid-system {
  .container-lg {
    width: 100%;
    max-width: 1302px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 16px;
    padding-left: 16px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -24px;
    margin-left: -24px;
  }


  .col {
    &-lg {
      &-12,
      &-3,
      &-4,
      &-5,
      &-6,
      &-7,
      &-8 {
        position: relative;
        width: 100%;
        padding-right: 24px;
        padding-left: 24px;
      }

      @include media-query(lg) {
        &-12 {
          -ms-flex: 0 0 100%;
          flex: 0 0 100%;
          max-width: 100%;
        }

        &-3 {
          -ms-flex: 0 0 25%;
          flex: 0 0 25%;
          max-width: 25%;
        }

        &-4 {
          -ms-flex: 0 0 33.333333%;
          flex: 0 0 33.333333%;
          max-width: 33.333333%;
        }

        &-5 {
          -ms-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
          max-width: 41.66667%
        }

        &-6 {
          -ms-flex: 0 0 50%;
          flex: 0 0 50%;
          max-width: 50%;
        }

        &-7 {
          -ms-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
          max-width: 58.33333%
        }

        &-8 {
          -ms-flex: 0 0 66.666667%;
          flex: 0 0 66.666667%;
          max-width: 66.666667%;
        }
      }
    }
  }

  .no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .card-section {
    margin-bottom: 20px;
    padding: 50px 20px;
    @include media-query(lg) {
      margin-bottom: 72px;
      padding: 48px;
    }
  }

  .card {
    padding: 20px;
    @include media-query(lg) {
      padding: 48px;
    }

    &-lg {
      @include media-query(lg) {
        padding: 48px;
      }
    }
  }

  .w {
    &-100 {
      width: 100%;
    }

    &-auto {
      width: auto;
    }
  }

  .mw {
    &-48 {
      min-width: 48px;
    }

    &-24 {
      max-width: 24px;
    }

    &-lg {
      @include media-query(lg) {
        &-none {
          max-width: none;
        }

        &-52 {
          max-width: 52px;
        }
      }
    }
  }

  .h {
    &-100 {
      height: 100%;
    }

    &-52 {
      height: 52px;
    }

    &-auto {
      height: auto;
    }
  }

  .position {
    &-relative {
      position: relative;
    }
  }

  .z {
    &-0 {
      z-index: 0;
    }

    &-2 {
      z-index: 2;
    }
  }

  .rounded {
    border-radius: 24px;

    &-md,
    &-lg {
      border-radius: 8px;
    }

    &-md {
      @include media-query(lg) {
        border-radius: 16px;
      }
    }

    &-lg {
      @include media-query(lg) {
        border-radius: 24px;
      }

      &-lg {
        border-radius: 0;

        @include media-query(lg) {
          border-radius: 24px;
        }
      }
    }
  }

  .shadow {
    &-1 {
      box-shadow: -16px -16px 40px rgba(255, 255, 255, 0.8), 16px 4px 64px rgba(18, 61, 101, 0.3), inset -8px -6px 80px rgba(255, 255, 255, 0.18);
    }

    &-2 {
      box-shadow: 0 4px 30px rgba(43, 45, 74, 0.17);
    }

    &-3 {
      box-shadow: -8px -10px 30px 0 rgba(232, 234, 243, 0.05), 18px 12px 45px 0 rgba(23, 37, 87, 0.42), -16px -6px 80px 0 rgba(248, 249, 249, 0.03) inset;
    }

    &-lg {
      @include media-query(lg) {
        &-1 {
          box-shadow: -16px -16px 40px rgba(255, 255, 255, 0.8), 16px 4px 64px rgba(18, 61, 101, 0.3), inset -8px -6px 80px rgba(255, 255, 255, 0.18);
        }

        &-2 {
          box-shadow: 0 4px 30px rgba(43, 45, 74, 0.17);
        }

        &-3 {
          box-shadow: -8px -10px 30px 0 rgba(232, 234, 243, 0.05), 18px 12px 45px 0 rgba(23, 37, 87, 0.42), -16px -6px 80px 0 rgba(248, 249, 249, 0.03) inset;
        }

        &-none {
          box-shadow: none;
        }
      }
    }
  }

  .bg {
    &-white {
      background-color: #fff;
    }

    &-gradient {
      background: linear-gradient(90.36deg, #6EACF6 21.97%, #A685F3 76.97%), #4996D1;

    }

    &-gradient-lg-img {
      @include media-query(lg) {
        background: center / cover no-repeat url($__IMG_PATH + 'guide/bg-gradient.jpg') #4996D1;
      }
    }

    &-lg {
      @include media-query(lg) {
        &-white {
          background-color: #fff;
        }
      }
    }
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .color {
    &-primary {
      color: $color_blur;
    }

    &-secondary {
      color: $color_title;
    }

    &-text {
      color: $color_default;
    }

    &-white {
      color: #fff;
    }

    &-gradient {
      &-1 {
        background: $gradient_primary;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &-2 {
        background: $gradient_secondary;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &-lg {
      @include media-query(lg) {
        &-primary {
          color: $color_blur;
        }

        &-secondary {
          color: $color_title;
        }

        &-text {
          color: $color_default;
        }

        &-white {
          color: #fff;
        }

        &-gradient {
          &-1 {
            background: $gradient_primary;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          &-2 {
            background: $gradient_secondary;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
  }

  .text {
    &-spacing {
      letter-spacing: 0.02em;
    }

    &-center {
      text-align: center;
    }

    &-lg {
      @include media-query(lg) {
        &-center {
          text-align: center;
        }
      }
    }
  }

  .fs {
    &-14 {
      font-size: 14px;
    }

    &-18 {
      font-size: 18px;
    }

    &-18 {
      font-size: 18px;
    }

    &-24 {
      font-size: 24px;
    }

    &-28 {
      font-size: 28px;
    }

    &-36 {
      font-size: 36px;
    }

    &-45 {
      font-size: 45px;
    }

    &-48 {
      font-size: 48px;
    }

    @include media-query(lg) {
      &-lg {
        &-14 {
          font-size: 14px;
        }

        &-18 {
          font-size: 18px;
        }

        &-24 {
          font-size: 24px;
        }

        &-28 {
          font-size: 28px;
        }

        &-36 {
          font-size: 36px;
        }

        &-45 {
          font-size: 45px;
        }

        &-48 {
          font-size: 48px;
        }
      }
    }
  }

  .fw {
    &-normal {
      font-weight: 400;
    }

    &-semi {
      font-weight: 600;
    }

    &-bold {
      font-weight: 700;
    }
  }

  .lh {
    &-1 {
      line-height: 1;
    }

    &-sm {
      line-height: 1.1752 !important;
    }

    &-base {
      line-height: 1.33;
    }

    &-lg {
      line-height: 1.4;
    }
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mb-05 {
    margin-bottom: 4px;
  }

  .mx-lg-n4 {
    @include media-query(lg) {
      margin-left: -24px;
      margin-right: -24px;
    }
  }

  .order {
    &-0 {
      order: 0;
    }

    &-1 {
      order: 1;
    }

    &-lg {
      @include media-query(lg) {
        &-0 {
          order: 0;
        }

        &-1 {
          order: 1;
        }
      }
    }
  }

  .ratio {
    position: relative;
    width: 100%;

    &::before {
      display: block;
      content: '';
    }

    & > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &-1x1 {
      &::before {
        padding-top: 100%;
      }
    }
  }

  .content-block {
    * + * {
      margin-top: 20px;
      @include media-query(lg) {
        margin-top: 16px;
      }
    }

    * {
      @extend .fs-18, .lh-base, .text-spacing;
    }

    h4 {
      @extend .fs-24, .fs-lg-28, .lh-base, .fw-bold, .color-primary;

      @include media-query(lg) {
        color: $color_title;
      }
    }

    ul {
      li {
        position: relative;
        padding-left: 36px;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: -2px;
          width: 24px;
          height: 24px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='25' fill='none' viewBox='0 0 24 25'%3E%3Cpath fill='%234996D1' fill-rule='evenodd' d='M16.08 22.296H7.91c-3.53 0-5.91-2.27-5.91-5.66v-8.67c0-3.39 2.38-5.67 5.91-5.67h8.17c3.54 0 5.92 2.28 5.92 5.67v8.67c0 3.39-2.38 5.66-5.92 5.66Zm-1.81-10.75H7.92c-.42 0-.75.34-.75.75 0 .42.33.75.75.75h6.35l-2.48 2.47c-.14.14-.22.34-.22.53s.08.38.22.53c.29.29.77.29 1.06 0l3.77-3.75c.28-.28.28-.78 0-1.06l-3.77-3.75a.754.754 0 0 0-1.06 0c-.29.3-.29.77 0 1.07l2.48 2.46Z' clip-rule='evenodd'/%3E%3Cpath fill='url(%23a)' fill-rule='evenodd' d='M16.08 22.296H7.91c-3.53 0-5.91-2.27-5.91-5.66v-8.67c0-3.39 2.38-5.67 5.91-5.67h8.17c3.54 0 5.92 2.28 5.92 5.67v8.67c0 3.39-2.38 5.66-5.92 5.66Zm-1.81-10.75H7.92c-.42 0-.75.34-.75.75 0 .42.33.75.75.75h6.35l-2.48 2.47c-.14.14-.22.34-.22.53s.08.38.22.53c.29.29.77.29 1.06 0l3.77-3.75c.28-.28.28-.78 0-1.06l-3.77-3.75a.754.754 0 0 0-1.06 0c-.29.3-.29.77 0 1.07l2.48 2.46Z' clip-rule='evenodd'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='6.421' x2='19.54' y1='2.296' y2='2.378' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%236EACF6'/%3E%3Cstop offset='.844' stop-color='%23A685F3'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
        }
      }
    }

    strong {
      @extend .d-inline-block, .color-primary;
    }
  }

  .accordion {
    &-item {
      border-bottom: 1px solid rgba(102, 114, 126, 0.15);

      &.opened {

      }

      &:not(.opened) {
        .accordion-button {
          //color: #959BAF;

          /*&:after {
            background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="32" height="33" fill="none" viewBox="0 0 32 33"%3E%3Cpath fill="%23959BAF" d="M22.223 2.914H9.777c-4.54 0-7.111 2.571-7.111 7.11V22.47c0 4.527 2.56 7.111 7.111 7.111h12.444c4.54 0 7.112-2.583 7.112-7.11V10.024c0-4.54-2.572-7.111-7.111-7.111Z" opacity=".4"/%3E%3Cpath fill="%23959BAF" d="M20.428 17.191H17v3.399c0 .547-.448.99-1 .99s-.999-.443-.999-.99v-3.4h-3.428a1.005 1.005 0 0 1-.906-.996c0-.513.391-.943.906-.996h3.417v-3.387c.053-.51.487-.898 1.005-.898.518 0 .951.387 1.005.898v3.387h3.428c.514.053.906.483.906.996s-.392.944-.906.996Z"/%3E%3C/svg%3E');
          }*/
        }

        .accordion-icon {
          svg path:nth-child(2) {
            transform: none;
            transform-origin: center;
            transition: transform 0.2s;
          }
        }
      }
    }

    &-button {
      position: relative;
      border: 0;
      border-radius: 0;
      color: #959BAF;
      font-family: inherit;
      cursor: pointer;
      background: transparent;
      text-align: left;
      @extend .d-flex, .fs-18, .fs-lg-24, .fw-bold, .text-spacing, .w-100, .m-0, .px-0, .py-4; // .color-primary,

      /*&:after {
        flex-shrink: 0;
        width: 32px;
        height: 32px;
        margin-left: auto;
        padding-left: 32px;
        content: '';
        background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32"%3E%3Cpath fill="%23959BAF" d="M22.224 2.667H9.779c-4.54 0-7.111 2.572-7.111 7.11v12.445c0 4.528 2.56 7.111 7.111 7.111h12.445c4.539 0 7.11-2.583 7.11-7.11V9.777c0-4.54-2.571-7.111-7.11-7.111Z" opacity=".4"/%3E%3Cpath fill="%23959BAF" d="M10.668 15.948c0 .514.391.944.906.996h8.855c.514-.052.906-.482.906-.996 0-.513-.392-.943-.906-.996h-8.855a1.005 1.005 0 0 0-.906.996Z"/%3E%3C/svg%3E');
        background-repeat: no-repeat;
        background-position-x: 100%;
        transition: 0.3s;
      }*/
    }

    &-icon {
      svg path:nth-child(2) {
        transform-origin: center;
        transform: rotate(45deg);
        transition: transform 0.2s;
      }
    }

    &-body {
      padding-bottom: 32px;
    }

    .content-block {
      color: #5C6876;

      ul li {
        padding-left: 23px;

        &:before {
          top: 2px;
          width: 15px;
          height: 15px;
          background: center/contain no-repeat #4996D1 url($__IMG_PATH + 'icons/ellipse.svg');
          box-shadow: -2px -1px 4px rgba(255, 255, 255, 0.4), 2px 1px 3px rgba(24, 47, 91, 0.2), inset 3px 3px 5px rgba(213, 222, 245, 0.4), inset -4px -3px 4px rgba(79, 103, 120, 0.2);
          border-radius: 50%;
        }
      }
    }
  }
}

// Flex System
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-query($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $key, $values in $layout {
      @each $abbr-attr in $key {
        @each $attr, $values in $values {
          @each $abbr-value, $attr-value in $values {
            .#{$abbr-attr}#{$infix}-#{$abbr-value} {
              #{$attr}: #{$attr-value} !important;
            }
          }
        }
      }
    }

    // Margin and Padding
    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {
        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length !important;
        }
        .#{$abbrev}t#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-top: $length !important;
        }
        .#{$abbrev}r#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-right: $length !important;
        }
        .#{$abbrev}b#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-bottom: $length !important;
        }
        .#{$abbrev}l#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-left: $length !important;
        }
      }
    }
    // Some special margin utils
    .m#{$infix}-auto {
      margin: auto !important;
    }
    .mt#{$infix}-auto,
    .my#{$infix}-auto {
      margin-top: auto !important;
    }
    .mr#{$infix}-auto,
    .mx#{$infix}-auto {
      margin-right: auto !important;
    }
    .mb#{$infix}-auto,
    .my#{$infix}-auto {
      margin-bottom: auto !important;
    }
    .ml#{$infix}-auto,
    .mx#{$infix}-auto {
      margin-left: auto !important;
    }
  }
}