.about_page {
  position: relative;
  padding-bottom: 107px;
  overflow: hidden;
  @include xs {
    margin-bottom: 44px;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    left: 0;
  }

  &:before {
    top: 0;
    max-height: 497px;
    background: linear-gradient(0deg, #FFFFFF 19.38%, rgba(255, 255, 255, 0) 77.46%), top 0 left 0 / 100% no-repeat url($__IMG_PATH + 'about-us/bg-top.jpg');
    @include xs {
      max-height: 268px;
      background: top 0 left 0 / cover no-repeat url($__IMG_PATH + 'contacts/bg-md-1.jpg');
    }
  }

  &:after {
    top: 280px;
    z-index: -2;
    min-width: 1440px;
    background: top 0 center / 100% 2150px no-repeat url($__IMG_PATH + 'about-us/bg.jpg');
    @include xs {
      top: 0;
      min-width: auto;
      background: top 0 center / 100% no-repeat url($__IMG_PATH + 'about-us/bg-mobile.jpg');
    }
  }

  .hero {
    padding-top: 144px;

    &__card {
      padding: 86px 58px 71px;
      background: center / cover no-repeat url($__IMG_PATH + 'about-us/hero-bg.jpg') #FFFFFF;
      box-shadow: 0 4px 30px rgba(43, 45, 74, 0.17);
      border-radius: 24px;
      @include sm {
        display: flex;
        flex-direction: column-reverse;
        overflow: hidden;
        padding: 0;
        border-radius: 8px;
        background: #fff;
      }
    }

    &__header {
      margin-top: -114px;
      margin-bottom: 28px;
      @include sm {
        margin-top: 0;
        margin-bottom: 0;
        background: center / cover no-repeat url($__IMG_PATH + 'about-us/hero-mobile-bg.jpg');
        padding: 12px 27px;
      }
    }

    &__grid {
      display: inline-grid;
      grid-template-columns: max(334px) auto;
      grid-template-rows: repeat(4, auto);
      justify-items: flex-start;
      grid-column-gap: 7%;
      grid-row-gap: 12px;
      @include sm {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: baseline;
        gap: 0;
      }
    }

    .hero__item:nth-child(1) {
      grid-area: 1 / 1 / 2 / 2;
    }

    .hero__item:nth-child(2) {
      grid-area: 2 / 1 / 3 / 2;
    }

    .hero__item:nth-child(3) {
      grid-area: 3 / 1 / 4 / 2;
      width: 100%;
      @include sm {
        max-width: 195px;
      }

      .hero__wrapper {
        width: 100%;
        justify-content: space-between;
      }
    }

    .hero__item:nth-child(4) {
      grid-area: 4 / 1 / 5 / 2;
      width: 100%;
      @include sm {
        max-width: 195px;
      }

      .hero__wrapper {
        width: 100%;
        justify-content: space-between;
      }
    }

    .hero__item:nth-child(5) {
      grid-area: 2 / 2 / 3 / 3;
      justify-self: flex-end;
    }

    .hero__item:nth-child(6) {
      grid-area: 3 / 2 / 4 / 3;
      justify-self: flex-end;
    }

    .hero__item:nth-child(7) {
      grid-area: 4 / 2 / 5 / 3;
      justify-self: flex-end;
    }

    &__col {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      @include sm {
        justify-content: flex-start;
      }

      &:first-child {
        max-width: 334px;
        @include sm {
          max-width: none;
        }

        .hero__item:nth-child(3),
        .hero__item:nth-child(4) {
          width: 100%;
          @include sm {
            max-width: 195px;
          }

          .hero__wrapper {
            width: 100%;
            justify-content: space-between;
          }
        }
      }

      &:last-child {
        margin-left: 7%;
        @include sm {
          margin-left: 0;
        }

        .hero__item {
          align-self: flex-end;
          @include sm {
            align-self: flex-start;
          }
        }
      }
    }

    &__item {
      //align-self: baseline;
      padding: 1px;
      box-shadow: -8px -6px 80px 0px #FFFFFF2E inset, -15px 2px 40px 0px #FFFFFFCC, 16px 4px 64px 0px #123D654D;
      background: linear-gradient(155deg, #FFFFFF 30%, rgba(219, 230, 242, 0) 53%, #4996D1 100%);
      border-radius: 24px;
      //margin-bottom: 12px;
      @include sm {
        margin-bottom: 10px;
      }
    }

    &__wrapper {
      display: inline-flex;
      align-items: center;
      min-width: 177px;
      min-height: 95px;
      height: 100%;
      padding: 10px 25px;
      border-radius: 24px;
      background: linear-gradient(0deg, #FFFFFF 90%, rgba(255, 255, 255, 0) 105%);
      box-shadow: 16px 4px 64px 0 rgba(18, 61, 101, 0.3), -15px 2px 40px 0 rgba(255, 255, 255, 0.8), -8px -6px 80px 0 rgba(255, 255, 255, 0.18) inset;
      @include sm {
        min-width: auto;
        min-height: 48px;
        padding: 10px 24px 10px 15px;
      }

      img {
        display: inline-block;
        vertical-align: middle;
        font-size: 0;
        @include sm {
          max-width: 25px;
        }
      }

      p {
        position: relative;
        padding-left: 30px;
        font-size: 18px;
        line-height: 1.3;
        letter-spacing: 0.02em;
        font-weight: 600;
        color: #5C6876;
        background: linear-gradient(90.36deg, #6EACF6 21.97%, #A685F3 76.97%), linear-gradient(0deg, #4996D1, #4996D1);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @include sm {
          padding-left: 17px;
          font-size: 10px;
          font-weight: 400;
        }

        &::before {
          content: '';
          position: absolute;
          top: 2px;
          left: 0;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: url($__IMG_PATH + 'about-us/point.png') no-repeat;
          box-shadow: -2px -1px 4px rgba(255, 255, 255, 0.4), 2px 1px 3px rgba(24, 47, 91, 0.2), inset 3px 3px 5px rgba(213, 222, 245, 0.4), inset -4px -3px 4px rgba(79, 103, 120, 0.2);
          @include sm {
            width: 10px;
            height: 10px;
          }
        }

        & + img {
          margin-left: 10px;
        }
      }
    }

    &__footer {
      @include sm {
        border-bottom: 1px solid rgba(92, 104, 118, 0.3);
        padding: 41px 30px 35px;
      }
      @include xs {
        padding: 42px 35px 32px 25px
      }

      .info {
        margin-left: 10px;
        @include sm {
          margin-left: 0;
          max-width: none;
        }
      }

      .heading,
      .title {
        margin-bottom: 10px;
      }

      .heading {
        @include sm {
          font-size: 24px;
          line-height: 1.4;
        }
        @include xs {
          font-size: 18px;
          margin-bottom: 10px;
        }
      }

      .mobile--hide {
        @include sm {
          display: none;
        }
      }

      .heading--mobile {
        display: none;
        @include sm {
          display: block;
        }
      }
    }
  }

  .values {
    margin-bottom: 100px;
    margin-top: 30px;
    position: relative;
    @include xs {
      margin-top: 42px;
      margin-bottom: 75px;
    }

    .heading {
      margin-bottom: 15px;
      padding-left: 4.5%;
      @include xs {
        padding-left: 0;
        margin-bottom: 13px;
      }
    }

    &__wrapp {
      border-radius: 24px;
      box-shadow: -16px -16px 40px rgba(255, 255, 255, 0.8), 16px 4px 64px rgba(18, 61, 101, 0.3), inset -8px -6px 80px rgba(255, 255, 255, 0.18);
      @include xs {
        border-radius: 8px;
      }
    }

    &__border {
      border-radius: 24px;
      background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(242, 245, 251, 0.8);
      padding: 23px 26px 41px 26px;
      @include xs {
        border-radius: 8px;
        padding: 22px 20px 10px 16px;
      }
    }

    &__img {
      border-radius: 18px;
      overflow: hidden;
      height: 0;
      padding-bottom: 27%;
      position: relative;
      margin-bottom: 60px;
      @include xs {
        border-radius: 8px;
        padding-bottom: 91%;
        margin-bottom: 32px;
      }

      img {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include xs {
        flex-direction: column;
      }

      .ul {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 8%;
        grid-auto-flow: row dense;
        padding-left: 31px;
        padding-right: 31px;
        @include xs {
          grid-template-columns: repeat(1, auto);
          grid-column-gap: 0;
          padding-left: 0;
          padding-right: 0;
        }

        .item {
          display: flex;
          flex-direction: column;
          margin-bottom: 26px;
          padding-left: 27px;
          @include xs {
            br {
              display: none;
            }
          }

          &:before {
            top: 6px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            box-shadow: -2px -1px 4px rgba(255, 255, 255, 0.4), 2px 1px 3px rgba(24, 47, 91, 0.2), inset 3px 3px 5px rgba(213, 222, 245, 0.4), inset -4px -3px 4px rgba(79, 103, 120, 0.2);
          }

          &--1 {
            grid-column-start: 1;
          }

          &--2 {
            grid-column-start: 2;
            @include xs {
              grid-column-start: 1;
            }
          }

          &--3 {
            grid-column-start: 3;
            @include xs {
              grid-column-start: 1;
            }
          }
        }
      }

      .title,
      .subtitle {
        font-size: 18px;
        line-height: 148.5%;
        font-weight: 600;
      }

      .title {
        display: block;
        color: $color_primary;
        background: linear-gradient(90.36deg, #6EACF6 21.97%, #A685F3 76.97%), #4996D1;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .partners {
    .heading {
      margin-bottom: 46px;
      padding-left: 3%;
    }

    &__wrapp {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      @include xs {
        flex-wrap: wrap;
      }
    }

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-right: 1px dashed #B366D7;
      width: 15.6%;
      justify-content: space-between;
      padding: 0 15px;
      @include xs {
        border-right: none;
        width: 50%;
      }

      & > div {
        min-height: 87px;
        @include md {
          min-height: 70px;
        }
        @include xs {
          min-height: auto;
          margin-bottom: 45px;
        }

        &:last-child {
          min-height: 30px;
          @include xs {
            min-height: auto;
            margin-bottom: 45px;
          }
        }
      }

      &:last-child {
        border: none;
      }
    }
  }

  .border__sm {
    position: relative;
    @include sm {
      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(0deg, rgba(73, 150, 209, 0), rgba(73, 150, 209, 0)), linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 36.82%, rgba(179, 102, 215, 0.4) 67.04%, rgba(90, 84, 209, 0.4) 87.16%);
      }
    }
  }
}

.list {
  display: flex;
  justify-content: space-between;
  padding: 50px 0;
  color: #5C6876;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.485;
  letter-spacing: 0;
  @include sm {
    flex-direction: column;
    padding: 25px 0;
    br {
      display: none;
    }
  }
  @include xs {
    padding: 32px 0;
  }

  &__title {
    color: #559BEB;
    font-size: 45px;
    font-weight: 700;
    letter-spacing: 0.03em;
    @include xs {
      font-size: 34px;
    }
  }

  &__subtitle {
    margin-bottom: 14px;
    color: #4996D1;
    font-size: 28px;
    font-weight: 700;
  }

  &__col {
    max-width: 494px;
    width: 100%;
    @include sm {
      max-width: 100%;
    }
  }

  &__contact {
    padding-top: 60px;
    @include sm {
      padding-top: 47px;
    }

    .list {
      padding-top: 25px;
      padding-bottom: 0;

      &__title {
        @include sm {
          font-size: 28px;
        }
      }

      &__col {
        display: contents;
        @include sm {
          display: flex;
          justify-content: space-between;
        }
      }

      &__item {
        padding-top: 21px;
        padding-bottom: 21px;
        padding-left: 25px;
        width: 21%;
        margin-bottom: 0;
        @include sm {
          padding-left: 0;
          width: 44%;
        }

        &:before {
          top: 25px;
          width: 15px;
          height: 15px;
          @include sm {
            display: none;
          }
        }
      }

      &__heading {
        margin-bottom: 13px;
        @include sm {
          margin-bottom: 10px;
        }
      }

      &__address {
        margin-bottom: 25px;
        @include sm {
          margin-bottom: 20px;
        }
      }

      .ul {
        @include sm {
          font-size: 14px;
        }
      }
    }
  }

  &__item {
    &--divider {
      padding-top: 16px;
      margin-bottom: 50px;
      position: relative;
      letter-spacing: 0.02em;
      @include sm {
        margin-bottom: 30px;
      }
      @include xs {
        padding-top: 20px;
        margin-bottom: 30px;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 91px;
        height: 1px;
        background: linear-gradient(90.36deg, rgba(110, 172, 246, 0.4) 21.97%, rgba(166, 133, 243, 0.4) 36.82%, rgba(179, 102, 215, 0.4) 67.04%, rgba(90, 84, 209, 0.4) 87.16%);
      }
    }

    &--dividerMobile {
      &:after {
        content: none;
      }

      &:after {
        @include sm {
          content: '';
        }
      }
    }
  }

  &__heading {
    font-weight: 700;
    color: $color_primary;
    background: linear-gradient(90.36deg, #6EACF6 21.97%, #A685F3 76.97%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &--icons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-auto-flow: column;
    grid-column-gap: 10%;
    padding-top: 12px;
    @include sm {
      display: flex;
      grid-template-columns: none;
      grid-template-rows: none;
      grid-column-gap: initial;
      padding-top: 20px;
      br {
        display: none;
      }
    }
    @include xs {
      padding-top: 44px;
    }

    .list {
      &__col {
        display: contents;
        max-width: 485px;
        @include sm {
          display: block;
          max-width: 100%;
        }
      }

      &__title {
        margin-bottom: 22px;
      }

      &__icon {
        margin-bottom: 17px;
      }

      &__text {
        margin-bottom: 5px;
      }

      &__item {
        padding-top: 24px;
        @include xs {
          padding-top: 45px;
        }
      }

      &__col:nth-child(2) {
        .list__item:nth-child(1) {
          padding-top: 10px;
          @include xs {
            padding-top: 45px;
          }
        }
      }
    }
  }
}
