.reviews_slider {
  width: 100%;

  &_block {
    position: relative;
    width: 100%;
    max-width: 1175px;
    margin: 0 auto;
  }

  &__text {
    text-align: center;
    font-size: 22px;
    line-height: 140%;
    letter-spacing: 0.02em;
    padding: 36px 103px 26px 120px;
    position: relative;

    &:before, &:after {
      content: '';
      display: block;
      width: 95px;
      height: 70px;
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    &:before {
      top: 0;
      left: 0;
      background-image: url($__IMG_PATH + 'icons/quote-open.svg');
    }

    &:after {
      bottom: 0;
      right: 20px;
      background-image: url($__IMG_PATH + 'icons/quote-close.svg');
    }

    @include xs {
      padding: 0 0 0 3px;
      text-align: left;
      width: 93%;
      font-size: 18px;
      &:before,
      &:after {
        position: static;
        width: auto;
        height: auto;
        display: inline-block;
        background: none;
      }
      &:before {
        content: '“'
      }
      &:after {
        content: '”'
      }
    }
  }

  &__reviewer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    @include xs {
      align-items: flex-start;
      flex-direction: column;
      margin-top: 26px;
      padding-left: 7px;
    }

    .name, p {
      font-size: 18px;
      line-height: 147%;
    }

    .name {
      font-weight: 600;
      @include xs {
        font-weight: 700;
        color: $color_secondary;
      }
    }

    p {
      display: flex;
      align-items: center;
      padding-left: 2px;
      @include xs {
        padding-left: 0;
      }
    }

    img {
      margin-left: 10px;
      max-width: 103px;
      max-height: 35px;
      mix-blend-mode: multiply;
    }
  }

  &__reviews {
    margin-bottom: 100px;
    margin-left: 20px;
  }

  .reviews_slider_pagination {
    margin-top: 48px;
    padding-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include xs {
      margin-top: 30px;
    }

    .swiper-pagination-bullet {
      cursor: pointer;
      width: 15px;
      height: 15px;
      display: block;
      margin-right: 15px;
      background-size: 100%;
      background-repeat: no-repeat;
      border-radius: 50%;
      box-shadow: 2px 1px 3px 0 rgba(24, 47, 91, 0.2), -2px -1px 4px 0 rgba(255, 255, 255, 0.4), -4px -3px 4px 0 rgba(79, 103, 120, 0.2) inset, 3px 3px 5px 0 rgba(213, 222, 245, 0.4) inset, 0 0 4px 0 rgba(49, 46, 87, 0) inset;
      background-image: url($__IMG_PATH + 'icons/ellipse-white.svg');

      &:last-child {
        margin-right: 0
      }

      &-active {
        box-shadow: 2px 1px 3px 0 rgba(24, 47, 91, 0.2), -2px -1px 4px 0 rgba(255, 255, 255, 0.4), -4px -3px 4px 0 rgba(79, 103, 120, 0.2) inset, 3px 3px 5px 0 rgba(213, 222, 245, 0.4) inset, 0 0 4px 0 rgba(49, 46, 87, 0) inset;
        background-image: url($__IMG_PATH + 'icons/ellipse.svg');
      }
    }
  }
}
